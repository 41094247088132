@import '../../../assets/cascades/utilities';

.boxAllExpand {
	position: relative;
	width: calc(100% - 40px);
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;

	@media screen and (min-width: $mq-2) {
		width: 100%;
		margin-bottom: 10px;
	}
	@media screen and (min-width: $mq-3) {
		width: calc(46% - 70px);
		margin-top: 10px;
		height: 40px;
		margin-left: auto;
	}
	@media screen and (min-width: $mq-4) {  
		width: 160px; 
		margin: 10px 0 0 auto;
	}
	@media screen and (min-width: $mq-5) {  
		margin: 10px 0 0 auto; 
	}
	// @media screen and (min-width: $mq-6) {   
	// }

	span {
		width: calc(100% - 60px);
		text-align: right;
		margin: 12px 0;

		@media screen and (min-width: $mq-3) {
			width: calc(100% - 75px);
			margin: 12px 0 0;
		}
		@media screen and (min-width: $mq-4) {  
			width: 100px;
		}
	}

	.btnAllExpand {
		width: 60px;
		height: 40px;
		border: 0;
		margin: 0;
		padding: 0;
		background-color: $white;
		cursor: grab;

		@media screen and (min-width: $mq-3) { 
			margin-right: 15px;
		}
		@media screen and (min-width: $mq-4) { 
			margin-right: 0px;
		}

		img {
			@include transition(0.3s);
			width: 46px;
			height: 24px;
			margin: 8px 0 8px 14px;
		}
	}
}