// Main Colors
$black: #000000;
$white: #FFFFFF;
$blue: #1fa1d2;
$gray: #CCCCCC;
$red: #c71526;

// REALLY SIMILAR COLORS
// ARE ACTUALLY THE SAME
// MERGE THEM AFTER
// DESIGN IS FINISHED

// ALSO TRANSPARENT BORDERS
// WILL BE GENERALIZED

$redError: #FF9999;
$bluePassive: #A5D9ED;
$greenOk: #E8F3DD;
$warning: #faad14;

$nearlyWhite: #f0f0f0;
$darkGray: #7c7c7c;
$darkBlue: #167eb9;
$darkWhite: #eaeaea; // (:
$lightGray: #adadad;
$lightBlue: #d2ecf6;
$lightRed: #ffe5e5;
$lighterGray: #d8d8d8;
$darkerGray: #333333;

$border-gray: rgba(154, 154, 154, 0.2);
$border-red: rgba(199, 21, 38, 0.15);
$border-black: rgba(124, 124, 124, 0.25);

$background-light: rgba(0, 0, 0, 0.2);
$background-mid-dark: rgba(0, 0, 0, 0.45);
$background-dark: rgba(0, 0, 0, 0.7);
$background-darkest: rgba(0, 0, 0, 0.82);

$background-gray: rgba(154, 154, 154, 0.3);
$background-gray-hover: #c5c5c5;
$background-gray-disabled: rgba(154, 154, 154, 0.2);

$text-gray: rgba(154, 154, 154, 0.8);
$text-light-gray: rgba(255, 255, 255, 0.8); //in Sketch, it looks like text-gray, but in browser, it needs this

// animation colors
$light-green: #65ac1e;



// Active Buttons
// $actButtonCommon: #darkBlue;
// $actButtonAlter: #c9e9f4;
// $actButtonCancel: #bebdbe;

// $actTextCommon: $white;
// $actTextAlter: $blue;
// $actTextCancel: #6f6f6f;

// Buttons
// $genButtonCommon: $blue;
// $genButtonAlter: $white;
// $genButtonCancel: #dcdbdc;

// $genTextCommon: $white;
// $genTextAlter: $blue;
// $genTextCancel: #a3a3a3;

// Disabled Buttons
// $disButtonCommon: #aedeee;
// $disButtonAlter: #9bd7ea;
// $disButtonCancel: #e8e8e8;

// $disTextCommon: #57bada;
// $disTextAlter: #7bcae3;
// $disTextCancel: #c4c4c4;

// Breakpoint
$mq-1: 320px;
$mq-2: 480px;
$mq-3: 768px;
$mq-4: 1023px;
$mq-5: 1280px;
$mq-6: 1440px;
$screen-max: 1920px;

// Width
$text-content-max-width: 920px;
$content-max-width: 1512px;
$boxHead-max-width: 1622px;

// margin / padding
$base-margin: 24px;

//fonts
$font-family: "Roboto", sans-serif;
$font-size-00: 14px; // 20 (line-height)
$font-size-01: 16px; // 22
$font-size-02: 18px; // 24
$font-size-03: 20px; // 26
$font-size-04: 21px; // 27
$font-size-05: 22px; // 28
$font-size-06: 24px; // 30
$font-size-07: 30px; // 40
$font-size-08: 35px; // 45
$font-size-09: 40px; // 50
$font-size-10: 50px; // 60

//sizes
$icon-size: 32px;
$box-max: 546px;
$header-height: 109px;

//timings
$animation-time: 0.3s;
$animation-timing-function: cubic-bezier(0.25, 0.05, 0.5, 1);
$timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);

// $font-family-light: 'HelveticaLight', Helvetica, Arial, sans-serif;
// $font-family-bold: 'HelveticaBold', Helvetica, Arial, sans-serif;
// $font-family-medium: 'HelveticaMedium', Helvetica, Arial, sans-serif;