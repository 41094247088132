@import "../../assets/cascades/utilities";

section.maintenance {
	background-color: $white;
	width: 90%;
	max-width: $content-max-width;
	padding: 0 5% 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: visible;
	// position: relative;
	// Design Adjustment
	margin-top: 20px;
	z-index: 200;

	// TODO: Common Size Mixin
	@media screen and (min-width: $mq-2) {
		margin-top: 226px;
		padding: 0 5% 50px;
	}
	@media screen and (min-width: $mq-3) {
		overflow: visible;
		width: 86%;
		padding: 0 3% 100px;
		margin: 0 4% 0;
		height: 700px;
	}
	@media screen and (min-width: $mq-4) {
		padding: 0 3% 100px;
		margin: 0 4% 0;
	}
	@media screen and (min-width: $mq-5) {
		padding: 0 3% 100px;
		margin: 0 4% 0;
	}
	@media screen and (min-width: $mq-6) {
		padding: 0 55px 100px;
		margin: 0 auto 0;
		max-width: $content-max-width;
	}

	.requestempty {
		display: flex;
		margin: 40px auto 0 auto;
		width: 100%;
		flex-direction: column;
		align-items: center;
		@media screen and (min-width: $mq-3) {
			flex-direction: row;
			align-items: center;
		}
		@media screen and (min-width: $mq-5) {
			width: 1200px;
		}
		.eimage {
			width: 90%;
			height: 262px;
			margin: 0;
			@media screen and (min-width: $mq-3) {
				margin: 0 20px 0 0;
				width: 463px;
			}
			@media screen and (min-width: $mq-4) {
				padding-left: 40px;
			}
			img {
				width: 100%;
				height: 262px;
				display: block;
				@media screen and (min-width: $mq-3) {
					width: 463px;
				}
			}
		}
		.ewrap {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media screen and (min-width: $mq-3) {
				align-items: flex-start;
			}
			@media screen and (min-width: $mq-4) {
				padding-right: 40px;
			}
			.etitle {
				@include font-bold;
				@include font03;
				text-align: center;
				@media screen and (min-width: $mq-3) {
					text-align: left;
				}
			}
			.econtent {
				margin-top: 16px;
				@include font-light;
				text-align: center;
				@media screen and (min-width: $mq-3) {
					text-align: left;
				}
			}
			.ebutton {
				margin: 40px 0 0 0;
				@include transition(0.3s);
				width: 130px;
				display: block;
				background-color: $blue;
				border-radius: 2px;
				color: $white;
				border: solid 2px $blue;
				text-align: center;
				height: 46px;
				line-height: 42px;
				cursor: pointer;
				font-size: 16px;
	
				&:hover {
					background-color: $darkBlue;
					border: solid 2px $darkBlue;
				}
			}
		}
	}
}