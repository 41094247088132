.ui-main-heading {
    @include font-bold;

    @media screen and (min-width: $mq-2) {
        @include font06;
    }
    @media screen and (min-width: $mq-3) {
        @include font09;
    }
    @media screen and (min-width: $mq-4) {
        @include font10;
    }
}

.ui-sub-heading {
    @include font-light;

    @media screen and (min-width: $mq-2) {
        @include font04;
    }
    @media screen and (min-width: $mq-3) {
        @include font07;
    }
    @media screen and (min-width: $mq-4) {
        @include font08;
    }

    &.is-blue {
        color: $blue;
    }
}

.ui-minor-heading {
    @include font-bold;
    margin-top: 40px;
    line-height: 28px;

    @media screen and (min-width: $mq-2) {
        @include font02;
    }
    @media screen and (min-width: $mq-3) {
        @include font05;
    }
}

.ui-copy-text {
    @include font-light;
    @include font01;
    color: $black;
    margin-top: 20px;

    &.has-no-margin {
        margin-top: 0;
    }

    &.is-bold {
        @include font-bold;
        margin-top: 25px;
    }

    &.is-grey {
        color: $darkGray;
        margin-top: 30px;
    }

    &.is-blue {
        color: $blue;
    }

    &.is-bigger {
        @include font02;
    }
}

.ui-link {
    color: $blue;
    @include font-medium;
    cursor: pointer;
    text-decoration: none;

    &.margin-top-25 {
        margin-top: 25px;
    }

    &:hover {
        color: $darkBlue;
    }
}

.lower-roman-list {
    list-style-type: lower-roman;
}

.disc-list {
    list-style-type: disc;
}
