@import '../../assets/cascades/utilities';

.noauthwrap {
  margin: 80px auto;
  height: calc(100vh - 313px);

	// TODO: Generalize or Flux the Height
  	@media screen and (min-width: $mq-2) {
		height: calc(100vh - 329px);
	}
  	@media screen and (min-width: $mq-3) {
		height: calc(100vh - 356px);
	}
	@media screen and (min-width: $mq-4) {
		height: calc(100vh - 373px);
	}
	@media screen and (min-width: $mq-5) {
		height: calc(100vh - 377px);
	}
	@media screen and (min-width: $mq-6) {
		height: calc(100vh - 381px);
	}

  svg {
    margin: 0 auto;
    display: block;
  }
  .text {
    margin-top: 30px;
    color: #000000;
    @include font-light;
    @include font02;
    letter-spacing: normal;
    text-align: center;
    padding: 0 20px;
  }
}