@import '../../assets/cascades/utilities';

section.support {
	@include border-box;
	background-color: $white;
	border-radius: 2px;
	box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
	width: 90%;
	height: auto;
	margin: 40px 5%;
	padding: 25px 15px;
	display: flex;
	flex-flow: column wrap;
	position: relative;
	z-index: 250;

	@media screen and (min-width: $mq-2) {
		padding: 30px 20px;
		margin: 65px 5% 50px;
	}
	@media screen and (min-width: $mq-3) {
		width: 86%;
		padding: 40px 35px 50px;
		margin: 50px 7% 80px;
	}
	@media screen and (min-width: $mq-4) {
		padding: 50px 55px 50px;
		margin: 80px 7% 70px;
	}
	@media screen and (min-width: $mq-5) {
		width: 910px;
		padding: 70px 65px;
		margin: 80px auto 120px;
	}

	// TODO: Generalize Headers
	h2 {
		@include font06;
		width: 100%;
		color: $black;
		margin-bottom: 10px;

		@media screen and (min-width: $mq-3) {
			@include font09;
			margin-bottom: 20px;
		}
		@media screen and (min-width: $mq-4) {
			@include font10;
		}
	}

	h3 {
		margin: 10px 0 15px;

		@media screen and (min-width: $mq-2) {
			@include font02;
		}
		@media screen and (min-width: $mq-3) {
			@include font05;
			margin: 20px 0 30px;
		}
		@media screen and (min-width: $mq-4) {
			margin: 30px 0 40px;
		}

		&.narrow {
			margin: 10px 0 5px;

			@media screen and (min-width: $mq-3) {
				margin: 20px 0 10px;
			}
			@media screen and (min-width: $mq-4) {
				margin: 30px 0 20px;
			}
		}
	}

	p.information {
		margin-bottom: 20px;
		line-height: 22px;

		&.short {
			margin-bottom: 0;
		}

		a {
			color: $blue;
			text-decoration: none;
		}
	}

	// Defined Areas
	.boxInteraction {
		display: flex;
		width: 100%;
		flex-flow: row wrap;

		@media screen and (min-width: $mq-3) {
			justify-content: flex-end;
		}
	}

	.errorMessage {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.img {
			width: 30px;
			height: 30px;
			margin-right: 20px;
		}
		.title {
			color: $red;
			@include font-medium;
		}
	}

	// Lists
	.lstSupport {
		@include lst-common;

		@media screen and (min-width: $mq-3) {
			flex-flow: row wrap;
			justify-content: space-between;
		}

		li {
			width: 100%;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				margin-bottom: 30px;
			}

			&.half {
				@media screen and (min-width: $mq-3) {
					width: 48%;
				}
			}

			&.narrow {
				margin-bottom: 10px;

				@media screen and (min-width: $mq-3) {
					margin-bottom: 10px;
				}
			}

			.tagInput {
				margin-bottom: 15px;
				width: 100%;
				display: block;

				@media screen and (min-width: $mq-3) {
					margin-bottom: 20px;
				}
				@media screen and (min-width: $mq-4) {
					margin-bottom: 25px;
				}
			}

			.errInput {
				color: $red;
				width: 100%;
				height: 36px;
				line-height: 36px;
				display: none;
			}

			.visible {
				display: block;
			}
		}

		.captchawidget {
			display: flex;
			justify-content: flex-end;
			.frc-captcha {
				width: 100%;
				min-width: auto;
				max-width: 100%;
				@media screen and (min-width: $mq-3) {
					width: auto;
					min-width: 308px;
				}
				.frc-success {
					.frc-icon {
						fill: #52c41a;
						stroke: #52c41a;
					}
				}
			}
		}
	}

	// Inputs
	.txbSupport {
		@include txb-common;
	}

	.txaSupport {
		@include txa-common;
	}

	.countryphone {
		display: flex;
		flex-direction: column;
		@media screen and (min-width: $mq-3) {
			flex-direction: row;
		}
		.ddlFeedbackNofilter {
			@include ddl-common-no-filter;
			width: 100%;
			@media screen and (min-width: $mq-3) {
				width: 280px;
			}
		}

		.contryinput {
			width: 100%;
			@media screen and (min-width: $mq-3) {
				width: 280px;
			}
		}

		.telephoneNumberwrap {
			margin-top: 10px;
			display: flex;
			flex-direction: column;
			@media screen and (min-width: $mq-3) {
				flex: 1;
				margin-top: 0;
				margin-left: 20px;
				input {
					width: 100%;
				}
			}
			.telephoneNumber {
			}
		}
	}

	// Buttons
	.btnReset {
		@include btn-cancel;
		margin-bottom: 20px;

		@media screen and (min-width: $mq-2) {
			width: 48%;
			margin-right: 4%;
			margin-bottom: 0;
		}
		@media screen and (min-width: $mq-3) {
			width: 145px;
			margin-right: 20px;
		}
	}

	.btnSend {
		@include btn-common;

		@media screen and (min-width: $mq-2) {
			width: 48%;
		}
		@media screen and (min-width: $mq-3) {
			width: 145px;
		}
	}

	.btnSend1 {
		@include btn-cancel;

		cursor: not-allowed;

		@media screen and (min-width: $mq-2) {
			width: 48%;
		}
		@media screen and (min-width: $mq-3) {
			width: 145px;
		}
	}

	.btnReject {
		@include btn-empty;
		width: 150px;
		margin-right: 20px;
	}

	.btnAccept {
		@include btn-common;
		width: 150px;
	}

	.btnRecaptcha {
		@include lnk-common;
		@include font-medium;
		width: auto;
		padding: 0;
	}

	aside.dialog {
		position: fixed;
		background: $background-dark;
		-webkit-backdrop-filter: blur(37px);
		backdrop-filter: blur(37px);
		width: 100vw;
		height: 100vh;
		z-index: 300;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		.extraLogo {
			width: fit-content;
			background-color: $blue;
			height: 64px;
			margin-left: auto;
			margin-top: 25px;

			@media screen and (min-width: $mq-3) {
				height: 84px;
			}
			@media screen and (min-width: $mq-4) {
				height: 80px;
			}

			img {
				filter: brightness(0) invert(1);
				width: 95px;
				height: 34px;
				padding: 15px 20px;

				@media screen and (min-width: $mq-3) {
					width: 120px;
					height: 40px;
					padding: 22px 20px;
				}
				@media screen and (min-width: $mq-4) {
					padding: 20px 20px;
				}
			}
		}

		.boxDialog {
			@include border-box;
			width: 100%;
			background-color: $white;
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 20vh auto auto auto;
			padding: 15px;

			@media screen and (min-width: $mq-2) {
				padding: 20px;
			}
			@media screen and (min-width: $mq-3) {
				width: 80%;
				padding: 35px;
			}
			@media screen and (min-width: $mq-4) {
				width: 60%;
				padding: 55px;
			}
			@media screen and (min-width: $mq-5) {
				padding: 70px;
			}
			// @media screen and (min-width: $mq-6) {
			//     padding: 80px;
			// }

			.btnClose {
				@include btn-close;
				right: 15px;
				top: 15px;

				@media screen and (min-width: $mq-3) {
					right: 20px;
					top: 20px;
				}
			}

			span {
				@include font04;
				margin-bottom: 10px;
				width: 100%;

				@media screen and (min-width: $mq-3) {
					@include font07;
				}
				@media screen and (min-width: $mq-4) {
					@include font08;
					margin-bottom: 20px;
				}
				@media screen and (min-width: $mq-5) {
					margin-bottom: 30px;
				}
			}

			p {
				@include font00;
				margin-bottom: 15px;
				width: 100%;

				@media screen and (min-width: $mq-3) {
					@include font01;
					margin-bottom: 20px;
				}
				@media screen and (min-width: $mq-4) {
					margin-bottom: 30px;
				}
				@media screen and (min-width: $mq-5) {
					margin-bottom: 40px;
				}
			}
		}
	}
}

.cancelModal {
	position: relative;
	.ant-modal-content {
		@media screen and (min-width: $mq-2) {
			width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			width: 616px;
		}
		@media screen and (min-width: $mq-4) {
			width: 664px;
		}
		@media screen and (min-width: $mq-5) {
			width: 664px;
		}
		@media screen and (min-width: $mq-6) {
			width: 664px;
		}
		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				@media screen and (min-width: $mq-2) {
					padding: 60px 36px;
				}
				@media screen and (min-width: $mq-3) {
					padding: 60px;
				}
				@media screen and (min-width: $mq-4) {
					padding: 60px;
				}
				@media screen and (min-width: $mq-5) {
					padding: 60px;
				}
				@media screen and (min-width: $mq-6) {
					padding: 60px;
				}
				padding: 60px;
				.title {
					@include font-light;
					height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.info {
					@include font-light;
					color: $black;
					font-size: 16px;
					line-height: 22px;
				}
			}
			.buttongroup {
				margin-top: 30px;
				display: flex;
				.btnCancel {
					@include transition(0.3s);
					width: 130px;
					border-radius: 2px;
					border: solid 2px $blue;
					background-color: $white;
					text-align: center;
					color: $blue;
					height: 46px;
					text-decoration: none;
					cursor: pointer;
					line-height: 42px;
					font-size: 16px;

					&:hover {
						background-color: $lightBlue;
					}
				}
				.btnLogin {
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;
					margin-left: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}

.ant-zoom-enter,
.ant-zoom-appear {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-zoom-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
	-webkit-animation-name: antZoomIn;
	animation-name: antZoomIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
	-webkit-animation-name: antZoomOut;
	animation-name: antZoomOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
	transform: scale(0);
	opacity: 0;
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
	transform: none;
}
.ant-zoom-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@-webkit-keyframes antZoomOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.2);
		opacity: 0;
	}
}
@keyframes antZoomOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.2);
		opacity: 0;
	}
}

.ant-fade-enter,
.ant-fade-appear {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-fade-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
	-webkit-animation-name: antFadeIn;
	animation-name: antFadeIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
	-webkit-animation-name: antFadeOut;
	animation-name: antFadeOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
	opacity: 0;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
.ant-fade-leave {
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes antFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes antFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes antFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
