@import '../../assets/cascades/utilities';

.attachModal {
	position: relative;
	.ant-modal-content {
		@media screen and (min-width: $mq-2) {
			width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			width: 516px;
		}
		@media screen and (min-width: $mq-4) {
			width: 564px;
		}
		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 60px;

				@media screen and (min-width: $mq-2) {
					padding: 60px 36px;
				}
				.title {
					@include font-light;
					min-height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.contentwrap {
					display: flex;
					flex-direction: column;
					.itemwrap {
						display: flex;
						height: 60px;
						align-items: center;
						margin-bottom: 20px;
						.itemimage {
							@include icon-ext;
							width: 50px;
							height: 60px;
							margin: 0;
							padding: 0;
						}
						.itemtitlewrap {
							flex: 1;
							display: flex;
							flex-direction: column;
							cursor: pointer;
							.itemfilename {
								display: block;
								color: $black;
								min-width: 200px;
								padding-left: 15px;
								margin-bottom: 5px;
								font-size: 16px;
								@media screen and (min-width: $mq-3) {
									padding-top: 5px;
									padding-left: 30px;
								}
							}
							.itemfilesize {
								display: block;
								color: $lightGray;
								padding-left: 15px;
								width: 100%;
								overflow: hidden;
								font-size: 14px;
								@media screen and (min-width: $mq-3) {
									padding-left: 30px;
								}
							}
						}
					}
				}
			}
			.buttongroup {
				margin-top: 30px;
				display: flex;
				.btnCancel {
					@include transition(0.3s);
					width: 130px;
					border-radius: 2px;
					border: solid 2px $blue;
					background-color: $white;
					text-align: center;
					color: $blue;
					height: 46px;
					text-decoration: none;
					cursor: pointer;
					line-height: 42px;
					font-size: 16px;

					&:hover {
						background-color: $lightBlue;
					}
				}
				.btnLogin {
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;
					margin-left: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}
.ant-modal-mask {
	background: $background-darkest;
}

.carouselmodel {
	position: relative;
	width: 90% !important;
	// @media screen and (min-width: $mq-3) {
	// 	width: 549px !important;
	// }
	@media screen and (min-width: $mq-4) {
		width: 1000px !important;
	}
	@media screen and (min-width: $mq-5) {
		width: 1200px !important;
	}
	@media screen and (min-width: $mq-6) {
		width: 1200px !important;
	}
	.ant-modal-content {
		width: 100%;
		// @media screen and (min-width: $mq-3) {
		// 	width: 549px;
		// }
		// @media screen and (min-width: $mq-4) {
		// 	width: 1000px;
		// }
		// @media screen and (min-width: $mq-5) {
		// 	width: 1200px;
		// }
		// @media screen and (min-width: $mq-6) {
		// 	width: 1200px;
		// }
		.checkboxwrap {
			position: absolute;
			top: 0;
			right: 20px;
			color: $blue;
			@media screen and (min-width: $mq-3) {
				top: 6px;
				right: 34px;
			}
			.ant-checkbox-wrapper {
				color: $blue;
				font-size: 10px;
				@media screen and (min-width: $mq-3) {
					font-size: 14px;
				}
			}
			.ant-checkbox-checked .ant-checkbox-inner {
				background-color: $blue;
				border-color: $blue;
			}
		}
		.closeicon {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			top: 6px;
			right: 5px;
			@media screen and (min-width: $mq-3) {
				top: 10px;
				right: 10px;
			}
			.anticon {
				color: $blue;
				font-size: 16px;
				@media screen and (min-width: $mq-3) {
					font-size: 20px;
				}
			}
			img {
				width: 20px;
				height: 20px;
				@media screen and (min-width: $mq-3) {
					width: 20px;
					height: 20px;
				}
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 0;
				position: relative;
				.slidewrap {
					img {
						width: 100%;
						height: auto;
						display: block;
					}
				}
				.carouselleft {
					font-size: inherit;
					position: absolute;
					left: 0;
					z-index: 9999;
					color: $blue;
					top: 50%;
					cursor: pointer;
					@media screen and (min-width: $mq-3) {
						left: 10px;
					}
				}
				.carouselright {
					font-size: inherit;
					position: absolute;
					right: 0;
					z-index: 9999;
					color: $blue;
					top: 50%;
					cursor: pointer;
					@media screen and (min-width: $mq-3) {
						right: 10px;
					}
				}
			}
		}
	}
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
	font-size: inherit !important;
	left: 0 !important;
	z-index: 9999 !important;
	color: $blue !important;
	@media screen and (min-width: $mq-3) {
		left: 10px !important;
	}
}

.ant-carousel .slick-dots li button {
	background: $bluePassive !important;
	opacity: 1 !important;
}

.ant-carousel .slick-dots li.slick-active button {
	background: $blue !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
	font-size: inherit !important;
	right: 0 !important;
	z-index: 9999 !important;
	color: $blue !important;
	@media screen and (min-width: $mq-3) {
		right: 10px !important;
	}
}

.ant-carousel .slick-dots-bottom {
	bottom: -10px !important;
	@media screen and (min-width: $mq-3) {
		bottom: 10px !important;
	}
}

// TODO: Use Section Mixin
section.landing {
	background-color: $white;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
	justify-content: space-between;
	// Design Adjustment
	// Will see more
	width: 90%;
	max-width: $content-max-width;
	padding: 110px 5% 50px;
	margin-top: 160px;
	margin-left: auto;
	margin-right: auto;
	z-index: 200;

	// TODO: Common Size Mixin
	// @media screen and (min-width: $mq-2) {
	// 	margin-top: 220px;
	// 	padding: 90px 5% 50px;
	// }
	// Keep total 7% space
	@media screen and (min-width: $mq-3) {
		margin-top: 480px;
		width: 86%;
		padding: 30px 3% 80px;
		overflow: visible;
	}
	@media screen and (min-width: $mq-4) {
		margin-top: 560px;
		padding: 30px 3% 120px;
		justify-content: center;
	}
	@media screen and (min-width: $mq-5) {
		margin-top: 500px;
	}
	@media screen and (min-width: $mq-6) {
		margin-top: 580px;
	}

	.mb30 {
		margin-top: 20px !important;
		@media screen and (min-width: $mq-3) {
			margin-top: 0 !important;
		}
	}

	// TODO: Generalize Headers
	// Header
	h2 {
		@include font06;
		@include border-box;
		background-color: $blue;
		color: $white;
		position: absolute;
		text-align: center;
		width: 90%;
		margin: 0 auto;
		padding: 20px;
		left: 0;
		right: 0;
		top: -20px;
		height: 130px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: $mq-3) {
			height: auto;
			background-color: transparent;
			@include font09;
			top: -300px;
		}
		// @media screen and (min-width: $mq-4) {
		// 	top: -280px;
		// }
		// @media screen and (min-width: $mq-5) {
		// 	background-color: transparent;
		// }
		@media screen and (min-width: $mq-6) {
			// background-color: transparent;
			@include font10;
		}
	}

	// Defined Areas
	.boxTags {
		position: relative;
		display: flex;
		flex-direction: row;
		background-color: $blue;
		width: 100%;
		min-height: 40px;
		border-radius: 2px;
		align-items: center;

		&.draft {
			background-color: $bluePassive;
		}

		&.draft .checkDetail img {
			filter: none;
		}

		&.draft .deputyDetail img {
			filter: none;
		}

		&.draft .deputyDetail img {
			filter: none;
		}

		span {
			@include font01;
			color: $white;
			margin: 10px 0 10px 15px;

			&.long {
				flex: 1;
				@media screen and (min-width: $mq-4) {
					@include font00;
				}
				@media screen and (min-width: $mq-6) {
					@include font01;
				}
			}
		}

		.deputyDetail {
			width: 26px;
			height: 40px;
			display: flex;
			align-items: center;
			overflow: hidden;
			cursor: zoom-in;
			margin-right: 10px;
			img {
				width: 26px;
				height: 26px;
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);
			}

			// Animation possible if needed
			p {
				@include font01;
				@include border-box;
				display: none;
				position: absolute;
				z-index: 300;
				bottom: 55px;
				right: 5px;
				width: 270px;
				height: auto;
				padding: 15px;
				background-color: $black;
				color: $white;

				@media screen and (min-width: $mq-3) {
					padding: 20px;
				}
				@media screen and (min-width: $mq-6) {
					right: -105px;
				}

				&:after {
					@include icon-tooltip;
					right: 13px;
					bottom: -10px;

					@media screen and (min-width: $mq-3) {
						right: 14px;
					}
					@media screen and (min-width: $mq-6) {
						right: 124px;
					}
				}
			}

			&:hover p {
				display: block;
			}
		}

		.checkDetail {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			overflow: hidden;
			cursor: zoom-in;
			margin-right: 10px;
			img {
				width: 40px;
				height: 40px;
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);
			}

			// Animation possible if needed
			p {
				@include font01;
				@include border-box;
				display: none;
				position: absolute;
				z-index: 300;
				bottom: 55px;
				right: 5px;
				width: 270px;
				height: auto;
				padding: 15px;
				background-color: $black;
				color: $white;

				@media screen and (min-width: $mq-3) {
					padding: 20px;
				}
				@media screen and (min-width: $mq-6) {
					right: -105px;
				}

				&:after {
					@include icon-tooltip;
					right: 13px;
					bottom: -10px;

					@media screen and (min-width: $mq-3) {
						right: 14px;
					}
					@media screen and (min-width: $mq-6) {
						right: 124px;
					}
				}
			}

			&:hover p {
				display: block;
			}
		}

		&.draft span {
			color: $black;
		}

		&.draft .downloadDetail {
			margin-right: 10px;
			img {
				width: 20px;
				height: 20px;
				filter: none;
			}
		}

		.downloadDetail {
			width: 20px;
			height: 40px;
			display: flex;
			align-items: center;
			overflow: hidden;
			cursor: pointer;
			margin-right: 10px;
			img {
				width: 20px;
				height: 20px;
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				&.draft {
					filter: none;
				}
			}

			// Animation possible if needed
			p {
				@include font01;
				@include border-box;
				display: none;
				position: absolute;
				z-index: 300;
				bottom: 55px;
				right: 5px;
				width: 270px;
				height: auto;
				padding: 15px;
				background-color: $black;
				color: $white;

				@media screen and (min-width: $mq-3) {
					padding: 20px;
				}
				@media screen and (min-width: $mq-6) {
					right: -105px;
				}

				&:after {
					@include icon-tooltip;
					right: 13px;
					bottom: -10px;

					@media screen and (min-width: $mq-3) {
						right: 14px;
					}
					@media screen and (min-width: $mq-6) {
						right: 124px;
					}
				}
			}

			&:hover p {
				display: block;
			}
		}
	}

	.boxConfirmation {
		@include border-box;
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
		padding: 0 15px;
		margin: 20px 0;

		.cbxConfirm {
			@include ckb-common;
		}

		span {
			@include font01;
			margin-right: auto;
			line-height: 32px;
		}

		.lblPassive {
			@include font-light;
			display: block;
		}
		.lblActive {
			display: none;
		}

		&.confirmed {
			.lblPassive {
				display: block;
			}
			.lblActive {
				display: block;
			}
		}
	}

	// Lists
	.lstLanding {
		@include border-box;
		list-style: none;
		display: none;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin: 0;
		padding: 30px 0 0;
		gap: 26px;

		@media screen and (min-width: $mq-3) {
			padding: 40px 0 0;
		}
		@media screen and (min-width: $mq-5) {
			gap: 33px;
		}

		&.active {
			display: flex;
		}

		// TODO: Generalize Colors
		// Generalize spans
		li {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			position: relative;
			box-shadow: 0 2px 15px 0 $background-light;
			border-radius: 2px;
			background-color: $white;
			width: 100%;
			padding-top: 20px;
			cursor: pointer;

			// TODO: Generalize Column Behaviours
			@media screen and (min-width: $mq-3) {
				width: calc(50% - 13px);
			}
			@media screen and (min-width: $mq-4) {
				width: calc(33% - 15px);
			}
			@media screen and (min-width: $mq-5) {
				width: calc(25% - 25px);
			}

			p {
				@include font02;
				@include border-box;
				color: $black;
				width: 100%;
				text-align: center;
				padding: 0 15px;
				margin: 15px 0 0;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				-webkit-box-orient: vertical;
				// two lines
				height: 48px;

				@media screen and (min-width: $mq-3) {
					@include font05;
					padding: 0 15px;
					margin: 20px 0 0;
					height: 56px;
				}
			}

			.ncnumber {
				@include font00;

				color: rgba(154, 154, 154, 0.8);
				flex: 2;
				margin-left: 15px;
				text-align: left;
			}

			.reference {
				@include font00;

				color: rgba(154, 154, 154, 0.8);
				flex: 1;
				margin-right: 15px;
				text-align: right;
			}

			.dreportwrap {
				position: absolute;
				top: 37px;
				color: #000;
				font-size: 110px;
				opacity: 0.8;
				width: 100%;
				text-align: center;
				color: #a5d9ed;
				z-index: 0;
			}

			.dreportwrap1 {
				position: absolute;
				top: 118px;
				right: 10px;
				color: #000;
				font-size: 70px;
				opacity: 0.8;
				text-align: center;
				color: #a5d9ed;
				z-index: 0;
			}

			.title {
				@include font-bold;
				z-index: 1;
				.dicon {
					margin-right: 8px;
					display: inline-block;
					position: relative;
					.diocntext {
						position: absolute;
						top: -2px;
						left: 4px;
						color: #fff;
						font-size: 12px;
					}
					.diconimg {
						width: 22px;
						height: 22px;
						display: block;
					}
				}
			}

			.customercomplaint {
				@include font02;
				@include font-light;
				width: 100%;
				text-align: center;
				z-index: 1;
			}

			.date {
				@include font02;
				@include font-light;
				width: fit-content;
				margin: 8px auto 15px;
				padding: 5px 10px;

				@media screen and (min-width: $mq-3) {
					margin: 8px auto 20px;
				}
			}

			.overdue {
				@include font00;

				position: absolute;
				display: none;
				top: -11px;
				right: 10px;
				width: auto;
				padding: 3px 8px;
				text-align: center;
				border-radius: 16px;
				background-color: $red;
				color: $text-light-gray;
				margin: 0;

				@media screen and (min-width: $mq-3) {
					top: -13px;
					right: 15px;
					padding: 5px 10px;
				}
			}

			// same name can't cause any issue
			// but change would be better
			&.overdue .overdue {
				display: block;
			}

			&.overdue,
			&.pre-overdue {
				.date {
					background-color: $lightRed;
					color: $red;
				}
			}
		}

		li.listcard {
			&:hover {
				box-shadow: 0 2px 15px 0 rgb(0 0 0 / 40%);
			}
		}
		li.unletterli {
			box-shadow: none;
			background: #f0f0f0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 182px;
			@media screen and (min-width: $mq-3) {
				height: 224px;
			}
			@media screen and (min-width: $mq-4) {
				height: 222px;
			}
			@media screen and (min-width: $mq-5) {
				height: 222px;
			}
			.unletterliimage {
				height: 60px;
				width: 60px;
			}
			.unletterlitext {
				text-align: center;
				font-size: 16px;
				font-weight: 300;
				margin: 20px;
			}
			.unletterlibutton {
				width: 100%;
				color: $blue;
				font-size: 20px;
				font-weight: 500;
				text-align: center;
			}
		}
	}

	.tiptext {
		margin-top: 20px;
		width: 100%;
		@include font-light;
	}

	// Individual Inputs
	.btnTab {
		@include font04;
		color: $darkGray;
		background-color: transparent;
		width: 50%;
		text-align: center;
		padding: 10px 15px;
		border: 0;
		border-bottom: solid 3px $lighterGray;
		margin: 0 auto;
		margin-top: 24px;
		// background: $lighterGray;

		@media screen and (min-width: $mq-3) {
			margin-top: 0;
		}

		&:hover {
			color: $blue;
			border-bottom: solid 3px $blue;
			// background: #fff;
		}

		&.current {
			border-bottom: solid 3px $blue;
			color: $black;
			margin: 0 auto;
			margin-top: 24px;
			background: #fff;

			@media screen and (min-width: $mq-3) {
				margin-top: 0;
			}
		}

		.name {
			width: fit-content;
			position: relative;
			@include font-light;

			.quantity {
				@include font00;

				@include border-box;
				position: absolute;
				min-width: 20px;
				height: 20px;
				border-radius: 10px;
				top: -3px;
				right: -24px;
				background-color: $blue;
				color: $white;
				display: flex;
				justify-content: center;
				align-items: center;
				letter-spacing: 0;
				@media screen and (min-width: $mq-3) {
					top: 5px;
					right: -34px;
				}
			}
		}

		@media screen and (min-width: $mq-3) {
			@include font07;
		}
		@media screen and (min-width: $mq-4) {
			@include font08;
			// width: 375px;
			// margin-right: 2px;
		}
		@media screen and (min-width: $mq-6) {
			padding: 10px 20px;
		}
	}

	.requestempty {
		display: flex;
		margin: 40px auto 0 auto;
		width: 100%;
		flex-direction: column;
		.unletterwrap {
			padding: 30px 0;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: 0 2px 15px 0 rgb(0 0 0 / 40%);
			border-radius: 6px;
			margin-bottom: 20px;
			cursor: pointer;
			@media screen and (min-width: $mq-3) {
				flex-direction: row;
			}
			.unletterwrapimage {
				height: 60px;
				width: 60px;
				margin-left: 30px;
			}
			.unletterwraptext {
				flex: 1;
				text-align: center;
				font-size: 16px;
				font-weight: 300;
				margin: 20px;
				@media screen and (min-width: $mq-3) {
					margin: 0 0 0 30px;
					text-align: left;
				}
			}
			.unletterwrapbutton {
				width: 120px;
				color: $blue;
				font-size: 20px;
				font-weight: 500;
				text-align: center;
				@media screen and (min-width: $mq-3) {
					// margin: 30px 0 0 0;
					text-align: left;
				}
			}
		}
		.emptywrap {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			@media screen and (min-width: $mq-3) {
				flex-direction: row;
			}
			@media screen and (min-width: $mq-5) {
				width: 1200px;
			}
			.eimage {
				width: 90%;
				height: 262px;
				margin: 0;
				@media screen and (min-width: $mq-3) {
					margin: 0 20px 0 0;
					width: 463px;
				}
				@media screen and (min-width: $mq-4) {
					padding-left: 40px;
				}
				img {
					width: 100%;
					height: 262px;
					display: block;
					@media screen and (min-width: $mq-3) {
						width: 463px;
					}
				}
			}
			.ewrap {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				@media screen and (min-width: $mq-3) {
					align-items: flex-start;
				}
				@media screen and (min-width: $mq-4) {
					padding-right: 40px;
				}
				.etitle {
					@include font-bold;
					@include font03;
					text-align: center;
					@media screen and (min-width: $mq-3) {
						text-align: left;
					}
				}
				.econtent {
					margin-top: 16px;
					@include font-light;
					text-align: center;
					@media screen and (min-width: $mq-3) {
						text-align: left;
					}
				}
				.ebutton {
					margin: 40px 0 0 0;
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}

// Seperate Notification Entity
aside.landing {
	background-color: $white;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	position: absolute;
	border-radius: 2px;
	box-shadow: 0 2px 25px 0 $background-light;
	top: -130px;
	right: 5%;
	width: 90%;
	height: 110px;

	&.hidden {
		display: none;
	}

	@media screen and (min-width: $mq-2) {
		top: -195px;
		height: 80px;
	}
	@media screen and (min-width: $mq-3) {
		right: 0;
		top: -450px;
		width: 520px;
	}
	@media screen and (min-width: $mq-4) {
		top: -510px;
	}
	@media screen and (min-width: $mq-5) {
		top: -490px;
	}
	@media screen and (min-width: $mq-6) {
		top: -480px;
		width: 540px;
	}

	img.status {
		width: 32px;
		height: 32px;
		margin: 22px 14px 26px 18px;
		display: none;

		&.visible {
			display: block;
		}
	}

	p {
		width: calc(100% - 100px);
		color: $black;
		margin: 16px 0 5px;
		@include font-bold;
	}

	span {
		@include font01;
		width: calc(100% - 75px);
		color: $black;
		@include font-light;

		a {
			@include font-medium;
			@include transition(0.3s);
			color: $blue;
			text-decoration: none;

			&:hover {
				color: $darkBlue;
			}
		}
	}

	// TODO: Use specialized button mixin
	.btnHideNofitication {
		@include transition(0.3s);
		position: absolute;
		cursor: pointer;
		width: 40px;
		height: 40px;
		right: 0;
		top: 0;
		border: 0;
		margin: 0 0 0 auto;
		padding: 0;
		background-color: $white;

		img {
			@include transition(0.3s);
			width: 18px;
			height: 18px;
			margin: 11px;

			filter: invert(16%) sepia(86%) saturate(0%) hue-rotate(304deg)
				brightness(111%) contrast(103%);
		}

		&:hover {
			img {
				filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg)
					brightness(103%) contrast(100%);
			}
		}
	}
}
