.ant-notification {
  position: fixed;
  top: 33vh !important;
  right: 10px !important;
  // transform: translate(0, -50%);
  min-width: calc(320px - 20px);
  width: calc(100vw - 20px);
  max-width: $box-max;
  padding: 0;
  margin: 0;
  color: $black;
  @include font00;
  z-index: 1010;

	@media screen and (min-width: $mq-1) {
    top: 110px !important;
    transform: translate(0, 0);
  }

  @media screen and (min-width: $mq-3) {
    top: 126px !important;
    right: 30px !important;
    width: 50%;
    transform: translate(0, 0);
  }

  // @media screen and (min-width: $mq-4) {
  //   transform: translate(0, -50%);
  // }

  .btn-back-to-start {
    background: $blue;
    @include transition(0.3s);
    width: 130px;
    display: inline-block;
    background-color: $blue;
    border-radius: 2px;
    border: solid 2px $blue;
    text-align: center;
    height: 46px;
    line-height: 42px;
    cursor: pointer;
    @include font01;
    margin-top: $base-margin;
    margin-left: 150px;

    &:hover {
      background-color: $darkBlue;
      border: solid 2px $darkBlue;
    }

    a{ 
      color: $white;
      text-decoration: none;
    }
		span{ 
      color: $white;
      text-decoration: none;
    }
  }
	.de {
		margin-left: 193px;
	}
	.fr {
		margin-left: 182px;
	}
	.es {
		margin-left: 194px;
	}
	.pt {
		margin-left: 204px;
	}
  .ant-notification-notice-btn {
    position: absolute;
    bottom: $base-margin;
		
    
    button {
			// margin-top: 15px;
      @include transition(0.3s);
      min-width: 130px;
      border-radius: 2px;
      border: solid 2px $blue;
      background-color: $white;
      text-align: center;
      color: $blue;
      height: 46px;
      text-decoration: none;
      cursor: pointer;
      line-height: 42px;
      font-size: 16px;

      &:hover {
        background-color: $lightBlue;
      }
    }
  }
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: $base-margin;
}
.ant-notification-close-icon {
  @include font00;
  cursor: pointer;
}
.ant-notification-notice {
  position: relative;
  overflow: hidden;
  background: $white;
  border-radius: 2px;
	box-shadow: 0 2px 25px 0 $background-light;
	padding: $base-margin;
  padding-left: $base-margin*2.25;

  @media screen and (min-width: $mq-3) {
    padding-left: $base-margin*3;
  }
}
.ant-notification-notice-message {
  @include font-bold;
  @include font01;
  color: $black;
  margin-bottom: $base-margin/3;
}
.ant-notification-notice-description {
  @include font-light;
  @include font01;
  color: $black;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: $base-margin;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
	color: $black;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  @include font-light;
  text-align: left;
  color: $black;
}
.ant-notification-notice-icon {
  position: absolute;
  top: $base-margin;
  left: $base-margin/2;
  width: $icon-size;
  height: $icon-size;
  @include font06;

  @media screen and (min-width: $mq-3) {
    left: $base-margin;
  }
}
.anticon.ant-notification-notice-icon-success {
  color: $greenOk;
}
.anticon.ant-notification-notice-icon-info {
  color: $blue;
}
.anticon.ant-notification-notice-icon-warning {
  color: $warning;
}
.anticon.ant-notification-notice-icon-error {
  color: $redError;
}
.ant-notification-notice-close {
  @include close-button;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  animation-duration: $animation-time;
  animation-timing-function: $timing-function;
  animation-fill-mode: both;
  opacity: 0;
}
.ant-notification-fade-leave {
  animation-duration: $animation-time;
  animation-timing-function: $timing-function;
  animation-fill-mode: both;
  animation-duration: $animation-time;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  &.ant-notification-fade-enter-active,
  &.ant-notification-fade-appear-active {
    animation-name: NotificationFadeIn;
    animation-play-state: running;
  }
} 
.ant-notification-fade-leave {
  &.ant-notification-fade-leave-active {
    animation-name: NotificationFadeOut;
    animation-play-state: running;
  }
}
