// Functionality
@mixin transition($seconds) {
	transition: all $seconds ease-in-out;
	-moz-transition: all $seconds ease-in-out;
	-webkit-transition: all $seconds ease-in-out;
}

@mixin transform($seconds) {
	-webkit-transition: -webkit-transform $seconds ease-in-out;
	-ms-transition: -ms-transform $seconds ease-in-out;
	transition: transform $seconds ease-in-out;
}

@mixin border-box {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

@mixin box-emboss($opacity, $opacity2) {
	box-shadow: white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}

@mixin svg-icon($path, $height, $width, $color) {
	height: $height;
	width: $width;
	background-color: $color;
	mask-image: url($path);
	background-size: cover;
	-webkit-mask-size: contain;
	mask-size: contain;
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin abs-pos($top: auto, $right: auto, $bottom: auto, $left: auto) {
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	position: absolute;
}

// Text
@mixin txt-common(
	$size: $font-size-01,
	$height: normal,
	$font: $font-family,
	// fallback, needed?
	$letter: normal
) {
	font-size: $size;
	line-height: $height;
	letter-spacing: $letter;

	// font-family + font-weigth
	@if $font == HelveticaLight {
		@include font-light;
	} @else if $font == HelveticaMedium {
		@include font-medium;
	} @else {
		@include font-bold;
	}
}

// not used anywhere
// @mixin txt-common-fixed(
// 	$size: 16px,
// 	$height: normal,
// 	$font: '',
// 	$letter: normal
// ) {
// 	// font-family: 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
// 	font-size: $size;
// 	line-height: $height;
// 	letter-spacing: $letter;
// }

// Link
@mixin lnk-common {
	@include transition(0.3s);
	text-decoration: none;
	border: 0;
	background-color: transparent;
	color: $blue;
	width: 100%;

	&:hover {
		color: $darkBlue;
	}
}

// Buttons
@mixin btn-common {
	@include border-box;
	@include transition(0.3s);
	line-height: 46px; // TODO: still necessary / change this
	display: block;
	cursor: pointer;
	background-color: $blue;
	border: solid 2px $blue;
	text-align: center;
	border-radius: 2px;
	color: $white;
	width: 100%;
	// width: 160px;
	height: 50px;

	&:hover {
		background-color: $darkBlue;
		border: solid 2px $darkBlue;
	}
	&:focus {
		background-color: $blue;
		border: solid 2px $blue;
	}
}

@mixin btn-empty {
	@include btn-common;
	background-color: $white;
	color: $blue;

	&:hover {
		background-color: $lightBlue;
	}
	&:focus {
		background-color: $white;
	}
}

@mixin btn-cancel {
	@include btn-common;
	background-color: $background-gray;
	border: 0;
	color: $lightGray;

	&:hover {
		background-color: $background-gray-hover;
		border: 0;
		color: $darkGray;
	}
	&:focus {
		background-color: $background-gray;
		color: $lightGray;
	}
}

@mixin btn-sorter {
	background-color: $white;
	background-size: auto 14px;
	background-repeat: no-repeat;
	background-position: 90% 50%;
	background-image: none;
	display: table-cell;
	vertical-align: middle;
	border: 0;
	text-align: left;

	&:hover {
		background-color: $nearlyWhite;
	}

	&.asc {
		background-image: $smallUpArrow;
	}

	&.hoverasc {
		// background-image: none;
		&:hover {
			background-image: $smallUpArrow;
		}
	}

	&.desc {
		background-image: $smallDownArrow;
	}

	&.hoverdesc {
		// background-image: none;
		&:hover {
			background-image: $smallDownArrow;
		}
	}
}

@mixin btn-close {
	@include transition(0.3s);
	background-color: $white;
	background: $closeCommon;
	background-size: 18px 18px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	position: absolute;
	width: 40px;
	height: 40px;
	border: 0;
	margin: 0 0 0 auto;
	padding: 0;

	&:hover {
		transform: scale(1.4) rotate(90deg);
	}
}

@mixin btn-icon {
	@include border-box;
	@include transition(0.3s);
	position: relative;
	width: 18px;
	height: 16px;
	border: 0;
	margin: 0;
	padding: 0;
	background-color: transparent;

	img {
		@include transition(0.3s);
		// $white
		filter: invert(99%) sepia(4%) saturate(2%) hue-rotate(281deg)
			brightness(114%) contrast(100%);
		width: 18px;
		height: 16px;
	}
	&:hover {
		img {
			// $lighterGray
			filter: invert(96%) sepia(35%) saturate(0%) hue-rotate(227deg)
				brightness(95%) contrast(78%);
		}
	}
}

@mixin btn-shadow {
	@include btn-common;
	background-color: transparent;
	border: solid 2px $darkGray;
	color: $darkGray;
	border-radius: 25px;

	&.active {
		background-color: transparent;
		color: $blue;
		border: solid 2px $blue;
	}

	&:hover {
		background-color: transparent;
		color: $white;
		border: solid 2px $white;
	}
}

// Textbox
@mixin txb-common {
	@include transition(0.5s);
	@include font-light;
	@include border-box;
	color: $black;
	background-color: $white;
	border-radius: 2px;
	border: solid 2px $border-gray;
	width: 100%;
	height: 60px;
	padding: 10px 20px;

	&.gotError {
		background-color: $border-red;
		border-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
	}

	&:focus {
		outline: none !important;
		background-color: $white;
		// background-color: $greenOk;
		border: solid 2px $bluePassive;
	}

	// Non-Standard Selectors
	&:-webkit-autofill,
	&:-moz-autofill,
	&:-o-autofill,
	&:-khtml-autofill,
	&:focus:-webkit-autofill,
	&:focus:-moz-autofill,
	&:focus:-o-autofill,
	&:focus:-khtml-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background-color: $greenOk;
		border-color: transparent;
	}
}

@mixin txb-common-noborder {
	@include transition(0.5s);
	@include font-light;
	@include border-box;
	color: $black;
	background-color: $white;
	border-radius: 2px;
	border: solid 2px $border-gray;
	width: 100%;
	height: 60px;
	padding: 10px 20px;

	&.gotError {
		background-color: $border-red;
		border-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
		&:focus {
			outline: none !important;
			background-color: $white;
			// background-color: $greenOk;
			border: solid 2px $greenOk;
		}
	}

	&:focus {
		outline: none !important;
		background-color: $white;
		// background-color: $greenOk;
		border: solid 2px $border-gray;
	}

	// Non-Standard Selectors
	&:-webkit-autofill,
	&:-moz-autofill,
	&:-o-autofill,
	&:-khtml-autofill,
	&:focus:-webkit-autofill,
	&:focus:-moz-autofill,
	&:focus:-o-autofill,
	&:focus:-khtml-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background-color: $greenOk;
		border-color: transparent;
	}
}

@mixin txb-shadow {
	@include txb-common;
	background-color: transparent;
	color: $white;

	&.gotError {
		background-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
	}

	&.isClean {
		background-color: transparent;
	}

	&:focus {
		background-color: transparent;
		border: solid 2px $white;
	}

	// Non-Standard Selectors
	&:-webkit-autofill,
	&:-moz-autofill,
	&:-o-autofill,
	&:-khtml-autofill,
	&:focus:-webkit-autofill,
	&:focus:-moz-autofill,
	&:focus:-o-autofill,
	&:focus:-khtml-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background-color: $greenOk;
		border-color: $white;
	}
}

// Date Time Picker
@mixin dtp-common {
	@include transition(0.5s);
	@include border-box;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	outline-width: 0;
	color: $black;
	background-color: $white;
	background-image: $calendar;
	background-repeat: no-repeat;
	background-position: 18px 17px;
	background-size: 20px;
	border-radius: 2px;
	border: solid 2px $border-gray;
	width: 100%;
	height: 60px;
	padding: 10px 20px 10px 55px;

	&::-webkit-calendar-picker-indicator {
		background-image: $ddlArrow;
		background-repeat: no-repeat;
		background-size: 22px auto;
		cursor: pointer;
		margin-top: 5px;
	}
	// &::-webkit-datetime-edit{}
	// &::-webkit-datetime-edit-fields-wrapper{}
	// &::-webkit-datetime-edit-text{}
	// &::-webkit-datetime-edit-month-field{}
	// &::-webkit-datetime-edit-day-field{}
	// &::-webkit-datetime-edit-year-field{}
	// &::-webkit-inner-spin-button{}

	&.gotError {
		background-color: $border-red;
		border-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
	}

	&.isClean {
		background-color: $greenOk;
		border-color: transparent;
	}

	&:focus {
		outline: none !important;
		background-color: $white;
		border: solid 2px $bluePassive;
	}
}

@mixin dtp-shadow {
	@include dtp-common;
	@include font-light;
	padding: 10px 20px;
	background-color: transparent;
	background-image: none;
	color: $white;

	&::-webkit-calendar-picker-indicator {
		background-image: $calendar;
		background-size: 20px auto;
		filter: invert(1);
		// mix-blend-mode: difference;
		margin-top: 0;
	}

	&.gotError {
		background-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
	}

	&.isClean {
		background-color: transparent;
	}

	&:focus {
		background-color: transparent;
		border: solid 2px $white;
	}
}

// Textarea
@mixin txa-common {
	@include transition(0.5s);
	@include font-light;
	@include border-box;
	resize: vertical;
	overflow-x: hidden;
	overflow-y: auto;
	color: $black;
	background-color: $white;
	border-radius: 2px;
	border: solid 2px $border-gray;
	width: 100%;
	min-height: 100px;
	padding: 20px;

	&.gotError {
		background-color: $border-red !important;
		border-color: transparent;
	}

	&.gotError:focus {
		outline: none !important;
		background-color: $border-red !important;
		border-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
	}

	&:focus {
		outline: none !important;
		// background-color: $white;
		background-color: $white;
		border: solid 2px $bluePassive;
	}

	// color/border/opacity style
	// is not possible on resizer
	&::-webkit-resizer {
		background: $areaResizer;
	}
}

@mixin txa-common-noborder {
	@include transition(0.5s);
	@include font-light;
	@include border-box;
	resize: vertical;
	overflow-x: hidden;
	overflow-y: auto;
	color: $black;
	background-color: $white;
	border-radius: 2px;
	border: solid 2px $border-gray;
	width: 100%;
	min-height: 100px;
	padding: 20px;

	&.gotError {
		background-color: $border-red !important;
		border-color: transparent;
	}

	&.gotError:focus {
		outline: none !important;
		background-color: $border-red !important;
		border-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
		&:focus {
			outline: none !important;
			// background-color: $white;
			background-color: $white;
			border: solid 2px $greenOk;
		}
	}

	&:focus {
		outline: none !important;
		// background-color: $white;
		background-color: $white;
		border: solid 2px $border-gray;
	}

	// color/border/opacity style
	// is not possible on resizer
	&::-webkit-resizer {
		background: $areaResizer;
	}
}

// Select
@mixin ddl-common {
	@include transition(0.3s);
	line-height: 40px; // TODO: change this, if possible
	@include border-box;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	position: relative;
	outline-width: 0;
	// background-image: $ddlArrow;
	// background-repeat: no-repeat;
	// background-position: 95% 50%;
	// background-size: 20px;
	background-color: $white;
	border: solid 2px $border-gray;
	border-radius: 2px;
	color: $black;
	width: 100%;
	height: 60px;
	padding: 9px 20px;

	&:focus {
		outline: none !important;
		background-color: $white;
		border: solid 2px $bluePassive;
	}
	&.hidden {
		display: none;
		height: 0;
		width: 0;
	}
	&.choiceMade {
		background-color: $greenOk;
		border-color: transparent;
	}
	&.gotError {
		background-color: $border-red;
		border-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
	}

	.options {
		@include ops-common;
	}

	.countryphoneoptions {
		@include ops-common;
		height: 400px;
		overflow: auto;
	}

	.lineControl {
		position: relative;
		width: 100%;
	}

	.iconUp {
		@include icon-up;
		right: 0;
		top: 12px;
	}
	.iconDown {
		@include icon-down;
		right: 0;
		top: 12px;
	}
}

@mixin ddl-common-no-filter {
	@include transition(0.3s);
	line-height: 40px; // TODO: change this, if possible
	@include border-box;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	position: relative;
	outline-width: 0;
	// background-image: $ddlArrow;
	// background-repeat: no-repeat;
	// background-position: 95% 50%;
	// background-size: 20px;
	background-color: $white;
	border: solid 2px $border-gray;
	border-radius: 2px;
	color: $black;
	width: 100%;
	height: 60px;
	padding: 9px 20px;

	&:focus {
		outline: none !important;
		background-color: $white;
		border: solid 2px $bluePassive;
	}
	&.hidden {
		display: none;
		height: 0;
		width: 0;
	}
	&.choiceMade {
		background-color: $greenOk;
		border-color: transparent;
	}
	&.gotError {
		background-color: $border-red;
		border-color: transparent;
	}

	&.isFilled {
		background-color: $greenOk !important;
		border-color: transparent;
	}

	.options {
		@include ops-common;
	}

	.countryphoneoptions {
		@include ops-common;
		height: 400px;
		overflow: auto;
	}

	.lineControl {
		position: relative;
		width: 100%;
	}

	.iconUp {
		@include icon-up;
		right: 0;
		top: 12px;
	}
	.iconDown {
		@include icon-down-no-filter;
		right: 0;
		top: 12px;
	}
}

// Options List of Selects
@mixin ops-common {
	@include font-medium; // TODO: medium right here?
	line-height: 30px; // TODO: get rid of this approach
	background-color: $white;
	box-shadow: 0 0 15px 0 $background-light;
	clip-path: inset(0px -15px -15px -15px);
	position: absolute;
	z-index: 900;
	top: 58px;
	// Design Adjustment
	left: -2px;
	right: -2px;
	width: calc(100% + 4px);

	.Dropdown-option {
		@include transition(0.2s);
		@include border-box;
		padding: 15px 20px;
		width: 100%;

		&:hover {
			color: $blue;
		}
	}
}

// Lists
@mixin lst-common {
	@include border-box;
	list-style: none;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	margin: 0;
	padding: 0;
}

// Pseudo CheckBox
@mixin ckb-common {
	@include transition(0.3s);
	display: block;
	cursor: pointer;
	border: 0;
	background: $cbxPassive;
	width: 32px;
	height: 32px;

	&:hover {
		background: $cbxHover;
	}

	&.confirmed {
		background: $cbxActive;
	}
}

@mixin ckb-common-prview {
	@include transition(0.3s);
	display: block;
	cursor: not-allowed;
	border: 0;
	background: $cbxPassive;
	width: 32px;
	height: 32px;

	&:hover {
		background: $cbxPassive;
	}

	&.confirmed {
		background: $cbxPassive;
	}
}

// ICONS

// File Extension Icons
// There are two different sizes for every extension icon
// There are also different colored icons for different states
@mixin icon-ext {
	width: 45px;
	height: 53px;
	background-repeat: no-repeat;

	&.docx {
		background-image: $docxNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $docxNormalBig;
		}

		&.upload {
			background-image: $docxUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $docxUploadBig;
			}
		}

		&.uploading {
			background-image: $docxUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $docxUploadingBig;
			}
		}

		&.error {
			background-image: $docxErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $docxErrorBig;
			}
		}
	}

	&.msg {
		background-image: $msgNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $msgNormalBig;
		}

		&.upload {
			background-image: $msgUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $msgUploadBig;
			}
		}

		&.uploading {
			background-image: $msgUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $msgUploadingBig;
			}
		}

		&.error {
			background-image: $msgErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $msgErrorBig;
			}
		}
	}

	&.jfif {
		&.error {
			background-image: $jfifErrorBig;

			@media screen and (min-width: $mq-3) {
				background-image: $jfifErrorBig;
			}
		}
	}

	&.png {
		background-image: $pngNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $pngNormalBig;
		}

		&.upload {
			background-image: $pngUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pngUploadBig;
			}
		}

		&.uploading {
			background-image: $pngUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pngUploadingBig;
			}
		}

		&.error {
			background-image: $pngErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pngErrorBig;
			}
		}
	}
	&.jpg {
		background-image: $jpgNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $jpgNormalBig;
		}

		&.upload {
			background-image: $jpgUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $jpgUploadBig;
			}
		}

		&.uploading {
			background-image: $jpgUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $jpgUploadingBig;
			}
		}

		&.error {
			background-image: $jpgErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $jpgErrorBig;
			}
		}
	}
	&.xlsx {
		background-image: $xlsxNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $xlsxNormalBig;
		}

		&.upload {
			background-image: $xlsxUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $xlsxUploadBig;
			}
		}

		&.uploading {
			background-image: $xlsxUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $xlsxUploadingBig;
			}
		}

		&.error {
			background-image: $xlsxErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $xlsxErrorBig;
			}
		}
	}
	&.pdf {
		background-image: $pdfNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $pdfNormalBig;
		}

		&.upload {
			background-image: $pdfUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pdfUploadBig;
			}
		}

		&.uploading {
			background-image: $pdfUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pdfUploadingBig;
			}
		}

		&.error {
			background-image: $pdfErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pdfErrorBig;
			}
		}
	}
	&.txt {
		background-image: $txtNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $txtNormalBig;
		}

		&.upload {
			background-image: $txtUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $txtUploadBig;
			}
		}

		&.uploading {
			background-image: $txtUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $txtUploadingBig;
			}
		}

		&.error {
			background-image: $txtErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $txtErrorBig;
			}
		}
	}
	&.ppt {
		background-image: $pptNormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $pptNormalBig;
		}

		&.upload {
			background-image: $pptUploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pptUploadBig;
			}
		}

		&.uploading {
			background-image: $pptUploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pptUploadingBig;
			}
		}

		&.error {
			background-image: $pptErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $pptErrorBig;
			}
		}
	}
	&.mp4 {
		background-image: $mp4NormalSma;

		@media screen and (min-width: $mq-3) {
			background-image: $mp4NormalBig;
		}

		&.upload {
			background-image: $mp4UploadSma;

			@media screen and (min-width: $mq-3) {
				background-image: $mp4UploadBig;
			}
		}

		&.uploading {
			background-image: $mp4UploadingSma;

			@media screen and (min-width: $mq-3) {
				background-image: $mp4UploadingBig;
			}
		}

		&.error {
			background-image: $mp4ErrorSma;

			@media screen and (min-width: $mq-3) {
				background-image: $mp4ErrorBig;
			}
		}
	}
}

@mixin icon-cancel {
	@include border-box;
	background-repeat: no-repeat;
	background-image: $closeCommon;
	background-color: $white;
	background-position: center center;
	background-size: 18px 18px;
	width: 40px;
	height: 40px;
	padding: 11px;
	border: 0;
}

@mixin icon-tooltip {
	content: '';
	background: $tooltip no-repeat;
	background-size: cover;
	display: block;
	position: absolute;
	width: 22px;
	height: 10px;
}

@mixin icon-down {
	@include transition(0.3s);
	width: 18px;
	height: 12px;
	position: absolute;

	filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
		brightness(88%) contrast(85%);
}

@mixin icon-down-no-filter {
	@include transition(0.3s);
	width: 18px;
	height: 12px;
	position: absolute;
}

@mixin icon-up {
	@include transition(0.3s);
	width: 18px;
	height: 12px;
	position: absolute;

	filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
		brightness(88%) contrast(85%);
}

@mixin icon-success {
	@include transition(0.3s);
	width: 32px;
	height: 32px;
	margin: 0;
	padding: 0;
}

// FONTS
@mixin font {
	font-family: $font-family;
}

@mixin letter-space {
	letter-spacing: 1px;
}

@mixin font-light {
	@include font; // TODO: delete later, after elminating Helvetica entirely
	font-weight: 300;
}

@mixin font-medium {
	@include font; // TODO: delete later, after elminating Helvetica entirely
	font-weight: 500;
}

@mixin font-bold {
	@include font; // TODO: delete later, after elminating Helvetica entirely
	font-weight: 700;
}

@mixin font00 {
	font-size: $font-size-00;
	line-height: $font-size-00 + 6;
	@include font-medium;
	@include letter-space;
}

@mixin font01 {
	font-size: $font-size-01;
	line-height: $font-size-01 + 6;
}

@mixin font02 {
	font-size: $font-size-02;
	line-height: $font-size-02 + 6;
}

@mixin font03 {
	font-size: $font-size-03;
	line-height: $font-size-03 + 6;
}

@mixin font04 {
	font-size: $font-size-04;
	line-height: $font-size-04 + 6;
}

@mixin font05 {
	font-size: $font-size-05;
	line-height: $font-size-05 + 6;
}

@mixin font06 {
	font-size: $font-size-06;
	line-height: $font-size-06 + 6;
}

@mixin font07 {
	font-size: $font-size-07;
	line-height: $font-size-07 + 10;
}

@mixin font08 {
	font-size: $font-size-08;
	line-height: $font-size-08 + 10;
}

@mixin font09 {
	font-size: $font-size-09;
	line-height: $font-size-09 + 10;
}

@mixin font10 {
	font-size: $font-size-10;
	line-height: $font-size-10 + 10;
}

// BUTTONS
@mixin close-button {
	position: absolute;
	top: 0;
	right: 0;
	outline: none;
	width: 40px;
	height: 40px;
	display: block;
	text-align: center;
	color: #000;
	@include font02;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

// GRID
@mixin grid-content {
	background-color: $white;
	width: 90%;
	max-width: $content-max-width;
	padding: 0 5%;
	height: auto;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
	margin-top: $base-margin * 1.5;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $base-margin * 2;

	@media screen and (min-width: $mq-3) {
		margin-top: $base-margin * 2.5;
		margin-bottom: $base-margin * 3;
		width: 86%;
		padding: 0 7%;
	}
	@media screen and (min-width: $mq-4) {
		margin-top: $base-margin * 3;
		margin-bottom: $base-margin * 4;
	}
}
