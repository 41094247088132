@import '../../assets/cascades/_utilities';
@import "../../assets/cascades/_typo";

// Custom Margin Mixin
section.faq {
	@include grid-content;
	
	.ui-main-heading {
		@include font05;
		
		@media screen and (min-width: $mq-3) {
			@include font09;
		}
		@media screen and (min-width: $mq-4) {
			@include font10;
		}
	}
	.ui-sub-heading {
		@include font04;
		margin-top: 20px;

		&.has-color {
			color: $blue;
		}
		@media screen and (min-width: $mq-3) {
			@include font07;
		}
		@media screen and (min-width: $mq-4) {
			@include font08;
		}
	}
	.faq-content-area {
		padding: $base-margin/2 0;
		margin-top: $base-margin/2;
		// margin-bottom: $base-margin;
		
		// @media screen and (min-width: $mq-3) {
		// 	margin-bottom: $base-margin*3;
		// }
		// @media screen and (min-width: $mq-4) {
		// 	margin-bottom: $base-margin*4;
		// }
		// border-top: 2px solid $nearlyWhite;

		.ui-sub-heading {
			margin: $base-margin/6 0 $base-margin/2;
			padding-left: $base-margin/2;
		}
		
	}
	.faq-lst {
		list-style-type: none;
		padding-left: $base-margin;
		@extend .ui-copy-text;
		@include lst-common;

		// adjustment for 1ST ORDER faq-lst-items
		.faq-lst-item {
			position: relative;
			margin: $base-margin/2 0;
			background: $nearlyWhite;
			border: 2px solid transparent;
			@include border-box;
			transition: all $animation-time $animation-time*2/3 ease-in-out;

			.faq-lst-item-panel {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 $base-margin/2;
				cursor: pointer;

				@media screen and (min-width: $mq-2) {
					padding: 0 $base-margin*3/4;
				}
				@media screen and (min-width: $mq-3) {
					padding: 0 $base-margin;
				}

				&:hover {
					.accordion-icon {
						filter: invert(35%) sepia(89%) saturate(676%) 
							hue-rotate(164deg) brightness(96%) contrast(92%);
					}
				}
			}
			.faq-lst-item-headline {
				@include font04;
				@include font-light;
				margin: $base-margin 0;
				padding-right: $base-margin/4;
				flex: 1 0 0;

				@media screen and (min-width: $mq-3) {
					@include font07;
				}
				@media screen and (min-width: $mq-4) {
					@include font08;
				}
			}
			.faq-lst-item-content {
				max-height: 0;
				overflow: hidden;
				transition: all $animation-time ease-out;
				a {
					color: $blue !important;
					text-decoration: none;
					&:link {
						color: $blue !important;
						text-decoration: none;
					}
					&:visited {
						color: $blue !important;
						text-decoration: none;
					}
					&:hover {
						color: $blue !important;
						text-decoration: none;
					}
				}
				a {
					span {
						color: $blue !important;
						text-decoration: none;
						&:link {
							color: $blue !important;
							text-decoration: none;
						}
						&:visited {
							color: $blue !important;
							text-decoration: none;
						}
						&:hover {
							color: $blue !important;
							text-decoration: none;
						}
					}
				}
				.answerimage {
					margin-bottom: 24px;
					max-width: 100%;
					img {
						max-width: 100%;
					}
				}
				.answerimage2 {
					margin-bottom: 20px;
					margin-top: 20px;
					max-width: 100%;
					img {
						max-width: 100%;
					}
				}
			}
			.faq-lst-item-icon {
				position: relative;
				top: 5px; // to place icon as good as possible
    			margin: -6px 5px 0; // to avoid higher line-heights inside paragraph
				width: 22px;
				height: 22px;
				
				&.big {
					top: 7px;
					margin: -10px 5px 0; // to avoid higher line-heights inside paragraph
					width: 26px;
					height: 26px;
				}
			}
			&.expanded {
				border-top: 2px solid $nearlyWhite;
				border-bottom: 2px solid $nearlyWhite;
				// margin-bottom: 0; // intended by Design, but looks weird when 2 main categories are open
				background: transparent;
				transition: all $animation-time ease-in-out;
				>.faq-lst-item-content {
					max-height: 2000px; // use whatever value here, just bigger than content ever be expected to be
					transition: all $animation-time*4/3 $animation-time*2/3 ease-in;
				}
				// only address the very next panel level of icons here
				>.faq-lst-item-panel{
					.accordion-icon {
						&.expand { 
							opacity: 0;
						}
						&.shrink { 
							opacity: 1;
						}
					}
				}
			}
			// further elements
			.faq-lst-item-element {
				margin: $base-margin/2 0;
				a {
					color: $blue;
					text-decoration: none;
				}
			}
			.faq-lst-item-unordered-lst {
				padding-left: $base-margin*2/3;
				list-style-type: disc;

				li {
					margin: $base-margin/3 0;
				} 
			}
			.faq-lst-item-image {
				margin-top: $base-margin*2/3;
				@media screen and (min-width: $mq-4) {
					max-width: 66%;
				}
			}
			// adjustment for 2ND ORDER faq-lst-items
			.faq-lst-item {
				&.expanded {
					// margin-bottom: $base-margin/2; // intended by Design, but looks weird when 2 main categories are open
					border-right: 2px solid $nearlyWhite;
					border-left: 2px solid $nearlyWhite;
				}
				&:last-child {
					margin-bottom: $base-margin;
				}
				.faq-lst-item-headline {
					@include font02;
					@include font-bold;
					margin: $base-margin/2 0;

					@media screen and (min-width: $mq-3) {
						@include font05;
					}
				}
				.faq-lst-item-content {
					padding: 0 $base-margin;
					// word-wrap: break-word; word-break: normal;
					// text-align: left;
					// p {
					// 	word-wrap: break-word; word-break: normal;
					// }
				}
			}
			
		}
	}
	.btnExpand {
		position: relative;
		width: 40px;
		height: 40px;
		border: 0;
		padding: 0;
		background-color: transparent;

		.accordion-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			width: 18px;
			height: 18px;
			filter: invert(47%) sepia(97%) saturate(437%) 
				hue-rotate(150deg) brightness(97%) contrast(85%);
	
			&.shrink { 
				opacity: 0; 
			}
			transition: opacity 0s $animation-time*2/3, filter $animation-time;
		}
		// &.expanded {
		// 	.accordion-icon {
		// 		&.expand { 
		// 			opacity: 0;
		// 		}
		// 		&.shrink { 
		// 			opacity: 1;
		// 		}
		// 	}
		// }
	}
}
