// Main
$basfLogo: url('../icons/logo.svg');
$iconLanguages: url('../icons/languages.svg');

// Content
$ddlArrow: url('../icons/down.png');

$downArrow: url('../icons/arrow-down.svg');
$upArrow: url('../icons/arrow-up.svg');
$smallDownArrow: url('../icons/arrow-small-down.svg');
$smallUpArrow: url('../icons/arrow-small-up.svg');

$closeCommon: url('../icons/close.svg');
$calendar: url('../icons/calendar.svg');
$tooltip: url('../icons/tooltip.svg');

// Pseudo Checkbox
$cbxActive: url('../icons/cbxActive.svg');
$cbxPassive: url('../icons/cbxPassive.svg');
$cbxHover: url('../icons/cbxHover.svg');

// TextArea Resizer
$areaResizer: url('../icons/resizer.png');

// Extension Icons
$docxNormalBig: url('../extensions/DOCX-normal-big.svg');
$docxNormalSma: url('../extensions/DOCX-normal-small.svg');
$docxUploadBig: url('../extensions/DOCX-upload-big.svg');
$docxUploadSma: url('../extensions/DOCX-upload-small.svg');
$docxUploadingBig: url('../extensions/DOCX-uploading-big.svg');
$docxUploadingSma: url('../extensions/DOCX-uploading-small.svg');
$docxErrorBig: url('../extensions/DOCX-error-big.svg');
$docxErrorSma: url('../extensions/DOCX-error-small.svg');

$msgNormalBig: url('../extensions/MSG-normal-big.svg');
$msgNormalSma: url('../extensions/MSG-normal-small.svg');
$msgUploadBig: url('../extensions/MSG-upload-big.svg');
$msgUploadSma: url('../extensions/MSG-upload-small.svg');
$msgUploadingBig: url('../extensions/MSG-uploading-big.svg');
$msgUploadingSma: url('../extensions/MSG-uploading-small.svg');
$msgErrorBig: url('../extensions/MSG-error-big.svg');
$msgErrorSma: url('../extensions/MSG-error-small.svg');

$jfifErrorBig: url('../extensions/JFIF-error-big.svg');

$jpgNormalBig: url('../extensions/JPG-normal-big.svg');
$jpgNormalSma: url('../extensions/JPG-normal-small.svg');
$jpgUploadBig: url('../extensions/JPG-upload-big.svg');
$jpgUploadSma: url('../extensions/JPG-upload-small.svg');
$jpgUploadingBig: url('../extensions/JPG-uploading-big.svg');
$jpgUploadingSma: url('../extensions/JPG-uploading-small.svg');
$jpgErrorBig: url('../extensions/JPG-error-big.svg');
$jpgErrorSma: url('../extensions/JPG-error-small.svg');

$pdfNormalBig: url('../extensions/PDF-normal-big.svg');
$pdfNormalSma: url('../extensions/PDF-normal-small.svg');
$pdfUploadBig: url('../extensions/PDF-upload-big.svg');
$pdfUploadSma: url('../extensions/PDF-upload-small.svg');
$pdfUploadingBig: url('../extensions/PDF-uploading-big.svg');
$pdfUploadingSma: url('../extensions/PDF-uploading-small.svg');
$pdfErrorBig: url('../extensions/PDF-error-big.svg');
$pdfErrorSma: url('../extensions/PDF-error-small.svg');

$pngNormalBig: url('../extensions/PNG-normal-big.svg');
$pngNormalSma: url('../extensions/PNG-normal-small.svg');
$pngUploadBig: url('../extensions/PNG-upload-big.svg');
$pngUploadSma: url('../extensions/PNG-upload-small.svg');
$pngUploadingBig: url('../extensions/PNG-uploading-big.svg');
$pngUploadingSma: url('../extensions/PNG-uploading-small.svg');
$pngErrorBig: url('../extensions/PNG-error-big.svg');
$pngErrorSma: url('../extensions/PNG-error-small.svg');

$xlsxNormalBig: url('../extensions/XLSX-normal-big.svg');
$xlsxNormalSma: url('../extensions/XLSX-normal-small.svg');
$xlsxUploadBig: url('../extensions/XLSX-upload-big.svg');
$xlsxUploadSma: url('../extensions/XLSX-upload-small.svg');
$xlsxUploadingBig: url('../extensions/XLSX-uploading-big.svg');
$xlsxUploadingSma: url('../extensions/XLSX-uploading-small.svg');
$xlsxErrorBig: url('../extensions/XLSX-error-big.svg');
$xlsxErrorSma: url('../extensions/XLSX-error-small.svg');

$pptNormalBig: url('../extensions/PPT-normal-big.svg');
$pptNormalSma: url('../extensions/PPT-normal-small.svg');
$pptUploadBig: url('../extensions/PPT-upload-big.svg');
$pptUploadSma: url('../extensions/PPT-upload-small.svg');
$pptUploadingBig: url('../extensions/PPT-uploading-big.svg');
$pptUploadingSma: url('../extensions/PPT-uploading-small.svg');
$pptErrorBig: url('../extensions/PPT-error-big.svg');
$pptErrorSma: url('../extensions/PPT-error-small.svg');

$txtNormalBig: url('../extensions/TXT-normal-big.svg');
$txtNormalSma: url('../extensions/TXT-normal-small.svg');
$txtUploadBig: url('../extensions/TXT-upload-big.svg');
$txtUploadSma: url('../extensions/TXT-upload-small.svg');
$txtUploadingBig: url('../extensions/TXT-uploading-big.svg');
$txtUploadingSma: url('../extensions/TXT-uploading-small.svg');
$txtErrorBig: url('../extensions/TXT-error-big.svg');
$txtErrorSma: url('../extensions/TXT-error-small.svg');

$mp4NormalBig: url('../extensions/MP4-normal-big.svg');
$mp4NormalSma: url('../extensions/MP4-normal-small.svg');
$mp4UploadBig: url('../extensions/MP4-upload-big.svg');
$mp4UploadSma: url('../extensions/MP4-upload-small.svg');
$mp4UploadingBig: url('../extensions/MP4-uploading-big.svg');
$mp4UploadingSma: url('../extensions/MP4-uploading-small.svg');
$mp4ErrorBig: url('../extensions/MP4-error-big.svg');
$mp4ErrorSma: url('../extensions/MP4-error-small.svg');
