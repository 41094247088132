// new styling
.ant-spin-nested-loading {
    position: relative;

    .ant-spin {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 100vh;
        display: none;
        opacity: 0;
        transition: transform $animation-time $timing-function;
    }

    .ant-spin-spinning {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
    }

    .ant-spin-container {
        position: relative;
        transition: opacity $animation-time;
        // special case to trigger absolute position of bgImage properly
        border: 1px solid transparent;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            display: none;
            width: 100%;
            height: 100%;
            background: $white;
            opacity: 0;
            transition: all $animation-time;
            content: '';
            pointer-events: none;
        }
    }
    
    .ant-spin-dot {
        position: relative;
        display: inline-block;
        width: $base-margin*5;
        height: $base-margin*5;
        animation: cssload-dot-rotation 3s infinite linear;
    }

    .ant-spin-blur {
        clear: both;
        opacity: 0.1;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none;
    }
    .ant-spin-dot-item {
        position: absolute;
        display: block;
        width: $base-margin*1.5;
        height: $base-margin*1.5;
        background-color: transparent;
        border-radius: 100%;
        transform: scale(1);
        transform-origin: 50% 50%;
        opacity: 1;
        // animation: cssload-dot-colors 4s 0s $animation-timing-function infinite;
    }
    .ant-spin-dot-item:nth-child(1) {
        top: 0;
        left: 0;
        animation-delay: $animation-time;
				background-color: #004A96;
    }
    .ant-spin-dot-item:nth-child(2) {
        top: 0;
        right: 0;
        animation-delay: $animation-time*2;
				background-color: #C50022;
    }
    .ant-spin-dot-item:nth-child(3) {
        right: 0;
        bottom: 0;
        animation-delay: $animation-time*3;
				background-color: #00793A;
    }
    .ant-spin-dot-item:nth-child(4) {
        bottom: 0;
        left: 0;
        animation-delay: $animation-time*4;
				background-color: #F39500;
    }
}

// @-webkit-keyframes antSpinMove {
//     to {
//       opacity: 1;
//     }
// }
// @keyframes antSpinMove {
//     to {
//       opacity: 1;
//     }
// }
// @-webkit-keyframes antRotate {
//     to {
//       transform: rotate(405deg);
//     }
// }
// @keyframes antRotate {
//     to {
//       transform: rotate(405deg);
//     }
// }
