@import '../../assets/cascades/utilities';

// WORK-IN-PROGRESS
section.register {
	background-color: $white;
	width: 90%;
	padding: 0 5%;
	height: auto;
	display: flex;
	flex-flow: column wrap;
	overflow: hidden;
	// Design Adjustment
	margin-top: 146px;

	// box height: 455
	// TODO: custom margin mixin
	@media screen and (min-width: $mq-2) {
		margin-top: 226px;
	}
	@media screen and (min-width: $mq-3) {
		// HEADER+FOOTER 254px
		height: 480px;
		width: calc(50% - 80px);
		margin: calc(50vh - 358px) 7%;
		padding: 0 0 0 40px;
	}
	@media screen and (min-width: $mq-4) {
		// HEADER+FOOTER 230px
		height: 490px;
		width: calc(40% - 80px);
		margin: calc(50vh - 352px) 7%;
	}
	@media screen and (min-width: $mq-5) {
		// HEADER+FOOTER 234px
		height: 545px;
		width: calc(35% - 80px);
		margin: calc(50vh - 382px) 7%;
	}
	@media screen and (min-width: $mq-6) {
		// HEADER+FOOTER 238px
		height: 560px;
		width: calc(30% - 80px);
		margin: calc(50vh - 391px) 7%;
	}

	// Header
	// TODO: custom margin mixin
	// TODO: Generalize Headers
	h2 {
		width: 100%;
		color: $black;
		margin-bottom: 20px;
		@include font02;

		@media screen and (min-width: $mq-2) {
			width: 100%;
			margin-bottom: 25px;
		}
		@media screen and (min-width: $mq-3) {
			width: 100%;
		}
		@media screen and (min-width: $mq-4) {
			font-size: 22px;
			margin-bottom: 25px;
		}
	}

	// Inputs
	.txbRegisterItem {
		@include txb-common;
	}

	.btnRegister {
		@include btn-common;
		margin-bottom: 40px;
		width: calc(100% - 40px);

		@media screen and (min-width: $mq-6) {
			margin-bottom: 60px;
		}
	}

	// TODO: custom margin mixin
	// TODO: generalized link
	.lnkBack {
		width: 100%;
		margin: 35px 0 15px;
		text-decoration: none;
		color: $blue;

		@media screen and (min-width: $mq-2) {
			margin: 30px 0 10px;
		}
		@media screen and (min-width: $mq-3) {
			margin: 25px 0 15px;
		}
		@media screen and (min-width: $mq-5) {
			margin: 40px 0 15px;
		}

		img {
			@include transition(0.3s);
			width: 7px;
			margin: auto 5px 0 0;
			// $blue
			filter: invert(47%) sepia(97%) saturate(437%) 
				hue-rotate(150deg) brightness(97%) contrast(85%);
		}

		span {
			@include transition(0.3s);
			text-decoration: none;

			// @media screen and (min-width: $mq-4) {
			//     font-size: 22px;
			// }
		}

		&:hover {
			color: $darkBlue;

			img {
				// $darkBlue
				filter: invert(34%) sepia(92%) saturate(651%) 
					hue-rotate(163deg) brightness(100%) contrast(90%);
			}
		}
	}

	// Lists
	// TODO: generalizations for lists and errors
	// TODO: custom margin mixin
	.lstForm {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin-bottom: 42px;
			position: relative;

			@media screen and (min-width: $mq-3) {
				margin-bottom: 30px;
			}
			@media screen and (min-width: $mq-5) {
				margin-bottom: 40px;
			}

			&:last-child {
				margin-bottom: 40px;
			}

			.inputwrap {
				display: flex;
				align-items: center;
				input {
					flex: 1;
				}
				.tooltipwrap {
					width: 40px;
					.tooltipInput {
						width: 40px;
						cursor: pointer;
						display: none;
						img {
							// @include transition(0.3s);
							width: 22px;
							height: 22px;
							margin: 9px;
							cursor: pointer;
							filter: invert(75%) sepia(0%) saturate(2657%) 
									hue-rotate(20deg) brightness(96%) contrast(82%);

						&:hover {
							filter: invert(35%) sepia(89%) saturate(676%) 
									hue-rotate(164deg) brightness(96%) contrast(92%);
							}
						}	
						&.visible {
							display: block;
						}
					}
				}
			}
			span.errInput { 
				width: calc(100% - 40px);
				@include transition(0.5s);
				position: absolute;
				opacity: 0;
				top: 71px;
				left: 0;
				color: $red;

				&.visible {
					opacity: 1;
				}
				@include font00;

				@media screen and (min-width: $mq-2) {
					top: 71px;
				}
				@media screen and (min-width: $mq-3) {
					font-size: 14px;
					top: 71px;
				}
				@media screen and (min-width: $mq-4) {
					top: 71px;
				}
				@media screen and (min-width: $mq-5) {
					top: 71px;
				}
			}
		}
		li.mb50 {
			margin-bottom: 58px;
		}
	}
}

.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
