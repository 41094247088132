.ant-row {
	display: flex;
	flex-flow: row wrap;
	min-width: 0;
}

.ant-col {
	position: relative;
	max-width: 100%;
	min-height: 1px;
}

.ant-col-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}

.ant-col-push-24 {
	left: 100%;
}

.ant-col-pull-24 {
	right: 100%;
}

.ant-col-offset-24 {
	margin-left: 100%;
}

.ant-col-order-24 {
	order: 24;
}

.ant-col-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}

.ant-col-push-23 {
	left: 95.83333333%;
}

.ant-col-pull-23 {
	right: 95.83333333%;
}

.ant-col-offset-23 {
	margin-left: 95.83333333%;
}

.ant-col-order-23 {
	order: 23;
}

.ant-col-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}

.ant-col-push-22 {
	left: 91.66666667%;
}

.ant-col-pull-22 {
	right: 91.66666667%;
}

.ant-col-offset-22 {
	margin-left: 91.66666667%;
}

.ant-col-order-22 {
	order: 22;
}

.ant-col-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}

.ant-col-push-21 {
	left: 87.5%;
}

.ant-col-pull-21 {
	right: 87.5%;
}

.ant-col-offset-21 {
	margin-left: 87.5%;
}

.ant-col-order-21 {
	order: 21;
}

.ant-col-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}

.ant-col-push-20 {
	left: 83.33333333%;
}

.ant-col-pull-20 {
	right: 83.33333333%;
}

.ant-col-offset-20 {
	margin-left: 83.33333333%;
}

.ant-col-order-20 {
	order: 20;
}

.ant-col-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}

.ant-col-push-19 {
	left: 79.16666667%;
}

.ant-col-pull-19 {
	right: 79.16666667%;
}

.ant-col-offset-19 {
	margin-left: 79.16666667%;
}

.ant-col-order-19 {
	order: 19;
}

.ant-col-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}

.ant-col-push-18 {
	left: 75%;
}

.ant-col-pull-18 {
	right: 75%;
}

.ant-col-offset-18 {
	margin-left: 75%;
}

.ant-col-order-18 {
	order: 18;
}

.ant-col-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}

.ant-col-push-17 {
	left: 70.83333333%;
}

.ant-col-pull-17 {
	right: 70.83333333%;
}

.ant-col-offset-17 {
	margin-left: 70.83333333%;
}

.ant-col-order-17 {
	order: 17;
}

.ant-col-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}

.ant-col-push-16 {
	left: 66.66666667%;
}

.ant-col-pull-16 {
	right: 66.66666667%;
}

.ant-col-offset-16 {
	margin-left: 66.66666667%;
}

.ant-col-order-16 {
	order: 16;
}

.ant-col-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}

.ant-col-push-15 {
	left: 62.5%;
}

.ant-col-pull-15 {
	right: 62.5%;
}

.ant-col-offset-15 {
	margin-left: 62.5%;
}

.ant-col-order-15 {
	order: 15;
}

.ant-col-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}

.ant-col-push-14 {
	left: 58.33333333%;
}

.ant-col-pull-14 {
	right: 58.33333333%;
}

.ant-col-offset-14 {
	margin-left: 58.33333333%;
}

.ant-col-order-14 {
	order: 14;
}

.ant-col-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}

.ant-col-push-13 {
	left: 54.16666667%;
}

.ant-col-pull-13 {
	right: 54.16666667%;
}

.ant-col-offset-13 {
	margin-left: 54.16666667%;
}

.ant-col-order-13 {
	order: 13;
}

.ant-col-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}

.ant-col-push-12 {
	left: 50%;
}

.ant-col-pull-12 {
	right: 50%;
}

.ant-col-offset-12 {
	margin-left: 50%;
}

.ant-col-order-12 {
	order: 12;
}

.ant-col-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}

.ant-col-push-11 {
	left: 45.83333333%;
}

.ant-col-pull-11 {
	right: 45.83333333%;
}

.ant-col-offset-11 {
	margin-left: 45.83333333%;
}

.ant-col-order-11 {
	order: 11;
}

.ant-col-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}

.ant-col-push-10 {
	left: 41.66666667%;
}

.ant-col-pull-10 {
	right: 41.66666667%;
}

.ant-col-offset-10 {
	margin-left: 41.66666667%;
}

.ant-col-order-10 {
	order: 10;
}

.ant-col-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}

.ant-col-push-9 {
	left: 37.5%;
}

.ant-col-pull-9 {
	right: 37.5%;
}

.ant-col-offset-9 {
	margin-left: 37.5%;
}

.ant-col-order-9 {
	order: 9;
}

.ant-col-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}

.ant-col-push-8 {
	left: 33.33333333%;
}

.ant-col-pull-8 {
	right: 33.33333333%;
}

.ant-col-offset-8 {
	margin-left: 33.33333333%;
}

.ant-col-order-8 {
	order: 8;
}

.ant-col-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}

.ant-col-push-7 {
	left: 29.16666667%;
}

.ant-col-pull-7 {
	right: 29.16666667%;
}

.ant-col-offset-7 {
	margin-left: 29.16666667%;
}

.ant-col-order-7 {
	order: 7;
}

.ant-col-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}

.ant-col-push-6 {
	left: 25%;
}

.ant-col-pull-6 {
	right: 25%;
}

.ant-col-offset-6 {
	margin-left: 25%;
}

.ant-col-order-6 {
	order: 6;
}

.ant-col-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}

.ant-col-push-5 {
	left: 20.83333333%;
}

.ant-col-pull-5 {
	right: 20.83333333%;
}

.ant-col-offset-5 {
	margin-left: 20.83333333%;
}

.ant-col-order-5 {
	order: 5;
}

.ant-col-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}

.ant-col-push-4 {
	left: 16.66666667%;
}

.ant-col-pull-4 {
	right: 16.66666667%;
}

.ant-col-offset-4 {
	margin-left: 16.66666667%;
}

.ant-col-order-4 {
	order: 4;
}

.ant-col-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}

.ant-col-push-3 {
	left: 12.5%;
}

.ant-col-pull-3 {
	right: 12.5%;
}

.ant-col-offset-3 {
	margin-left: 12.5%;
}

.ant-col-order-3 {
	order: 3;
}

.ant-col-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}

.ant-col-push-2 {
	left: 8.33333333%;
}

.ant-col-pull-2 {
	right: 8.33333333%;
}

.ant-col-offset-2 {
	margin-left: 8.33333333%;
}

.ant-col-order-2 {
	order: 2;
}

.ant-col-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}

.ant-col-push-1 {
	left: 4.16666667%;
}

.ant-col-pull-1 {
	right: 4.16666667%;
}

.ant-col-offset-1 {
	margin-left: 4.16666667%;
}

.ant-col-order-1 {
	order: 1;
}

.ant-col-0 {
	display: none;
}

.ant-col-offset-0 {
	margin-left: 0;
}

.ant-col-order-0 {
	order: 0;
}

.ant-col-offset-0.ant-col-rtl {
	margin-right: 0;
}

.ant-col-push-1.ant-col-rtl {
	right: 4.16666667%;
	left: auto;
}

.ant-col-pull-1.ant-col-rtl {
	right: auto;
	left: 4.16666667%;
}

.ant-col-offset-1.ant-col-rtl {
	margin-right: 4.16666667%;
	margin-left: 0;
}

.ant-col-push-2.ant-col-rtl {
	right: 8.33333333%;
	left: auto;
}

.ant-col-pull-2.ant-col-rtl {
	right: auto;
	left: 8.33333333%;
}

.ant-col-offset-2.ant-col-rtl {
	margin-right: 8.33333333%;
	margin-left: 0;
}

.ant-col-push-3.ant-col-rtl {
	right: 12.5%;
	left: auto;
}

.ant-col-pull-3.ant-col-rtl {
	right: auto;
	left: 12.5%;
}

.ant-col-offset-3.ant-col-rtl {
	margin-right: 12.5%;
	margin-left: 0;
}

.ant-col-push-4.ant-col-rtl {
	right: 16.66666667%;
	left: auto;
}

.ant-col-pull-4.ant-col-rtl {
	right: auto;
	left: 16.66666667%;
}

.ant-col-offset-4.ant-col-rtl {
	margin-right: 16.66666667%;
	margin-left: 0;
}

.ant-col-push-5.ant-col-rtl {
	right: 20.83333333%;
	left: auto;
}

.ant-col-pull-5.ant-col-rtl {
	right: auto;
	left: 20.83333333%;
}

.ant-col-offset-5.ant-col-rtl {
	margin-right: 20.83333333%;
	margin-left: 0;
}

.ant-col-push-6.ant-col-rtl {
	right: 25%;
	left: auto;
}

.ant-col-pull-6.ant-col-rtl {
	right: auto;
	left: 25%;
}

.ant-col-offset-6.ant-col-rtl {
	margin-right: 25%;
	margin-left: 0;
}

.ant-col-push-7.ant-col-rtl {
	right: 29.16666667%;
	left: auto;
}

.ant-col-pull-7.ant-col-rtl {
	right: auto;
	left: 29.16666667%;
}

.ant-col-offset-7.ant-col-rtl {
	margin-right: 29.16666667%;
	margin-left: 0;
}

.ant-col-push-8.ant-col-rtl {
	right: 33.33333333%;
	left: auto;
}

.ant-col-pull-8.ant-col-rtl {
	right: auto;
	left: 33.33333333%;
}

.ant-col-offset-8.ant-col-rtl {
	margin-right: 33.33333333%;
	margin-left: 0;
}

.ant-col-push-9.ant-col-rtl {
	right: 37.5%;
	left: auto;
}

.ant-col-pull-9.ant-col-rtl {
	right: auto;
	left: 37.5%;
}

.ant-col-offset-9.ant-col-rtl {
	margin-right: 37.5%;
	margin-left: 0;
}

.ant-col-push-10.ant-col-rtl {
	right: 41.66666667%;
	left: auto;
}

.ant-col-pull-10.ant-col-rtl {
	right: auto;
	left: 41.66666667%;
}

.ant-col-offset-10.ant-col-rtl {
	margin-right: 41.66666667%;
	margin-left: 0;
}

.ant-col-push-11.ant-col-rtl {
	right: 45.83333333%;
	left: auto;
}

.ant-col-pull-11.ant-col-rtl {
	right: auto;
	left: 45.83333333%;
}

.ant-col-offset-11.ant-col-rtl {
	margin-right: 45.83333333%;
	margin-left: 0;
}

.ant-col-push-12.ant-col-rtl {
	right: 50%;
	left: auto;
}

.ant-col-pull-12.ant-col-rtl {
	right: auto;
	left: 50%;
}

.ant-col-offset-12.ant-col-rtl {
	margin-right: 50%;
	margin-left: 0;
}

.ant-col-push-13.ant-col-rtl {
	right: 54.16666667%;
	left: auto;
}

.ant-col-pull-13.ant-col-rtl {
	right: auto;
	left: 54.16666667%;
}

.ant-col-offset-13.ant-col-rtl {
	margin-right: 54.16666667%;
	margin-left: 0;
}

.ant-col-push-14.ant-col-rtl {
	right: 58.33333333%;
	left: auto;
}

.ant-col-pull-14.ant-col-rtl {
	right: auto;
	left: 58.33333333%;
}

.ant-col-offset-14.ant-col-rtl {
	margin-right: 58.33333333%;
	margin-left: 0;
}

.ant-col-push-15.ant-col-rtl {
	right: 62.5%;
	left: auto;
}

.ant-col-pull-15.ant-col-rtl {
	right: auto;
	left: 62.5%;
}

.ant-col-offset-15.ant-col-rtl {
	margin-right: 62.5%;
	margin-left: 0;
}

.ant-col-push-16.ant-col-rtl {
	right: 66.66666667%;
	left: auto;
}

.ant-col-pull-16.ant-col-rtl {
	right: auto;
	left: 66.66666667%;
}

.ant-col-offset-16.ant-col-rtl {
	margin-right: 66.66666667%;
	margin-left: 0;
}

.ant-col-push-17.ant-col-rtl {
	right: 70.83333333%;
	left: auto;
}

.ant-col-pull-17.ant-col-rtl {
	right: auto;
	left: 70.83333333%;
}

.ant-col-offset-17.ant-col-rtl {
	margin-right: 70.83333333%;
	margin-left: 0;
}

.ant-col-push-18.ant-col-rtl {
	right: 75%;
	left: auto;
}

.ant-col-pull-18.ant-col-rtl {
	right: auto;
	left: 75%;
}

.ant-col-offset-18.ant-col-rtl {
	margin-right: 75%;
	margin-left: 0;
}

.ant-col-push-19.ant-col-rtl {
	right: 79.16666667%;
	left: auto;
}

.ant-col-pull-19.ant-col-rtl {
	right: auto;
	left: 79.16666667%;
}

.ant-col-offset-19.ant-col-rtl {
	margin-right: 79.16666667%;
	margin-left: 0;
}

.ant-col-push-20.ant-col-rtl {
	right: 83.33333333%;
	left: auto;
}

.ant-col-pull-20.ant-col-rtl {
	right: auto;
	left: 83.33333333%;
}

.ant-col-offset-20.ant-col-rtl {
	margin-right: 83.33333333%;
	margin-left: 0;
}

.ant-col-push-21.ant-col-rtl {
	right: 87.5%;
	left: auto;
}

.ant-col-pull-21.ant-col-rtl {
	right: auto;
	left: 87.5%;
}

.ant-col-offset-21.ant-col-rtl {
	margin-right: 87.5%;
	margin-left: 0;
}

.ant-col-push-22.ant-col-rtl {
	right: 91.66666667%;
	left: auto;
}

.ant-col-pull-22.ant-col-rtl {
	right: auto;
	left: 91.66666667%;
}

.ant-col-offset-22.ant-col-rtl {
	margin-right: 91.66666667%;
	margin-left: 0;
}

.ant-col-push-23.ant-col-rtl {
	right: 95.83333333%;
	left: auto;
}

.ant-col-pull-23.ant-col-rtl {
	right: auto;
	left: 95.83333333%;
}

.ant-col-offset-23.ant-col-rtl {
	margin-right: 95.83333333%;
	margin-left: 0;
}

.ant-col-push-24.ant-col-rtl {
	right: 100%;
	left: auto;
}

.ant-col-pull-24.ant-col-rtl {
	right: auto;
	left: 100%;
}

.ant-col-offset-24.ant-col-rtl {
	margin-right: 100%;
	margin-left: 0;
}

.ant-col-xs-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}

.ant-col-xs-push-24 {
	left: 100%;
}

.ant-col-xs-pull-24 {
	right: 100%;
}

.ant-col-xs-offset-24 {
	margin-left: 100%;
}

.ant-col-xs-order-24 {
	order: 24;
}

.ant-col-xs-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}

.ant-col-xs-push-23 {
	left: 95.83333333%;
}

.ant-col-xs-pull-23 {
	right: 95.83333333%;
}

.ant-col-xs-offset-23 {
	margin-left: 95.83333333%;
}

.ant-col-xs-order-23 {
	order: 23;
}

.ant-col-xs-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}

.ant-col-xs-push-22 {
	left: 91.66666667%;
}

.ant-col-xs-pull-22 {
	right: 91.66666667%;
}

.ant-col-xs-offset-22 {
	margin-left: 91.66666667%;
}

.ant-col-xs-order-22 {
	order: 22;
}

.ant-col-xs-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}

.ant-col-xs-push-21 {
	left: 87.5%;
}

.ant-col-xs-pull-21 {
	right: 87.5%;
}

.ant-col-xs-offset-21 {
	margin-left: 87.5%;
}

.ant-col-xs-order-21 {
	order: 21;
}

.ant-col-xs-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}

.ant-col-xs-push-20 {
	left: 83.33333333%;
}

.ant-col-xs-pull-20 {
	right: 83.33333333%;
}

.ant-col-xs-offset-20 {
	margin-left: 83.33333333%;
}

.ant-col-xs-order-20 {
	order: 20;
}

.ant-col-xs-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}

.ant-col-xs-push-19 {
	left: 79.16666667%;
}

.ant-col-xs-pull-19 {
	right: 79.16666667%;
}

.ant-col-xs-offset-19 {
	margin-left: 79.16666667%;
}

.ant-col-xs-order-19 {
	order: 19;
}

.ant-col-xs-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}

.ant-col-xs-push-18 {
	left: 75%;
}

.ant-col-xs-pull-18 {
	right: 75%;
}

.ant-col-xs-offset-18 {
	margin-left: 75%;
}

.ant-col-xs-order-18 {
	order: 18;
}

.ant-col-xs-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}

.ant-col-xs-push-17 {
	left: 70.83333333%;
}

.ant-col-xs-pull-17 {
	right: 70.83333333%;
}

.ant-col-xs-offset-17 {
	margin-left: 70.83333333%;
}

.ant-col-xs-order-17 {
	order: 17;
}

.ant-col-xs-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}

.ant-col-xs-push-16 {
	left: 66.66666667%;
}

.ant-col-xs-pull-16 {
	right: 66.66666667%;
}

.ant-col-xs-offset-16 {
	margin-left: 66.66666667%;
}

.ant-col-xs-order-16 {
	order: 16;
}

.ant-col-xs-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}

.ant-col-xs-push-15 {
	left: 62.5%;
}

.ant-col-xs-pull-15 {
	right: 62.5%;
}

.ant-col-xs-offset-15 {
	margin-left: 62.5%;
}

.ant-col-xs-order-15 {
	order: 15;
}

.ant-col-xs-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}

.ant-col-xs-push-14 {
	left: 58.33333333%;
}

.ant-col-xs-pull-14 {
	right: 58.33333333%;
}

.ant-col-xs-offset-14 {
	margin-left: 58.33333333%;
}

.ant-col-xs-order-14 {
	order: 14;
}

.ant-col-xs-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}

.ant-col-xs-push-13 {
	left: 54.16666667%;
}

.ant-col-xs-pull-13 {
	right: 54.16666667%;
}

.ant-col-xs-offset-13 {
	margin-left: 54.16666667%;
}

.ant-col-xs-order-13 {
	order: 13;
}

.ant-col-xs-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}

.ant-col-xs-push-12 {
	left: 50%;
}

.ant-col-xs-pull-12 {
	right: 50%;
}

.ant-col-xs-offset-12 {
	margin-left: 50%;
}

.ant-col-xs-order-12 {
	order: 12;
}

.ant-col-xs-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}

.ant-col-xs-push-11 {
	left: 45.83333333%;
}

.ant-col-xs-pull-11 {
	right: 45.83333333%;
}

.ant-col-xs-offset-11 {
	margin-left: 45.83333333%;
}

.ant-col-xs-order-11 {
	order: 11;
}

.ant-col-xs-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}

.ant-col-xs-push-10 {
	left: 41.66666667%;
}

.ant-col-xs-pull-10 {
	right: 41.66666667%;
}

.ant-col-xs-offset-10 {
	margin-left: 41.66666667%;
}

.ant-col-xs-order-10 {
	order: 10;
}

.ant-col-xs-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}

.ant-col-xs-push-9 {
	left: 37.5%;
}

.ant-col-xs-pull-9 {
	right: 37.5%;
}

.ant-col-xs-offset-9 {
	margin-left: 37.5%;
}

.ant-col-xs-order-9 {
	order: 9;
}

.ant-col-xs-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}

.ant-col-xs-push-8 {
	left: 33.33333333%;
}

.ant-col-xs-pull-8 {
	right: 33.33333333%;
}

.ant-col-xs-offset-8 {
	margin-left: 33.33333333%;
}

.ant-col-xs-order-8 {
	order: 8;
}

.ant-col-xs-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}

.ant-col-xs-push-7 {
	left: 29.16666667%;
}

.ant-col-xs-pull-7 {
	right: 29.16666667%;
}

.ant-col-xs-offset-7 {
	margin-left: 29.16666667%;
}

.ant-col-xs-order-7 {
	order: 7;
}

.ant-col-xs-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}

.ant-col-xs-push-6 {
	left: 25%;
}

.ant-col-xs-pull-6 {
	right: 25%;
}

.ant-col-xs-offset-6 {
	margin-left: 25%;
}

.ant-col-xs-order-6 {
	order: 6;
}

.ant-col-xs-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}

.ant-col-xs-push-5 {
	left: 20.83333333%;
}

.ant-col-xs-pull-5 {
	right: 20.83333333%;
}

.ant-col-xs-offset-5 {
	margin-left: 20.83333333%;
}

.ant-col-xs-order-5 {
	order: 5;
}

.ant-col-xs-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}

.ant-col-xs-push-4 {
	left: 16.66666667%;
}

.ant-col-xs-pull-4 {
	right: 16.66666667%;
}

.ant-col-xs-offset-4 {
	margin-left: 16.66666667%;
}

.ant-col-xs-order-4 {
	order: 4;
}

.ant-col-xs-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}

.ant-col-xs-push-3 {
	left: 12.5%;
}

.ant-col-xs-pull-3 {
	right: 12.5%;
}

.ant-col-xs-offset-3 {
	margin-left: 12.5%;
}

.ant-col-xs-order-3 {
	order: 3;
}

.ant-col-xs-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}

.ant-col-xs-push-2 {
	left: 8.33333333%;
}

.ant-col-xs-pull-2 {
	right: 8.33333333%;
}

.ant-col-xs-offset-2 {
	margin-left: 8.33333333%;
}

.ant-col-xs-order-2 {
	order: 2;
}

.ant-col-xs-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}

.ant-col-xs-push-1 {
	left: 4.16666667%;
}

.ant-col-xs-pull-1 {
	right: 4.16666667%;
}

.ant-col-xs-offset-1 {
	margin-left: 4.16666667%;
}

.ant-col-xs-order-1 {
	order: 1;
}

.ant-col-xs-0 {
	display: none;
}

.ant-col-push-0 {
	left: auto;
}

.ant-col-pull-0 {
	right: auto;
}

.ant-col-xs-push-0 {
	left: auto;
}

.ant-col-xs-pull-0 {
	right: auto;
}

.ant-col-xs-offset-0 {
	margin-left: 0;
}

.ant-col-xs-order-0 {
	order: 0;
}

.ant-col-push-0.ant-col-rtl {
	right: auto;
}

.ant-col-pull-0.ant-col-rtl {
	left: auto;
}

.ant-col-xs-push-0.ant-col-rtl {
	right: auto;
}

.ant-col-xs-pull-0.ant-col-rtl {
	left: auto;
}

.ant-col-xs-offset-0.ant-col-rtl {
	margin-right: 0;
}

.ant-col-xs-push-1.ant-col-rtl {
	right: 4.16666667%;
	left: auto;
}

.ant-col-xs-pull-1.ant-col-rtl {
	right: auto;
	left: 4.16666667%;
}

.ant-col-xs-offset-1.ant-col-rtl {
	margin-right: 4.16666667%;
	margin-left: 0;
}

.ant-col-xs-push-2.ant-col-rtl {
	right: 8.33333333%;
	left: auto;
}

.ant-col-xs-pull-2.ant-col-rtl {
	right: auto;
	left: 8.33333333%;
}

.ant-col-xs-offset-2.ant-col-rtl {
	margin-right: 8.33333333%;
	margin-left: 0;
}

.ant-col-xs-push-3.ant-col-rtl {
	right: 12.5%;
	left: auto;
}

.ant-col-xs-pull-3.ant-col-rtl {
	right: auto;
	left: 12.5%;
}

.ant-col-xs-offset-3.ant-col-rtl {
	margin-right: 12.5%;
	margin-left: 0;
}

.ant-col-xs-push-4.ant-col-rtl {
	right: 16.66666667%;
	left: auto;
}

.ant-col-xs-pull-4.ant-col-rtl {
	right: auto;
	left: 16.66666667%;
}

.ant-col-xs-offset-4.ant-col-rtl {
	margin-right: 16.66666667%;
	margin-left: 0;
}

.ant-col-xs-push-5.ant-col-rtl {
	right: 20.83333333%;
	left: auto;
}

.ant-col-xs-pull-5.ant-col-rtl {
	right: auto;
	left: 20.83333333%;
}

.ant-col-xs-offset-5.ant-col-rtl {
	margin-right: 20.83333333%;
	margin-left: 0;
}

.ant-col-xs-push-6.ant-col-rtl {
	right: 25%;
	left: auto;
}

.ant-col-xs-pull-6.ant-col-rtl {
	right: auto;
	left: 25%;
}

.ant-col-xs-offset-6.ant-col-rtl {
	margin-right: 25%;
	margin-left: 0;
}

.ant-col-xs-push-7.ant-col-rtl {
	right: 29.16666667%;
	left: auto;
}

.ant-col-xs-pull-7.ant-col-rtl {
	right: auto;
	left: 29.16666667%;
}

.ant-col-xs-offset-7.ant-col-rtl {
	margin-right: 29.16666667%;
	margin-left: 0;
}

.ant-col-xs-push-8.ant-col-rtl {
	right: 33.33333333%;
	left: auto;
}

.ant-col-xs-pull-8.ant-col-rtl {
	right: auto;
	left: 33.33333333%;
}

.ant-col-xs-offset-8.ant-col-rtl {
	margin-right: 33.33333333%;
	margin-left: 0;
}

.ant-col-xs-push-9.ant-col-rtl {
	right: 37.5%;
	left: auto;
}

.ant-col-xs-pull-9.ant-col-rtl {
	right: auto;
	left: 37.5%;
}

.ant-col-xs-offset-9.ant-col-rtl {
	margin-right: 37.5%;
	margin-left: 0;
}

.ant-col-xs-push-10.ant-col-rtl {
	right: 41.66666667%;
	left: auto;
}

.ant-col-xs-pull-10.ant-col-rtl {
	right: auto;
	left: 41.66666667%;
}

.ant-col-xs-offset-10.ant-col-rtl {
	margin-right: 41.66666667%;
	margin-left: 0;
}

.ant-col-xs-push-11.ant-col-rtl {
	right: 45.83333333%;
	left: auto;
}

.ant-col-xs-pull-11.ant-col-rtl {
	right: auto;
	left: 45.83333333%;
}

.ant-col-xs-offset-11.ant-col-rtl {
	margin-right: 45.83333333%;
	margin-left: 0;
}

.ant-col-xs-push-12.ant-col-rtl {
	right: 50%;
	left: auto;
}

.ant-col-xs-pull-12.ant-col-rtl {
	right: auto;
	left: 50%;
}

.ant-col-xs-offset-12.ant-col-rtl {
	margin-right: 50%;
	margin-left: 0;
}

.ant-col-xs-push-13.ant-col-rtl {
	right: 54.16666667%;
	left: auto;
}

.ant-col-xs-pull-13.ant-col-rtl {
	right: auto;
	left: 54.16666667%;
}

.ant-col-xs-offset-13.ant-col-rtl {
	margin-right: 54.16666667%;
	margin-left: 0;
}

.ant-col-xs-push-14.ant-col-rtl {
	right: 58.33333333%;
	left: auto;
}

.ant-col-xs-pull-14.ant-col-rtl {
	right: auto;
	left: 58.33333333%;
}

.ant-col-xs-offset-14.ant-col-rtl {
	margin-right: 58.33333333%;
	margin-left: 0;
}

.ant-col-xs-push-15.ant-col-rtl {
	right: 62.5%;
	left: auto;
}

.ant-col-xs-pull-15.ant-col-rtl {
	right: auto;
	left: 62.5%;
}

.ant-col-xs-offset-15.ant-col-rtl {
	margin-right: 62.5%;
	margin-left: 0;
}

.ant-col-xs-push-16.ant-col-rtl {
	right: 66.66666667%;
	left: auto;
}

.ant-col-xs-pull-16.ant-col-rtl {
	right: auto;
	left: 66.66666667%;
}

.ant-col-xs-offset-16.ant-col-rtl {
	margin-right: 66.66666667%;
	margin-left: 0;
}

.ant-col-xs-push-17.ant-col-rtl {
	right: 70.83333333%;
	left: auto;
}

.ant-col-xs-pull-17.ant-col-rtl {
	right: auto;
	left: 70.83333333%;
}

.ant-col-xs-offset-17.ant-col-rtl {
	margin-right: 70.83333333%;
	margin-left: 0;
}

.ant-col-xs-push-18.ant-col-rtl {
	right: 75%;
	left: auto;
}

.ant-col-xs-pull-18.ant-col-rtl {
	right: auto;
	left: 75%;
}

.ant-col-xs-offset-18.ant-col-rtl {
	margin-right: 75%;
	margin-left: 0;
}

.ant-col-xs-push-19.ant-col-rtl {
	right: 79.16666667%;
	left: auto;
}

.ant-col-xs-pull-19.ant-col-rtl {
	right: auto;
	left: 79.16666667%;
}

.ant-col-xs-offset-19.ant-col-rtl {
	margin-right: 79.16666667%;
	margin-left: 0;
}

.ant-col-xs-push-20.ant-col-rtl {
	right: 83.33333333%;
	left: auto;
}

.ant-col-xs-pull-20.ant-col-rtl {
	right: auto;
	left: 83.33333333%;
}

.ant-col-xs-offset-20.ant-col-rtl {
	margin-right: 83.33333333%;
	margin-left: 0;
}

.ant-col-xs-push-21.ant-col-rtl {
	right: 87.5%;
	left: auto;
}

.ant-col-xs-pull-21.ant-col-rtl {
	right: auto;
	left: 87.5%;
}

.ant-col-xs-offset-21.ant-col-rtl {
	margin-right: 87.5%;
	margin-left: 0;
}

.ant-col-xs-push-22.ant-col-rtl {
	right: 91.66666667%;
	left: auto;
}

.ant-col-xs-pull-22.ant-col-rtl {
	right: auto;
	left: 91.66666667%;
}

.ant-col-xs-offset-22.ant-col-rtl {
	margin-right: 91.66666667%;
	margin-left: 0;
}

.ant-col-xs-push-23.ant-col-rtl {
	right: 95.83333333%;
	left: auto;
}

.ant-col-xs-pull-23.ant-col-rtl {
	right: auto;
	left: 95.83333333%;
}

.ant-col-xs-offset-23.ant-col-rtl {
	margin-right: 95.83333333%;
	margin-left: 0;
}

.ant-col-xs-push-24.ant-col-rtl {
	right: 100%;
	left: auto;
}

.ant-col-xs-pull-24.ant-col-rtl {
	right: auto;
	left: 100%;
}

.ant-col-xs-offset-24.ant-col-rtl {
	margin-right: 100%;
	margin-left: 0;
}

@media (min-width: 576px) {
	.ant-col-sm-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-sm-push-24 {
		left: 100%;
	}

	.ant-col-sm-pull-24 {
		right: 100%;
	}

	.ant-col-sm-offset-24 {
		margin-left: 100%;
	}

	.ant-col-sm-order-24 {
		order: 24;
	}

	.ant-col-sm-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-sm-push-23 {
		left: 95.83333333%;
	}

	.ant-col-sm-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-sm-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-sm-order-23 {
		order: 23;
	}

	.ant-col-sm-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-sm-push-22 {
		left: 91.66666667%;
	}

	.ant-col-sm-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-sm-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-sm-order-22 {
		order: 22;
	}

	.ant-col-sm-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-sm-push-21 {
		left: 87.5%;
	}

	.ant-col-sm-pull-21 {
		right: 87.5%;
	}

	.ant-col-sm-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-sm-order-21 {
		order: 21;
	}

	.ant-col-sm-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-sm-push-20 {
		left: 83.33333333%;
	}

	.ant-col-sm-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-sm-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-sm-order-20 {
		order: 20;
	}

	.ant-col-sm-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-sm-push-19 {
		left: 79.16666667%;
	}

	.ant-col-sm-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-sm-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-sm-order-19 {
		order: 19;
	}

	.ant-col-sm-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-sm-push-18 {
		left: 75%;
	}

	.ant-col-sm-pull-18 {
		right: 75%;
	}

	.ant-col-sm-offset-18 {
		margin-left: 75%;
	}

	.ant-col-sm-order-18 {
		order: 18;
	}

	.ant-col-sm-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-sm-push-17 {
		left: 70.83333333%;
	}

	.ant-col-sm-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-sm-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-sm-order-17 {
		order: 17;
	}

	.ant-col-sm-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-sm-push-16 {
		left: 66.66666667%;
	}

	.ant-col-sm-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-sm-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-sm-order-16 {
		order: 16;
	}

	.ant-col-sm-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-sm-push-15 {
		left: 62.5%;
	}

	.ant-col-sm-pull-15 {
		right: 62.5%;
	}

	.ant-col-sm-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-sm-order-15 {
		order: 15;
	}

	.ant-col-sm-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-sm-push-14 {
		left: 58.33333333%;
	}

	.ant-col-sm-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-sm-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-sm-order-14 {
		order: 14;
	}

	.ant-col-sm-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-sm-push-13 {
		left: 54.16666667%;
	}

	.ant-col-sm-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-sm-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-sm-order-13 {
		order: 13;
	}

	.ant-col-sm-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-sm-push-12 {
		left: 50%;
	}

	.ant-col-sm-pull-12 {
		right: 50%;
	}

	.ant-col-sm-offset-12 {
		margin-left: 50%;
	}

	.ant-col-sm-order-12 {
		order: 12;
	}

	.ant-col-sm-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-sm-push-11 {
		left: 45.83333333%;
	}

	.ant-col-sm-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-sm-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-sm-order-11 {
		order: 11;
	}

	.ant-col-sm-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-sm-push-10 {
		left: 41.66666667%;
	}

	.ant-col-sm-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-sm-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-sm-order-10 {
		order: 10;
	}

	.ant-col-sm-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-sm-push-9 {
		left: 37.5%;
	}

	.ant-col-sm-pull-9 {
		right: 37.5%;
	}

	.ant-col-sm-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-sm-order-9 {
		order: 9;
	}

	.ant-col-sm-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-sm-push-8 {
		left: 33.33333333%;
	}

	.ant-col-sm-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-sm-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-sm-order-8 {
		order: 8;
	}

	.ant-col-sm-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-sm-push-7 {
		left: 29.16666667%;
	}

	.ant-col-sm-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-sm-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-sm-order-7 {
		order: 7;
	}

	.ant-col-sm-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-sm-push-6 {
		left: 25%;
	}

	.ant-col-sm-pull-6 {
		right: 25%;
	}

	.ant-col-sm-offset-6 {
		margin-left: 25%;
	}

	.ant-col-sm-order-6 {
		order: 6;
	}

	.ant-col-sm-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-sm-push-5 {
		left: 20.83333333%;
	}

	.ant-col-sm-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-sm-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-sm-order-5 {
		order: 5;
	}

	.ant-col-sm-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-sm-push-4 {
		left: 16.66666667%;
	}

	.ant-col-sm-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-sm-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-sm-order-4 {
		order: 4;
	}

	.ant-col-sm-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-sm-push-3 {
		left: 12.5%;
	}

	.ant-col-sm-pull-3 {
		right: 12.5%;
	}

	.ant-col-sm-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-sm-order-3 {
		order: 3;
	}

	.ant-col-sm-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-sm-push-2 {
		left: 8.33333333%;
	}

	.ant-col-sm-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-sm-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-sm-order-2 {
		order: 2;
	}

	.ant-col-sm-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
		background-color: red;
	}

	.ant-col-sm-push-1 {
		left: 4.16666667%;
	}

	.ant-col-sm-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-sm-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-sm-order-1 {
		order: 1;
	}

	.ant-col-sm-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-sm-push-0 {
		left: auto;
	}

	.ant-col-sm-pull-0 {
		right: auto;
	}

	.ant-col-sm-offset-0 {
		margin-left: 0;
	}

	.ant-col-sm-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-sm-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-sm-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-sm-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-sm-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.ant-col-sm-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.ant-col-sm-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.ant-col-sm-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.ant-col-sm-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.ant-col-sm-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.ant-col-sm-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.ant-col-sm-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.ant-col-sm-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.ant-col-sm-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.ant-col-sm-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.ant-col-sm-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}

	.ant-col-sm-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}

	.ant-col-sm-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.ant-col-sm-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.ant-col-sm-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.ant-col-sm-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.ant-col-sm-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.ant-col-sm-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.ant-col-sm-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.ant-col-sm-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.ant-col-sm-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.ant-col-sm-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.ant-col-sm-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.ant-col-sm-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.ant-col-sm-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}

	.ant-col-sm-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}

	.ant-col-sm-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.ant-col-sm-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.ant-col-sm-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.ant-col-sm-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.ant-col-sm-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.ant-col-sm-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.ant-col-sm-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.ant-col-sm-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.ant-col-sm-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.ant-col-sm-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.ant-col-sm-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.ant-col-sm-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.ant-col-sm-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}

	.ant-col-sm-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}

	.ant-col-sm-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.ant-col-sm-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.ant-col-sm-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.ant-col-sm-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.ant-col-sm-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.ant-col-sm-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.ant-col-sm-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.ant-col-sm-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.ant-col-sm-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.ant-col-sm-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.ant-col-sm-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.ant-col-sm-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.ant-col-sm-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.ant-col-sm-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.ant-col-sm-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}

	.ant-col-sm-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}

	.ant-col-sm-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 768px) {
	.ant-col-md-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-md-push-24 {
		left: 100%;
	}

	.ant-col-md-pull-24 {
		right: 100%;
	}

	.ant-col-md-offset-24 {
		margin-left: 100%;
	}

	.ant-col-md-order-24 {
		order: 24;
	}

	.ant-col-md-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-md-push-23 {
		left: 95.83333333%;
	}

	.ant-col-md-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-md-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-md-order-23 {
		order: 23;
	}

	.ant-col-md-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-md-push-22 {
		left: 91.66666667%;
	}

	.ant-col-md-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-md-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-md-order-22 {
		order: 22;
	}

	.ant-col-md-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-md-push-21 {
		left: 87.5%;
	}

	.ant-col-md-pull-21 {
		right: 87.5%;
	}

	.ant-col-md-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-md-order-21 {
		order: 21;
	}

	.ant-col-md-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-md-push-20 {
		left: 83.33333333%;
	}

	.ant-col-md-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-md-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-md-order-20 {
		order: 20;
	}

	.ant-col-md-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-md-push-19 {
		left: 79.16666667%;
	}

	.ant-col-md-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-md-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-md-order-19 {
		order: 19;
	}

	.ant-col-md-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-md-push-18 {
		left: 75%;
	}

	.ant-col-md-pull-18 {
		right: 75%;
	}

	.ant-col-md-offset-18 {
		margin-left: 75%;
	}

	.ant-col-md-order-18 {
		order: 18;
	}

	.ant-col-md-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-md-push-17 {
		left: 70.83333333%;
	}

	.ant-col-md-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-md-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-md-order-17 {
		order: 17;
	}

	.ant-col-md-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-md-push-16 {
		left: 66.66666667%;
	}

	.ant-col-md-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-md-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-md-order-16 {
		order: 16;
	}

	.ant-col-md-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-md-push-15 {
		left: 62.5%;
	}

	.ant-col-md-pull-15 {
		right: 62.5%;
	}

	.ant-col-md-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-md-order-15 {
		order: 15;
	}

	.ant-col-md-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-md-push-14 {
		left: 58.33333333%;
	}

	.ant-col-md-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-md-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-md-order-14 {
		order: 14;
	}

	.ant-col-md-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-md-push-13 {
		left: 54.16666667%;
	}

	.ant-col-md-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-md-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-md-order-13 {
		order: 13;
	}

	.ant-col-md-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-md-push-12 {
		left: 50%;
	}

	.ant-col-md-pull-12 {
		right: 50%;
	}

	.ant-col-md-offset-12 {
		margin-left: 50%;
	}

	.ant-col-md-order-12 {
		order: 12;
	}

	.ant-col-md-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-md-push-11 {
		left: 45.83333333%;
	}

	.ant-col-md-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-md-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-md-order-11 {
		order: 11;
	}

	.ant-col-md-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-md-push-10 {
		left: 41.66666667%;
	}

	.ant-col-md-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-md-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-md-order-10 {
		order: 10;
	}

	.ant-col-md-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-md-push-9 {
		left: 37.5%;
	}

	.ant-col-md-pull-9 {
		right: 37.5%;
	}

	.ant-col-md-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-md-order-9 {
		order: 9;
	}

	.ant-col-md-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-md-push-8 {
		left: 33.33333333%;
	}

	.ant-col-md-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-md-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-md-order-8 {
		order: 8;
	}

	.ant-col-md-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-md-push-7 {
		left: 29.16666667%;
	}

	.ant-col-md-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-md-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-md-order-7 {
		order: 7;
	}

	.ant-col-md-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-md-push-6 {
		left: 25%;
	}

	.ant-col-md-pull-6 {
		right: 25%;
	}

	.ant-col-md-offset-6 {
		margin-left: 25%;
	}

	.ant-col-md-order-6 {
		order: 6;
	}

	.ant-col-md-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-md-push-5 {
		left: 20.83333333%;
	}

	.ant-col-md-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-md-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-md-order-5 {
		order: 5;
	}

	.ant-col-md-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-md-push-4 {
		left: 16.66666667%;
	}

	.ant-col-md-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-md-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-md-order-4 {
		order: 4;
	}

	.ant-col-md-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-md-push-3 {
		left: 12.5%;
	}

	.ant-col-md-pull-3 {
		right: 12.5%;
	}

	.ant-col-md-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-md-order-3 {
		order: 3;
	}

	.ant-col-md-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-md-push-2 {
		left: 8.33333333%;
	}

	.ant-col-md-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-md-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-md-order-2 {
		order: 2;
	}

	.ant-col-md-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-md-push-1 {
		left: 4.16666667%;
	}

	.ant-col-md-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-md-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-md-order-1 {
		order: 1;
	}

	.ant-col-md-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-md-push-0 {
		left: auto;
	}

	.ant-col-md-pull-0 {
		right: auto;
	}

	.ant-col-md-offset-0 {
		margin-left: 0;
	}

	.ant-col-md-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-md-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-md-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-md-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-md-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.ant-col-md-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.ant-col-md-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.ant-col-md-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.ant-col-md-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.ant-col-md-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.ant-col-md-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.ant-col-md-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.ant-col-md-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.ant-col-md-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.ant-col-md-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.ant-col-md-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.ant-col-md-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.ant-col-md-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.ant-col-md-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.ant-col-md-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}

	.ant-col-md-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}

	.ant-col-md-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.ant-col-md-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.ant-col-md-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.ant-col-md-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.ant-col-md-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.ant-col-md-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.ant-col-md-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.ant-col-md-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.ant-col-md-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.ant-col-md-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.ant-col-md-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.ant-col-md-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.ant-col-md-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.ant-col-md-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.ant-col-md-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.ant-col-md-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.ant-col-md-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}

	.ant-col-md-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}

	.ant-col-md-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.ant-col-md-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.ant-col-md-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.ant-col-md-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.ant-col-md-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.ant-col-md-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.ant-col-md-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.ant-col-md-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.ant-col-md-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.ant-col-md-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.ant-col-md-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.ant-col-md-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.ant-col-md-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.ant-col-md-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.ant-col-md-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.ant-col-md-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.ant-col-md-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}

	.ant-col-md-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}

	.ant-col-md-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.ant-col-md-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.ant-col-md-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.ant-col-md-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.ant-col-md-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.ant-col-md-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.ant-col-md-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.ant-col-md-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.ant-col-md-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.ant-col-md-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.ant-col-md-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.ant-col-md-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.ant-col-md-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.ant-col-md-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.ant-col-md-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.ant-col-md-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.ant-col-md-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}

	.ant-col-md-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}

	.ant-col-md-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 992px) {
	.ant-col-lg-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-lg-push-24 {
		left: 100%;
	}

	.ant-col-lg-pull-24 {
		right: 100%;
	}

	.ant-col-lg-offset-24 {
		margin-left: 100%;
	}

	.ant-col-lg-order-24 {
		order: 24;
	}

	.ant-col-lg-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-lg-push-23 {
		left: 95.83333333%;
	}

	.ant-col-lg-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-lg-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-lg-order-23 {
		order: 23;
	}

	.ant-col-lg-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-lg-push-22 {
		left: 91.66666667%;
	}

	.ant-col-lg-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-lg-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-lg-order-22 {
		order: 22;
	}

	.ant-col-lg-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-lg-push-21 {
		left: 87.5%;
	}

	.ant-col-lg-pull-21 {
		right: 87.5%;
	}

	.ant-col-lg-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-lg-order-21 {
		order: 21;
	}

	.ant-col-lg-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-lg-push-20 {
		left: 83.33333333%;
	}

	.ant-col-lg-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-lg-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-lg-order-20 {
		order: 20;
	}

	.ant-col-lg-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-lg-push-19 {
		left: 79.16666667%;
	}

	.ant-col-lg-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-lg-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-lg-order-19 {
		order: 19;
	}

	.ant-col-lg-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-lg-push-18 {
		left: 75%;
	}

	.ant-col-lg-pull-18 {
		right: 75%;
	}

	.ant-col-lg-offset-18 {
		margin-left: 75%;
	}

	.ant-col-lg-order-18 {
		order: 18;
	}

	.ant-col-lg-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-lg-push-17 {
		left: 70.83333333%;
	}

	.ant-col-lg-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-lg-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-lg-order-17 {
		order: 17;
	}

	.ant-col-lg-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-lg-push-16 {
		left: 66.66666667%;
	}

	.ant-col-lg-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-lg-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-lg-order-16 {
		order: 16;
	}

	.ant-col-lg-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-lg-push-15 {
		left: 62.5%;
	}

	.ant-col-lg-pull-15 {
		right: 62.5%;
	}

	.ant-col-lg-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-lg-order-15 {
		order: 15;
	}

	.ant-col-lg-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-lg-push-14 {
		left: 58.33333333%;
	}

	.ant-col-lg-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-lg-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-lg-order-14 {
		order: 14;
	}

	.ant-col-lg-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-lg-push-13 {
		left: 54.16666667%;
	}

	.ant-col-lg-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-lg-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-lg-order-13 {
		order: 13;
	}

	.ant-col-lg-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-lg-push-12 {
		left: 50%;
	}

	.ant-col-lg-pull-12 {
		right: 50%;
	}

	.ant-col-lg-offset-12 {
		margin-left: 50%;
	}

	.ant-col-lg-order-12 {
		order: 12;
	}

	.ant-col-lg-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-lg-push-11 {
		left: 45.83333333%;
	}

	.ant-col-lg-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-lg-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-lg-order-11 {
		order: 11;
	}

	.ant-col-lg-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-lg-push-10 {
		left: 41.66666667%;
	}

	.ant-col-lg-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-lg-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-lg-order-10 {
		order: 10;
	}

	.ant-col-lg-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-lg-push-9 {
		left: 37.5%;
	}

	.ant-col-lg-pull-9 {
		right: 37.5%;
	}

	.ant-col-lg-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-lg-order-9 {
		order: 9;
	}

	.ant-col-lg-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-lg-push-8 {
		left: 33.33333333%;
	}

	.ant-col-lg-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-lg-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-lg-order-8 {
		order: 8;
	}

	.ant-col-lg-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-lg-push-7 {
		left: 29.16666667%;
	}

	.ant-col-lg-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-lg-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-lg-order-7 {
		order: 7;
	}

	.ant-col-lg-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-lg-push-6 {
		left: 25%;
	}

	.ant-col-lg-pull-6 {
		right: 25%;
	}

	.ant-col-lg-offset-6 {
		margin-left: 25%;
	}

	.ant-col-lg-order-6 {
		order: 6;
	}

	.ant-col-lg-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-lg-push-5 {
		left: 20.83333333%;
	}

	.ant-col-lg-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-lg-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-lg-order-5 {
		order: 5;
	}

	.ant-col-lg-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-lg-push-4 {
		left: 16.66666667%;
	}

	.ant-col-lg-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-lg-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-lg-order-4 {
		order: 4;
	}

	.ant-col-lg-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-lg-push-3 {
		left: 12.5%;
	}

	.ant-col-lg-pull-3 {
		right: 12.5%;
	}

	.ant-col-lg-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-lg-order-3 {
		order: 3;
	}

	.ant-col-lg-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-lg-push-2 {
		left: 8.33333333%;
	}

	.ant-col-lg-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-lg-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-lg-order-2 {
		order: 2;
	}

	.ant-col-lg-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-lg-push-1 {
		left: 4.16666667%;
	}

	.ant-col-lg-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-lg-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-lg-order-1 {
		order: 1;
	}

	.ant-col-lg-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-lg-push-0 {
		left: auto;
	}

	.ant-col-lg-pull-0 {
		right: auto;
	}

	.ant-col-lg-offset-0 {
		margin-left: 0;
	}

	.ant-col-lg-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-lg-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-lg-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-lg-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-lg-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.ant-col-lg-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.ant-col-lg-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.ant-col-lg-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.ant-col-lg-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.ant-col-lg-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.ant-col-lg-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.ant-col-lg-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.ant-col-lg-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.ant-col-lg-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.ant-col-lg-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.ant-col-lg-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}

	.ant-col-lg-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}

	.ant-col-lg-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.ant-col-lg-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.ant-col-lg-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.ant-col-lg-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.ant-col-lg-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.ant-col-lg-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.ant-col-lg-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.ant-col-lg-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.ant-col-lg-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.ant-col-lg-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.ant-col-lg-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.ant-col-lg-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.ant-col-lg-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}

	.ant-col-lg-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}

	.ant-col-lg-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.ant-col-lg-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.ant-col-lg-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.ant-col-lg-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.ant-col-lg-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.ant-col-lg-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.ant-col-lg-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.ant-col-lg-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.ant-col-lg-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.ant-col-lg-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.ant-col-lg-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.ant-col-lg-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.ant-col-lg-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}

	.ant-col-lg-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}

	.ant-col-lg-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.ant-col-lg-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.ant-col-lg-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.ant-col-lg-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.ant-col-lg-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.ant-col-lg-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.ant-col-lg-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.ant-col-lg-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.ant-col-lg-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.ant-col-lg-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.ant-col-lg-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.ant-col-lg-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.ant-col-lg-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.ant-col-lg-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.ant-col-lg-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}

	.ant-col-lg-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}

	.ant-col-lg-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 1200px) {
	.ant-col-xl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-xl-push-24 {
		left: 100%;
	}

	.ant-col-xl-pull-24 {
		right: 100%;
	}

	.ant-col-xl-offset-24 {
		margin-left: 100%;
	}

	.ant-col-xl-order-24 {
		order: 24;
	}

	.ant-col-xl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-xl-push-23 {
		left: 95.83333333%;
	}

	.ant-col-xl-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-xl-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-xl-order-23 {
		order: 23;
	}

	.ant-col-xl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-xl-push-22 {
		left: 91.66666667%;
	}

	.ant-col-xl-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-xl-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-xl-order-22 {
		order: 22;
	}

	.ant-col-xl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-xl-push-21 {
		left: 87.5%;
	}

	.ant-col-xl-pull-21 {
		right: 87.5%;
	}

	.ant-col-xl-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-xl-order-21 {
		order: 21;
	}

	.ant-col-xl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-xl-push-20 {
		left: 83.33333333%;
	}

	.ant-col-xl-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-xl-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-xl-order-20 {
		order: 20;
	}

	.ant-col-xl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-xl-push-19 {
		left: 79.16666667%;
	}

	.ant-col-xl-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-xl-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-xl-order-19 {
		order: 19;
	}

	.ant-col-xl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-xl-push-18 {
		left: 75%;
	}

	.ant-col-xl-pull-18 {
		right: 75%;
	}

	.ant-col-xl-offset-18 {
		margin-left: 75%;
	}

	.ant-col-xl-order-18 {
		order: 18;
	}

	.ant-col-xl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-xl-push-17 {
		left: 70.83333333%;
	}

	.ant-col-xl-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-xl-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-xl-order-17 {
		order: 17;
	}

	.ant-col-xl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-xl-push-16 {
		left: 66.66666667%;
	}

	.ant-col-xl-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-xl-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-xl-order-16 {
		order: 16;
	}

	.ant-col-xl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-xl-push-15 {
		left: 62.5%;
	}

	.ant-col-xl-pull-15 {
		right: 62.5%;
	}

	.ant-col-xl-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-xl-order-15 {
		order: 15;
	}

	.ant-col-xl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-xl-push-14 {
		left: 58.33333333%;
	}

	.ant-col-xl-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-xl-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-xl-order-14 {
		order: 14;
	}

	.ant-col-xl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-xl-push-13 {
		left: 54.16666667%;
	}

	.ant-col-xl-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-xl-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-xl-order-13 {
		order: 13;
	}

	.ant-col-xl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-xl-push-12 {
		left: 50%;
	}

	.ant-col-xl-pull-12 {
		right: 50%;
	}

	.ant-col-xl-offset-12 {
		margin-left: 50%;
	}

	.ant-col-xl-order-12 {
		order: 12;
	}

	.ant-col-xl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-xl-push-11 {
		left: 45.83333333%;
	}

	.ant-col-xl-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-xl-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-xl-order-11 {
		order: 11;
	}

	.ant-col-xl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-xl-push-10 {
		left: 41.66666667%;
	}

	.ant-col-xl-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-xl-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-xl-order-10 {
		order: 10;
	}

	.ant-col-xl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-xl-push-9 {
		left: 37.5%;
	}

	.ant-col-xl-pull-9 {
		right: 37.5%;
	}

	.ant-col-xl-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-xl-order-9 {
		order: 9;
	}

	.ant-col-xl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-xl-push-8 {
		left: 33.33333333%;
	}

	.ant-col-xl-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-xl-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-xl-order-8 {
		order: 8;
	}

	.ant-col-xl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-xl-push-7 {
		left: 29.16666667%;
	}

	.ant-col-xl-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-xl-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-xl-order-7 {
		order: 7;
	}

	.ant-col-xl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-xl-push-6 {
		left: 25%;
	}

	.ant-col-xl-pull-6 {
		right: 25%;
	}

	.ant-col-xl-offset-6 {
		margin-left: 25%;
	}

	.ant-col-xl-order-6 {
		order: 6;
	}

	.ant-col-xl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-xl-push-5 {
		left: 20.83333333%;
	}

	.ant-col-xl-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-xl-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-xl-order-5 {
		order: 5;
	}

	.ant-col-xl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-xl-push-4 {
		left: 16.66666667%;
	}

	.ant-col-xl-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-xl-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-xl-order-4 {
		order: 4;
	}

	.ant-col-xl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-xl-push-3 {
		left: 12.5%;
	}

	.ant-col-xl-pull-3 {
		right: 12.5%;
	}

	.ant-col-xl-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-xl-order-3 {
		order: 3;
	}

	.ant-col-xl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-xl-push-2 {
		left: 8.33333333%;
	}

	.ant-col-xl-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-xl-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-xl-order-2 {
		order: 2;
	}

	.ant-col-xl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-xl-push-1 {
		left: 4.16666667%;
	}

	.ant-col-xl-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-xl-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-xl-order-1 {
		order: 1;
	}

	.ant-col-xl-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-xl-push-0 {
		left: auto;
	}

	.ant-col-xl-pull-0 {
		right: auto;
	}

	.ant-col-xl-offset-0 {
		margin-left: 0;
	}

	.ant-col-xl-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xl-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-xl-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xl-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-xl-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.ant-col-xl-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.ant-col-xl-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.ant-col-xl-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.ant-col-xl-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.ant-col-xl-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.ant-col-xl-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.ant-col-xl-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.ant-col-xl-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.ant-col-xl-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.ant-col-xl-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.ant-col-xl-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}

	.ant-col-xl-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}

	.ant-col-xl-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.ant-col-xl-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.ant-col-xl-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.ant-col-xl-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.ant-col-xl-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.ant-col-xl-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.ant-col-xl-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.ant-col-xl-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.ant-col-xl-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.ant-col-xl-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.ant-col-xl-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.ant-col-xl-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.ant-col-xl-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}

	.ant-col-xl-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}

	.ant-col-xl-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.ant-col-xl-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.ant-col-xl-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.ant-col-xl-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.ant-col-xl-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.ant-col-xl-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.ant-col-xl-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.ant-col-xl-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.ant-col-xl-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.ant-col-xl-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.ant-col-xl-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.ant-col-xl-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.ant-col-xl-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}

	.ant-col-xl-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}

	.ant-col-xl-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.ant-col-xl-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.ant-col-xl-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.ant-col-xl-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.ant-col-xl-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.ant-col-xl-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.ant-col-xl-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.ant-col-xl-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.ant-col-xl-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.ant-col-xl-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.ant-col-xl-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.ant-col-xl-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.ant-col-xl-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.ant-col-xl-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.ant-col-xl-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}

	.ant-col-xl-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}

	.ant-col-xl-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 1600px) {
	.ant-col-xxl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-xxl-push-24 {
		left: 100%;
	}

	.ant-col-xxl-pull-24 {
		right: 100%;
	}

	.ant-col-xxl-offset-24 {
		margin-left: 100%;
	}

	.ant-col-xxl-order-24 {
		order: 24;
	}

	.ant-col-xxl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-xxl-push-23 {
		left: 95.83333333%;
	}

	.ant-col-xxl-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-xxl-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-xxl-order-23 {
		order: 23;
	}

	.ant-col-xxl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-xxl-push-22 {
		left: 91.66666667%;
	}

	.ant-col-xxl-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-xxl-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-xxl-order-22 {
		order: 22;
	}

	.ant-col-xxl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-xxl-push-21 {
		left: 87.5%;
	}

	.ant-col-xxl-pull-21 {
		right: 87.5%;
	}

	.ant-col-xxl-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-xxl-order-21 {
		order: 21;
	}

	.ant-col-xxl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-xxl-push-20 {
		left: 83.33333333%;
	}

	.ant-col-xxl-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-xxl-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-xxl-order-20 {
		order: 20;
	}

	.ant-col-xxl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-xxl-push-19 {
		left: 79.16666667%;
	}

	.ant-col-xxl-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-xxl-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-xxl-order-19 {
		order: 19;
	}

	.ant-col-xxl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-xxl-push-18 {
		left: 75%;
	}

	.ant-col-xxl-pull-18 {
		right: 75%;
	}

	.ant-col-xxl-offset-18 {
		margin-left: 75%;
	}

	.ant-col-xxl-order-18 {
		order: 18;
	}

	.ant-col-xxl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-xxl-push-17 {
		left: 70.83333333%;
	}

	.ant-col-xxl-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-xxl-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-xxl-order-17 {
		order: 17;
	}

	.ant-col-xxl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-xxl-push-16 {
		left: 66.66666667%;
	}

	.ant-col-xxl-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-xxl-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-xxl-order-16 {
		order: 16;
	}

	.ant-col-xxl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-xxl-push-15 {
		left: 62.5%;
	}

	.ant-col-xxl-pull-15 {
		right: 62.5%;
	}

	.ant-col-xxl-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-xxl-order-15 {
		order: 15;
	}

	.ant-col-xxl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-xxl-push-14 {
		left: 58.33333333%;
	}

	.ant-col-xxl-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-xxl-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-xxl-order-14 {
		order: 14;
	}

	.ant-col-xxl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-xxl-push-13 {
		left: 54.16666667%;
	}

	.ant-col-xxl-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-xxl-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-xxl-order-13 {
		order: 13;
	}

	.ant-col-xxl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-xxl-push-12 {
		left: 50%;
	}

	.ant-col-xxl-pull-12 {
		right: 50%;
	}

	.ant-col-xxl-offset-12 {
		margin-left: 50%;
	}

	.ant-col-xxl-order-12 {
		order: 12;
	}

	.ant-col-xxl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-xxl-push-11 {
		left: 45.83333333%;
	}

	.ant-col-xxl-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-xxl-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-xxl-order-11 {
		order: 11;
	}

	.ant-col-xxl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-xxl-push-10 {
		left: 41.66666667%;
	}

	.ant-col-xxl-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-xxl-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-xxl-order-10 {
		order: 10;
	}

	.ant-col-xxl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-xxl-push-9 {
		left: 37.5%;
	}

	.ant-col-xxl-pull-9 {
		right: 37.5%;
	}

	.ant-col-xxl-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-xxl-order-9 {
		order: 9;
	}

	.ant-col-xxl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-xxl-push-8 {
		left: 33.33333333%;
	}

	.ant-col-xxl-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-xxl-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-xxl-order-8 {
		order: 8;
	}

	.ant-col-xxl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-xxl-push-7 {
		left: 29.16666667%;
	}

	.ant-col-xxl-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-xxl-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-xxl-order-7 {
		order: 7;
	}

	.ant-col-xxl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-xxl-push-6 {
		left: 25%;
	}

	.ant-col-xxl-pull-6 {
		right: 25%;
	}

	.ant-col-xxl-offset-6 {
		margin-left: 25%;
	}

	.ant-col-xxl-order-6 {
		order: 6;
	}

	.ant-col-xxl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-xxl-push-5 {
		left: 20.83333333%;
	}

	.ant-col-xxl-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-xxl-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-xxl-order-5 {
		order: 5;
	}

	.ant-col-xxl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-xxl-push-4 {
		left: 16.66666667%;
	}

	.ant-col-xxl-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-xxl-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-xxl-order-4 {
		order: 4;
	}

	.ant-col-xxl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-xxl-push-3 {
		left: 12.5%;
	}

	.ant-col-xxl-pull-3 {
		right: 12.5%;
	}

	.ant-col-xxl-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-xxl-order-3 {
		order: 3;
	}

	.ant-col-xxl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-xxl-push-2 {
		left: 8.33333333%;
	}

	.ant-col-xxl-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-xxl-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-xxl-order-2 {
		order: 2;
	}

	.ant-col-xxl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-xxl-push-1 {
		left: 4.16666667%;
	}

	.ant-col-xxl-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-xxl-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-xxl-order-1 {
		order: 1;
	}

	.ant-col-xxl-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-xxl-push-0 {
		left: auto;
	}

	.ant-col-xxl-pull-0 {
		right: auto;
	}

	.ant-col-xxl-offset-0 {
		margin-left: 0;
	}

	.ant-col-xxl-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xxl-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-xxl-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xxl-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-xxl-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.ant-col-xxl-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.ant-col-xxl-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.ant-col-xxl-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.ant-col-xxl-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.ant-col-xxl-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.ant-col-xxl-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.ant-col-xxl-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.ant-col-xxl-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.ant-col-xxl-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.ant-col-xxl-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.ant-col-xxl-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}

	.ant-col-xxl-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}

	.ant-col-xxl-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.ant-col-xxl-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.ant-col-xxl-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.ant-col-xxl-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.ant-col-xxl-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.ant-col-xxl-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.ant-col-xxl-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.ant-col-xxl-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.ant-col-xxl-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.ant-col-xxl-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.ant-col-xxl-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.ant-col-xxl-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.ant-col-xxl-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}

	.ant-col-xxl-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}

	.ant-col-xxl-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.ant-col-xxl-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.ant-col-xxl-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.ant-col-xxl-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.ant-col-xxl-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.ant-col-xxl-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.ant-col-xxl-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.ant-col-xxl-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.ant-col-xxl-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.ant-col-xxl-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.ant-col-xxl-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.ant-col-xxl-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.ant-col-xxl-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}

	.ant-col-xxl-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}

	.ant-col-xxl-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.ant-col-xxl-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.ant-col-xxl-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.ant-col-xxl-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.ant-col-xxl-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.ant-col-xxl-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.ant-col-xxl-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.ant-col-xxl-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.ant-col-xxl-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.ant-col-xxl-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.ant-col-xxl-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.ant-col-xxl-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.ant-col-xxl-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.ant-col-xxl-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.ant-col-xxl-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}

	.ant-col-xxl-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}

	.ant-col-xxl-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}
