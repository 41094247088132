.form {
	@media screen and (min-width: $mq-3) {

	}

	li {
		.form {
			&:nth-child(even) {
				margin: 0px 0px 20px 0px;
			}
		}
	}
}