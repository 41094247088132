@import '../../assets/cascades/_utilities';
@import "../../assets/cascades/_typo";

// Custom Margin Mixin
section.privacy {
	@include grid-content;
	
	// margin-bottom: $base-margin*2;

	// @media screen and (min-width: $mq-3) {
	// 	margin-bottom: $base-margin*3;
	// }
	// @media screen and (min-width: $mq-4) {
	// 	margin-bottom: $base-margin*4;
	// }

	.ui-sub-heading {
		margin-top: 20px;
	}

	ul {
		list-style-type: "- ";
		padding-left: 10px;
		margin-top: 10px;
		line-height: 30px;
		@extend .ui-copy-text;
	}

	ol {
		padding-left: 0;
		margin-top: 10px;
		line-height: 30px;
		margin-bottom: 0;
		list-style: none;
	}

	.highlighted-text {
		background-color: $lightBlue;
		color: $blue;
		padding: 17px 26px;
	}

	.anchor {
		color: $blue;
		@include font02;
		@include font-medium;
		cursor: pointer;
	}
}

#onetrust-banner-sdk, #onetrust-pc-sdk, #ot-sdk-cookie-policy, #ot-sync-ntfy {
	font-size: 16px !important;
	font-family: "Roboto",sans-serif !important;
	font-weight: 300 !important;
	line-height: 22px !important;
	color: #000 !important;
	margin-top: 20px !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy {
	font-family: "Roboto",sans-serif !important;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #000 !important;
	margin-top: 20px !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title {
	font-family: "Roboto",sans-serif !important;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #000 !important;
	margin-top: 20px !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h5, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h6, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy li, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy p, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description {
	font-family: "Roboto",sans-serif !important;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #000 !important;
	margin-top: 20px !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group {
	font-family: "Roboto",sans-serif !important;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #000 !important;
	margin-top: 20px !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-table-header, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td {
	font-family: "Roboto",sans-serif !important;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #000 !important;
	margin-top: 20px !important;
}
