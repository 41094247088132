@import '../../assets/cascades/utilities';

.boxUpload {
	width: 100%;

	span.errInput {
		display: none;
	}

	.infoUpload {
		@include font00;
		// Use common gray
		color: $text-gray;
		margin-bottom: 30px;
		display: block;
	}
}

.boxBrowse {
	margin-bottom: 10px;
	border: dashed 2px $blue;
	border-radius: 2px;
	cursor: pointer;

	@media screen and (min-width: $mq-4) {
		margin-bottom: 10px;
	}

	&:hover {
		background: $lightBlue;
	}

	p {
		padding: 20px;
		margin: 0;

		@media screen and (min-width: $mq-4) {
			text-align: center;
			padding: 40px 20px;
		}

		span {
			text-decoration: none;
			// font-style: italic;
			display: block;
			width: 100%;

			&.alt {
				@include font-medium;
				color: $blue; 
				width: auto;
				display:inline;
			}
		}
	}
}

.progressbar {
	margin-bottom: 20px;
	.ant-progress-outer {
		margin-right: 0;
		padding-right: 0;
		width: 95%;
	}
	.ant-progress-text {
		text-align: right;
	}
}

input.fluSupport {
	@include transition(0.5s);
	@include border-box;
	width: 100%;
	height: 68px;
	color: $black;
	background-color: $white;
	border-radius: 2px;
	border: solid 2px rgba(154, 154, 154, 0.2);
	padding: 10px 20px;

	// &.gotError {
	//     background-color: rgba(199, 21, 38, 0.15);
	//     border-color: transparent;
	// }
		
	// &.is {
	//     background-color: $greenOk;
	//     border-color: transparent;
	// }

	// &:focus {
	//     outline: none !important;
	//     background-color: $white;
	//     border: solid 2px $bluePassive;
	// }
}

.lstFilesfile {
	@include lst-common;
	padding: 0 0 10px;
	height: auto;
	gap: 20px;

	@media screen and (min-width: $mq-3) {
		padding: 0px 0 30px;
	}
	@media screen and (min-width: $mq-4) {
		gap: 30px;
	}

	// &.empty {
	// 	padding: 0;
	// 	height: 0;
	// }

	li {
		display: flex;
		position: relative;
		flex-wrap: wrap;
		width: 100%;

		.ext {
			@include icon-ext;
			display: block;
			margin: 0;
			padding: 0;

			@media screen and (min-width: $mq-3) {
				width: 50px;
				height: 60px;
			}
		}

		.details {
			width: calc(100% - 65px);

			@media screen and (min-width: $mq-3) {
				width: calc(100% - 80px);
			}

			.name {
				display: block;
				color: $black;
				min-width: 200px;
				padding-left: 15px;
				margin-bottom: 5px;

				@media screen and (min-width: $mq-3) {
					padding-top: 5px;
					padding-left: 30px;
				}
			}
			.size {
				display: block;
				color: $lightGray;
				padding-left: 15px;
				width: 100%;
				overflow: hidden;

				@media screen and (min-width: $mq-3) {
					padding-left: 30px;
				}
			}
			.btnCancelFile {
				@include icon-cancel;
				position: absolute;
				right: -11px;
				top: 8px;

				@media screen and (min-width: $mq-3) {
					top: 12px;
				}
			}
		}

		.fileerrorMessage {
			width: 100%;
			padding-left: 58px;
			color: $red;
			@media screen and (min-width: $mq-3) {
				padding-left: 80px;
			}
		}
	}
}