@import 'utilities';

html,
body {
	height: 100%;
	width: 100%;
	@include font;
	// filter: gray;
	// filter: grayscale(100%);
	// -webkit-filter: grayscale(100%);
	// -moz-filter: grayscale(100%);
	// -ms-filter: grayscale(100%);
	// -o-filter: grayscale(100%);
	// filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	// filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
	// -webkit-filter: grayscale(1);
}

.App {
	width: 100%;
	height: 100%;
	min-width: $mq-1;
	//corrupts the sticky items
	//overflow-x: hidden;
	position: relative;
	background-color: $white;
	padding-top: $header-height;
	overflow-x: hidden;
	// Alternative BG Color
	&.alt {
		background-color: $nearlyWhite;
	}
}

section {
	z-index: 10;
	position: relative;
}

p {
	@include font-light;
}

a {
	@include font-medium;
}

h1,
h2,
h3,
h4 {
	@include font-bold;
}

.inner-text {
	max-width: $text-content-max-width;
}

.btnTab {
	@include font04;
	color: $darkGray;
	background-color: transparent;
	width: 50%;
	text-align: center;
	padding: 10px 15px;
	border: 0;
	border-bottom: solid 3px $lighterGray;

	&:hover {
		color: $blue;
	}

	&.current {
		border-bottom: solid 3px $blue;
		color: $black;
	}

	.name {
		width: fit-content;
		position: relative;
		@include font-light;
	}

	@media screen and (min-width: $mq-3) {
		@include font07;
	}
	@media screen and (min-width: $mq-4) {
		@include font08;
		// width: 375px;
		// margin-right: 2px;
	}
	@media screen and (min-width: $mq-6) {
		padding: 10px 20px;
	}
}

.headertooltip {
	.ant-tooltip-inner {
		background: $black !important;
		@include font00;
		@include font-light;
		color: $white !important;
		padding: 20px !important;
		min-height: 23px;
	}
}

.deputytooltip {
	.ant-tooltip-inner {
		background: $blue !important;
		@include font00;
		@include font-light;
		color: $white !important;
		padding: 20px !important;
		min-height: 23px;
	}
}

.filetooltip {
	.ant-tooltip-inner {
		background: $black !important;
		@include font00;
		@include font-light;
		color: $white !important;
		padding: 20px !important;
		min-height: 23px;
	}
	.ant-tooltip-arrow-content::before {
		background: $black !important;
	}
}

.selectemailtooltip {
	.emailwrap {
		display: flex !important;
		flex-direction: column !important;
		.ant-tooltip-inner {
			padding: 20px !important;
		}
		.emailitem {
			// @include font-light;
			font-size: 16px !important;
			line-height: 34px !important;
			cursor: pointer;
			&:hover {
				color: #1fa1d2;
			}
		}
	}
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #c7cacf;
	border-radius: 4px;
	opacity: 0.3;
}

.reportform {
	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}
	.errorwrap {
		display: flex;
		align-items: center;
		margin-top: 5px;
		color: #c71526;
		.erroricon {
			margin-left: 5px;
			cursor: pointer;
		}
	}
	.errorwrapshow {
		display: flex;
	}
	.errorwraphide {
		display: none;
	}
	.antd_form_title {
		margin-bottom: 10px;
		font-size: 16px;
		display: flex;
	}
	.antd_label {
		margin-bottom: 30px;
	}
	.antd_sub_label {
		margin-bottom: 30px;
		line-height: 60px;
	}
	.ant-form-item-explain-error {
		color: #c71526;
		font-weight: 500;
	}
	.antd_input {
		height: 60px;
		padding: 10px 20px;
		font-weight: 300;
		border-radius: 2px;
		border: 2px solid hsla(0, 0%, 60.4%, 0.2);
		&.gotError {
			background-color: $border-red;
			border-color: transparent;
		}
		&.isFilled {
			background-color: $greenOk !important;
			border-color: transparent;
		}
		&:focus {
			outline: none !important;
			background-color: $white;
			border: solid 2px $bluePassive;
			box-shadow: none;
		}
	}
	.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
	.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
		background-color: rgba(199, 21, 38, 0.15) !important;
		border-color: transparent;
		&:focus {
			border: none;
			box-shadow: none;
		}
	}
	.antd_select {
		height: 60px;
		.ant-select-selector {
			height: 56px !important;
			border-radius: 2px !important;
			border: 2px solid hsla(0, 0%, 60.4%, 0.2) !important;
			.ant-select-selection-placeholder {
				line-height: 56px !important;
				font-weight: 300 !important;
				text-indent: 20px;
			}
			.ant-select-selection-item {
				line-height: 56px !important;
				font-weight: 300 !important;
				text-indent: 20px;
			}
		}
		.ant-select-selection-search-input {
			height: 60px !important;
		}
	}
	.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open
		.ant-select-selector,
	.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused
		.ant-select-selector
		.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
		box-shadow: none !important;
		border-color: transparent;
		background-color: rgba(199, 21, 38, 0.15) !important;
	}
	.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
		.ant-select-selector {
		box-shadow: none !important;
		border-color: transparent !important;
		background-color: rgba(199, 21, 38, 0.15) !important;
	}
	.ant-select-selector {
		border-color: transparent !important;
	}
	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
		.ant-select-selector {
		box-shadow: none !important;
	}
	.antd_date {
		height: 60px;
		padding: 10px 20px;
		border-radius: 2px;
		font-weight: 300 !important;
		border: 2px solid hsla(0, 0%, 60.4%, 0.2);
		&:hover {
			border: 2px solid hsla(0, 0%, 60.4%, 0.2) !important;
		}
		input {
			font-weight: 300 !important;
		}
	}
	.ant-picker-status-error {
		box-shadow: none !important;
		border-color: transparent !important;
		background-color: rgba(199, 21, 38, 0.15) !important;
	}
	input:read-only {
		&::placeholder {
			font-weight: 300;
			color: #b2b2b2;
		}
	}
	.d4d5select {
		.ant-select-selection-placeholder {
			color: #000;
			@include font-bold;
			font-weight: 700 !important;
		}
	}
}
