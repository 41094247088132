@import '../../assets/cascades/utilities';

footer {
	position: relative;
	padding: 20px 5%;
	background-color: $darkGray;
	z-index: 100;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	margin-bottom: -2px; // fix to hide transparent border loading element

	@media screen and (min-width: $mq-3) {
		// height: 120px;
		padding: 28px 7%;
	}
	.internallogin {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 20px;
		height: 20px;
		line-height: 20px;
		display: block;
		cursor: pointer;
	}
	.internallogin1 {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 20px;
		height: 20px;
		line-height: 20px;
		display: block;
		cursor: pointer;
	}
}

.lstFooter {
	width: 100%;
	max-width: $content-max-width;
	// min-height: 20px;
	list-style: none;
	margin: 0 auto;
	padding: 0;
	display: flex;
	flex-flow: column wrap;

	@media screen and (min-width: $mq-3) {
		flex-flow: row wrap;
		justify-content: center;
	}

	@media screen and (min-width: $mq-5) {
		justify-content: flex-start;
		align-items: center;
	}

	// Custom Margin Mixin
	li {
		margin-bottom: 20px;
		flex: 1 0;

		@media screen and (min-width: $mq-3) {
			flex: 0;
			margin-bottom: 20px;
			margin-right: 12px;
			text-align: center;
			margin-left: 12px;
		}
		@media screen and (min-width: $mq-5) {
			margin-bottom: 0px;
			margin-right: 25px;
			margin-left: 0;
			text-align: left;
		}

		a,
		span {
			cursor: pointer;
			@include transition(0.3s);
			@include font01; // IOS backup
			@include font-bold;
			text-decoration: none;
			white-space: nowrap;
			color: $white;
			padding-bottom: 2px;
			display: inline-block; // IOS backup
			min-height: $font-size-01 + 6; // IOS backup, equals line-height for mixing font01

			@media screen and (min-width: $mq-3) {
				display: block;
				text-align: center;
				width: 100%;
			}
			@media screen and (min-width: $mq-4) {
				text-align: left;
				display: inline;
				width: auto;
			}

			&.current {
				color: $white;
				border-bottom: 2px solid $white;
			}

			&:hover {
				color: $lighterGray;
			}
			&:active {
				color: $white;
			}
		}
	}
	li.trademark {
		margin: 0;

		@media screen and (min-width: $mq-3) {
			flex-basis: 100%;
			text-align: center;
		}
		@media screen and (min-width: $mq-5) {
			flex: 1 1 0;
			text-align: right;
		}
		// Custom Size Mixin
		p {
			@include font-light;
			color: #adadad;
			white-space: nowrap;
			margin: 0;
			padding: 0;
			line-height: 20px;
		}
	}
}
