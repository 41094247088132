@import '../../assets/cascades/utilities';

.bgImage {
	z-index: 5;
	position: absolute;
	top: -$header-height;
	left: 0;
	// transform: translateX(-50%);
	object-fit: cover;
	width: 100%;
	min-height: 250px;

	// USE FLEX OR GRID FOR HEIGHT
	// Common Height Mixin
	@media screen and (min-width: $mq-2) {
		min-height: 330px;
	}
	@media screen and (min-width: $mq-3) {
		min-height: 700px;
	}
	@media screen and (min-width: $mq-4) {
		min-height: 800px;
	}

	// all covering image
	// Trust the BG modifier
	&.cover {
		width: 100%;
		min-height: 250px;
		
		@media screen and (min-width: $mq-2) {
			min-width: 100%;
			min-height: 330px;
		}
		@media screen and (min-width: $mq-3) {
			height: calc(100vh - 124px);
			min-height: auto;
			width: auto;
		}
		@media screen and (min-width: $mq-4) {
			height: calc(100vh - 108px);
			min-height: auto;
		}
		@media screen and (min-width: $mq-5) {
			height: calc(100vh - 112px);
		}
		@media screen and (min-width: $mq-6) {
			height: calc(100vh - 116px);
		}
	}
}

.bgColor {
	z-index: 5;
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	top: -109px;
	left: 0;
	right: 0;
	background: linear-gradient(to bottom, $lighterGray 0%, $lighterGray 250px, 
				$nearlyWhite 250px, $nearlyWhite 100%); 

	@media screen and (min-width: $mq-2) {
		background: linear-gradient(to bottom, $lighterGray 0%, $lighterGray 300px, 
					$nearlyWhite 300px, $nearlyWhite 100%); 
	}
	@media screen and (min-width: $mq-3) {
		background: linear-gradient(to bottom, $lighterGray 0%, $lighterGray 700px, 
					$nearlyWhite 700px, $nearlyWhite 100%); 
	}
	@media screen and (min-width: $mq-4) {
		background: linear-gradient(to bottom, $lighterGray 0%, $lighterGray 800px, 
					$nearlyWhite 800px, $nearlyWhite 100%); 
	}
}