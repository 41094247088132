@import '../../../assets/cascades/utilities';

@page {
	size: A4 !important;
	margin: 10mm 10mm 14mm 10mm !important;
	box-sizing: border-box !important;
	min-width: 0 !important;
	width: 210mm !important;
	min-height: 297mm !important;
}

@media print {
	* {
		margin: 0;
		padding: 0;
	}

	html,
	body {
		min-width: 0 !important;
		width: 210mm !important;
		min-height: 297mm !important;
		height: 297mm !important;
		-webkit-print-color-adjust: exact !important;
	}
	.App {
		margin: 0 !important;
		padding: 0 !important;
		background: $white !important;
	}
	.A4Size {
		visibility: visible !important;
		display: block !important;
		width: 210mm;
		margin: 0;
		position: relative;
		box-sizing: border-box;
		padding: 0;
		.printheader {
			visibility: visible;
			display: block;
			width: 210mm;
			height: 15mm;
			display: flex;
			margin-bottom: 8mm;
			.printheaderleft {
				background: #c9c9c9;
				flex: 1;
			}
			.printheaderright {
				width: 42mm;
				margin-left: 3.5mm;
				background: #1fa1d2;
				img {
					width: 22mm;
					height: 9mm;
					display: block;
					margin: 3mm 0 0 3mm;
					filter: brightness(0) invert(1);
				}
			}
		}
		.printcontent {
			margin: 0 5mm;
			display: flex;
			.printcontent_left {
				width: 60mm;
				height: 270mm;
				box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
				.printcontent_left_top {
					padding: 6mm 4mm 4mm 4mm;
					height: auto;
					background: #e8e8e8;
					span {
						color: $black;
						font-size: 10pt;
						@include font-bold;
						height: 12pt;
						line-height: 12pt;
					}
					p {
						color: $black;
						font-size: 15pt;
						@include font-light;
						height: 20pt;
						line-height: 20pt;
					}
				}
				.printcontent_ul {
					list-style: none;
					padding: 6mm 4mm 10mm 4mm;
					background: #fff;
					.printcontent_li {
						height: auto;
						margin-bottom: 3mm;
						span {
							color: $black;
							font-size: 10pt;
							@include font-bold;
						}
						p {
							color: $black;
							font-size: 10pt;
							@include font-light;
							margin-top: 2mm;
						}
						input {
							@include txb-common;
							color: $black;
							font-size: 10pt;
							@include font-light;
							margin-top: 2mm;
							width: 100%;
							height: 10mm;
							line-height: 10mm;
							padding: 10px 20px 10px 3mm;
							border: solid 1px $border-gray;
							word-break: break-word;
						}
						textarea {
							@include txa-common;
							color: $black;
							font-size: 10pt;
							@include font-light;
							margin-top: 2mm;
							padding: 20px 20px 20px 3mm;
							border: solid 1px $border-gray;
							word-break: break-word;
						}
						.input {
							color: $black;
							font-size: 10pt;
							@include font-light;
							margin-top: 2mm;
							line-height: 14pt;
							min-height: 10mm;
							padding: 3mm;
							border: solid 1px $border-gray;
							word-break: break-word;
						}
						.textarea {
							color: $black;
							font-size: 10pt;
							@include font-light;
							margin-top: 2mm;
							line-height: 14pt;
							min-height: 30mm;
							padding: 3mm;
							border: solid 1px $border-gray;
							word-break: break-word;
						}
					}
					.disclaimer {
						@include font-light;
						width: 100%;
						color: $gray;
						font-size: 8pt;
						margin-top: 3mm;
					}
				}
			}
			.printcontent_right {
				width: 121mm;
				// min-height: 200mm;
				// float: left;
				margin-left: 3mm;
				padding: 0 8mm 12mm 8mm;
				box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
				position: relative;
				// box-sizing: border-box;
				.printcontent_top {
					height: auto;
					text-align: center;
					display: flex;
					flex-direction: column;
					.status {
						position: absolute;
						top: 7mm;
						right: -2mm;
						background: #d2ecf6;
						width: 14mm;
						height: 8mm;
						font-size: 8pt;
						line-height: 8mm;
						text-align: center;
						color: $black;
					}
					.open {
						background: $blue;
						color: $white;
					}
					.draft {
						background: $bluePassive;
						color: $black;
					}
					.createdon {
						margin-top: 7mm;
						font-size: 10pt;
						@include font-bold;
						color: $lightGray;
					}
					.ncnumber {
						margin-top: 2mm;
						font-size: 15pt;
						@include font-light;
					}
					.toptittle {
						margin-top: 4mm;
						font-size: 21pt;
						@include font-bold;
					}
					.description {
						margin-top: 4mm;
						font-size: 10pt;
						@include font-light;
						line-height: 14pt;
					}
				}
				.printcontent_justification {
					margin-top: 4mm;
					height: 10mm;
					line-height: 10mm;
					width: 121mm;
					border: 1px solid $nearlyWhite;
					border-left: none;
					border-right: none;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.justification_text {
						padding-left: 3mm;
						font-size: 10pt;
						@include font-light;
					}
					.btnJustify {
						border: 0;
						margin: 0;
						padding: 0;
						background-color: $white;
						img {
							@include transition(0.3s);
							width: 16mm;
							height: 5mm;
							margin-top: 1mm;
						}
					}
				}
				.printcontent_cause {
					height: auto;
					display: flex;
					flex-direction: column;
					.maintitle {
						margin-top: 6mm;
						font-size: 12pt;
						@include font-bold;
					}
					.infowrap {
						width: 121mm;
						margin-top: 3mm;
						background-color: $greenOk;
						border-color: transparent;
						padding: 3mm;
						box-sizing: border-box;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							line-height: 14pt;
						}
					}
					.infowrap_open {
						width: 121mm;
						margin-top: 3mm;
						background-color: $white;
						border-color: transparent;
						padding: 3mm;
						box-sizing: border-box;
						border: solid 1px $border-gray;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							color: $gray;
						}
					}
				}
				.printcontent_action {
					height: auto;
					display: flex;
					flex-direction: column;
					.maintitle {
						margin-top: 6mm;
						font-size: 12pt;
						@include font-bold;
					}
					.subtitle {
						margin-top: 3mm;
						font-size: 10pt;
						@include font-bold;
					}
					.infowrap {
						width: 121mm;
						margin-top: 3mm;
						background-color: $greenOk;
						border-color: transparent;
						padding: 3mm;
						box-sizing: border-box;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							line-height: 14pt;
						}
					}
					.infowraphalf {
						width: 60mm;
						margin-top: 3mm;
						background-color: $greenOk;
						border-color: transparent;
						padding: 3mm;
						box-sizing: border-box;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							line-height: 14pt;
						}
					}
					.infowraphalf_open {
						width: 60mm;
						margin-top: 3mm;
						background-color: $white;
						border: solid 1px $border-gray;
						padding: 3mm;
						box-sizing: border-box;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							line-height: 14pt;
							color: $gray;
						}
					}
					.infowrap_open {
						width: 121mm;
						margin-top: 3mm;
						background-color: $white;
						border-color: transparent;
						padding: 3mm;
						box-sizing: border-box;
						border: solid 1px $border-gray;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							color: $gray;
						}
					}
				}
				.printcontent_action_8dreport {
					height: auto;
					display: flex;
					flex-direction: column;
					.maintitle {
						margin-top: 6mm;
						font-size: 12pt;
						@include font-bold;
					}
					.maintitle_8d {
						margin-top: 6mm;
						font-size: 12pt;
						@include font-bold;
						padding: 3mm;
						background: #f0f0f0;
					}
					.subtitle {
						margin-top: 3mm;
						font-size: 10pt;
						@include font-bold;
					}
					.infowrap {
						width: 121mm;
						margin-top: 3mm;
						background-color: $greenOk;
						border-color: transparent;
						padding: 3mm;
						box-sizing: border-box;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							line-height: 14pt;
						}
					}
					.item8d {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						.infowrapfull {
							width: 121mm;
							margin-top: 3mm;
							background-color: $greenOk;
							border-color: transparent;
							padding: 3mm;
							box-sizing: border-box;
							.infotext_bold {
								font-size: 10pt;
								@include font-bold;
							}
							.infotext_light {
								font-size: 10pt;
								@include font-light;
								line-height: 14pt;
							}
						}
						.infowrapfull_open {
							width: 121mm;
							margin-top: 3mm;
							background-color: $white;
							border: solid 1px $border-gray;
							padding: 3mm;
							box-sizing: border-box;
							.infotext_bold {
								font-size: 10pt;
								@include font-bold;
							}
							.infotext_light {
								font-size: 10pt;
								@include font-light;
								line-height: 14pt;
								color: $gray;
							}
						}
						.infowraphalf {
							width: 59mm;
							margin-top: 3mm;
							background-color: $greenOk;
							border-color: transparent;
							padding: 3mm;
							box-sizing: border-box;
							.infotext_bold {
								font-size: 10pt;
								@include font-bold;
							}
							.infotext_light {
								font-size: 10pt;
								@include font-light;
								line-height: 14pt;
							}
						}
						.infowraphalf_open {
							width: 59mm;
							margin-top: 3mm;
							background-color: $white;
							border: solid 1px $border-gray;
							padding: 3mm;
							box-sizing: border-box;
							.infotext_bold {
								font-size: 10pt;
								@include font-bold;
							}
							.infotext_light {
								font-size: 10pt;
								@include font-light;
								line-height: 14pt;
								color: $gray;
							}
						}
					}
					.infowrap_open {
						width: 121mm;
						margin-top: 3mm;
						background-color: $white;
						border-color: transparent;
						padding: 3mm;
						box-sizing: border-box;
						border: solid 1px $border-gray;
						.infotext_bold {
							font-size: 10pt;
							@include font-bold;
						}
						.infotext_light {
							font-size: 10pt;
							@include font-light;
							color: $gray;
						}
					}
				}
				.border {
					border-top: solid 1px $nearlyWhite;
					margin-top: 6mm;
				}
				.printcontent_file {
					height: auto;
					display: flex;
					flex-direction: column;
					margin: 6mm 0 0 0;
					.maintitle {
						font-size: 12pt;
						@include font-bold;
					}
					.blueborder {
						margin-top: 3mm;
						padding: 0 3mm;
						display: flex;
						box-sizing: border-box;
						align-items: center;
						justify-content: center;
						height: 20mm;
						width: 121mm;
						border: dashed 1px #1fa1d2;
						p {
							font-size: 10pt;
							@include font-light;
							.alt {
								color: $blue;
							}
						}
					}
					.infoUpload {
						margin-top: 2mm;
						font-size: 8pt;
						color: $text-gray;
						line-height: 12pt;
					}
				}
				.printcontent_filelist {
					height: auto;
					display: flex;
					flex-direction: column;
					margin: 0;
					.filename {
						margin-top: 2mm;
						font-size: 10pt;
						@include font-bold;
					}
				}
			}
		}
	}
	.print_hide {
		visibility: hidden !important;
		display: none !important;
	}
	.print_show {
		visibility: visible !important;
		display: block !important;
	}
	.print_dom {
		page-break-after: always;
	}
}

.A4Size {
	display: none;
}
