@import "../../assets/cascades/utilities";

// WORK-IN-PROGRESS
header {
	width: 100%;
	height: auto;
	padding: 0;
	display: flex;
	position: relative;
	flex-flow: row wrap;
	z-index: 300;
	margin-top: -$header-height;

	.adminbarwrap {
		width: 100%;
		background: rgba($color: #000000, $alpha: 0.7);
		.adminbar {
			width: 100%;
			display: flex;
			flex-direction: column;
			// @media screen and (min-width: $mq-2) {
			// 	padding: 0 5% 50px;
			// }
			@media screen and (min-width: $mq-3) {
				width: 91%;
				text-indent: 0;
				padding: 0 4% 0;
				height: 40px;
				flex-direction: row;
				align-items: center;
			}
			@media screen and (min-width: $mq-4) {
				text-indent: 0;
				padding: 0 4% 0;
			}
			@media screen and (min-width: $mq-5) {
				padding: 0 4% 0;
			}
			@media screen and (min-width: $mq-6) {
				margin: 0 auto 0;
				text-indent: 0;
				padding: 0;
				width: 100%;
				// max-width: $content-max-width;
			}
			.adminbarleft {
				color: #fff;
				@include font00;
				@include font-light;
				font-weight: 300;
				width: 100%;
				padding: 10px 5% 0 5%;
				box-sizing: border-box;
				word-break: break-all;
				@media screen and (min-width: $mq-3) {
					flex: 1;
					padding-top: 0;
					padding-left: 3%;
				}
				@media screen and (min-width: $mq-6) {
					flex: none;
					width: 75%;
					padding-left: 7%;
				}
				.useremail {
					@include font-bold;
					display: block;
					@media screen and (min-width: $mq-3) {
						display: inline;
					}
				}
			}
			.adminbarright {
				@include font00;
				color: $blue;
				width: 100%;
				padding: 0 5% 10px 5%;
				cursor: pointer;
				box-sizing: border-box;
				@media screen and (min-width: $mq-3) {
					width: 25%;
					text-align: right;
					padding-bottom: 0;
					padding-left: 0;
					padding-right: 3%;
				}
				@media screen and (min-width: $mq-6) {
					padding-right: 7%;
				}
			}
		}
	}
	

	.emptybar {
		width: 100%;
		height: 25px;
	}

	&.covered {
		height: 100vh;
		padding: 0;
		position: fixed;
		overflow-y: scroll;
		top: 0;
		left: 0;
		right: 0;
		min-width: $mq-1;
		background: $background-darkest;
		-webkit-backdrop-filter: blur(24px);
		backdrop-filter: blur(24px);
		padding-top: 25px;
		padding-bottom: 35px;
		-ms-overflow-style: none;
		scrollbar-width: none;
		margin-top: 0;

		&::-webkit-scrollbar {
			display: none;
		}

		// @media screen and (min-width: $mq-5) {
		//     height: auto;
		//     margin: 25px 0 0;
		//     position: relative;
		// }
	}

	h1.lnkLogo {
		width: 135px;
		height: 64px;
		margin: 0;
		outline: none;
		text-indent: -9999px;
		white-space: nowrap;

		@media screen and (min-width: $mq-3) {
			width: 160px;
			height: 84px;
		}
		@media screen and (min-width: $mq-4) {
			height: 80px;
		}

		a {
			width: 95px;
			height: 34px;
			outline: none;
			float: left;
			padding: 15px 20px;
			background-color: $blue;

			@media screen and (min-width: $mq-3) {
				width: 120px;
				height: 40px;
				padding: 22px 20px;
			}
			@media screen and (min-width: $mq-4) {
				padding: 20px 20px;
			}

			img {
				filter: brightness(0) invert(1);
				width: 100%;
				float: left;
			}

			// &:hover {
			//     img {
			//         filter: brightness(0) invert(0.9);
			//     }
			// }
		}
	}

	nav {
		display: flex;
		padding-top: 12px;

		@media screen and (min-width: $mq-3) {
			padding-top: 22px;
		}
		@media screen and (min-width: $mq-4) {
			padding-top: 20px;
		}
		@media screen and (min-width: $mq-4) {
			padding-top: 20px;
		}

		.btnMenu {
			@include transition(0.3s);
			position: relative;
			width: 50px;
			height: 50px;
			border: 0;
			margin: 0 auto 0 0;
			padding: 0;
			background-color: transparent;
			// background: red;

			@media screen and (min-width: $mq-5) {
				display: none;
			}

			&.always {
				text-align: left;
				@media screen and (min-width: $mq-5) {
					width: 110px;
					display: block;
				}
			}

			img {
				@include transition(0.3s);
				// $white
				filter: invert(99%) sepia(4%) saturate(2%) hue-rotate(281deg)
					brightness(114%) contrast(100%);
				width: 20px;
				height: 24px;
				margin: 4px 11px 12px 1px;
			}

			&:hover {
				img {
					// $lighterGray
					filter: invert(96%) sepia(35%) saturate(0%) hue-rotate(227deg)
						brightness(95%) contrast(78%);
				}
			}
		}
	}

	// WORK-IN-PROGRESS
	// Percentage Mixin
	.aside {
		width: 5%;
		height: 64px;

		@media screen and (min-width: $mq-2) {
			width: 5%;
		}
		@media screen and (min-width: $mq-3) {
			width: 7%;
			height: 84px;
		}
		@media screen and (min-width: $mq-4) {
			height: 80px;
		}

		&.asideLeft {
			// No need to change bg
			// All transparent fits
			background: $background-light;
		}
		&.asideRight {
			background-color: $blue;
		}

		&.transparent {
			background: transparent;
		}
	}

	// Percentage Mixin
	// Common Size Mixin
	.headerBar {
		width: 90%;
		height: 64px;
		display: flex;
		// NES: Narrow Empty Space
		justify-content: space-between;
		padding-top: 0;

		@media screen and (min-width: $mq-2) {
			width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			width: 86%;
			height: 84px;
		}
		@media screen and (min-width: $mq-4) {
			height: 80px;
		}

		.leftBar {
			width: 50%;
			// No need to change bg
			// All transparent fits
			background: $background-light;

			@media screen and (min-width: $mq-2) {
				width: 64%;
			}
			@media screen and (min-width: $mq-3) {
				width: 70%;
			}
			@media screen and (min-width: $mq-4) {
				width: 74%;
			}
			@media screen and (min-width: $mq-5) {
				width: 78%;
			}
			@media screen and (min-width: $mq-6) {
				width: 82%;
			}

			&.transparent {
				background: transparent;
			}
		}

		.rightBar {
			// Minimum empty space between: 20px
			width: calc(50% - 20px);
			background-color: $blue;

			// TODO: Generalize Column Behaviours
			@media screen and (min-width: $mq-2) {
				width: calc(36% - 20px);
			}
			@media screen and (min-width: $mq-3) {
				width: calc(30% - 20px);
			}
			@media screen and (min-width: $mq-4) {
				width: calc(26% - 20px);
			}
			@media screen and (min-width: $mq-5) {
				width: calc(22% - 20px);
			}
			@media screen and (min-width: $mq-6) {
				width: calc(18% - 20px);
			}
		}

		.btnLogout {
			@include transition(0.3s);
			background-color: transparent;
			display: flex;
			flex-direction: row;
			width: auto;
			height: 40px;
			border: 0;
			padding: 0;
			margin: 0 0 0 auto;

			&.hidden {
				display: none;
			}

			span {
				@include transition(0.3s);
				@include txt-common($size: 16px, $height: 40px);
				color: $white;
				padding-right: 10px;
			}

			img {
				@include transition(0.3s);
				width: 24px;
				height: 22px;
				margin: 9px 0;
			}

			&:hover {
				span {
					color: $lighterGray;
				}
				img {
					filter: invert(15%);
				}
			}
		}
	}

	// SubPages Container
	.flyoutContent {
		width: 90%;
		margin: 0 5%;

		// HEADER
		min-height: calc(100vh - 64px);

		@media screen and (min-width: $mq-3) {
			min-height: calc(100vh - 84px);
			width: 86%;
			margin: 0 7%;
		}
		@media screen and (min-width: $mq-4) {
			min-height: calc(100vh - 80px);
		}

		// Lists of Items

		.lstIconsAlt {
			@include lst-common;
			display: flex;
			flex-direction: row-reverse;
			margin: 10px 0;

			li {
				margin-left: 5px;

				@media screen and (min-width: $mq-2) {
					margin-left: 0;
				}
				@media screen and (min-width: $mq-3) {
					margin-left: 5px;
				}

				button {
					border: 0;
					background: none;
					outline: none;
					width: 40px;
					height: 40px;
					padding: 0;
					margin: 0;

					img {
						@include transition(0.3s);
						// $white
						filter: invert(99%) sepia(4%) saturate(2%) hue-rotate(281deg)
							brightness(114%) contrast(100%);
						width: 22px;
						height: 22px;
						margin: 9px;
						float: left;

						&.small {
							width: 18px;
							height: 18px;
							margin: 11px;
						}

						&.big {
							width: 26px;
							height: 26px;
							margin: 7px;
						}
					}

					&:hover {
						img {
							// $lighterGray
							filter: invert(96%) sepia(35%) saturate(0%) hue-rotate(227deg)
								brightness(95%) contrast(78%);
						}
					}
				}
			}
		}

		.lstMenu {
			@include lst-common;
			display: flex;
			border-top: solid 2px $border-black;

			@media screen and (min-width: $mq-3) {
				max-width: 400px;
			}

			// @media screen and (min-width: $mq-5) {
			//     display: flex;
			//     width: 100%;
			//     position: relative;
			//     top: auto;
			//     left: auto;
			//     max-width: max-content;
			//     border-top: 0;
			// }

			li {
				border-bottom: solid 2px $border-black;

				// @media screen and (min-width: $mq-5) {
				//     border-bottom: 0;
				//     width: auto;
				// }

				a {
					@include transition(0.3s);
					@include txt-common($size: 18px, $height: 50px, $letter: 0.6px);
					text-decoration: none;
					color: $white;

					&.current {
						color: $blue;
					}

					&:hover {
						color: $lighterGray;
					}
				}

				span {
					@include transition(0.3s);
					@include txt-common($size: 18px, $height: 50px, $letter: 0.6px);
					text-decoration: none;
					color: $white;
					cursor: pointer;

					&.current {
						color: $blue;
					}

					&:hover {
						color: $lighterGray;
					}
				}
			}
		}
	}

	// Lists of Items
	// In the SubPages Container
	.lstNav {
		display: none;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;

		@media screen and (min-width: $mq-5) {
			display: flex;
		}

		&.hidden {
			display: none;
		}

		li {
			margin-right: 20px;

			@media screen and (min-width: $mq-5) {
				margin-right: 30px;
			}
			@media screen and (min-width: $mq-6) {
				margin-right: 40px;
			}

			a {
				@include transition(0.3s);
				@include txt-common($height: 40px, $letter: 0.6px);
				text-decoration: none;
				color: $white;
				padding: 0 2px;

				@media screen and (min-width: $mq-6) {
					@include txt-common($size: 18px, $height: 40px, $letter: 0.6px);
				}

				&.current {
					// color: $blue;
					border-bottom: 3px solid #fff;
				}

				&:hover {
					color: $lighterGray;
				}
				
			}
		}
	}

	.lstIcons {
		display: none;
		margin: 13px;
		outline: none;
		list-style: none;
		margin: 0 0 0 auto;
		padding: 0;
		padding-right: 15px;
		position: relative;

		@media screen and (min-width: $mq-3) {
			display: flex;
		}

		.hide {
			display: none;
		}

		li {
			margin-left: 10px;
			display: block;

			@media screen and (min-width: $mq-4) {
				margin-left: 15px;
			}

			button {
				border: 0;
				background: none;
				outline: none;
				width: 40px;
				height: 40px;
				padding: 0;
				margin: 0;

				img {
					@include transition(0.3s);
					// $white
					filter: invert(99%) sepia(4%) saturate(2%) hue-rotate(281deg)
						brightness(114%) contrast(100%);
					width: 22px;
					height: 22px;
					margin: 9px;
					float: left;

					&.small {
						width: 20px;
						height: 20px;
						margin: 10px;
					}

					&.big {
						width: 26px;
						height: 26px;
						margin: 7px;
					}
				}

				&:hover {
					img {
						// $lighterGray
						filter: invert(96%) sepia(35%) saturate(0%) hue-rotate(227deg)
							brightness(95%) contrast(78%);
					}
				}

				.logoutimage {
					margin-top: 8px;
    			width: 25px;
    			height: 27px;
				}
			}
		}

		&.hidden {
			@media screen and (min-width: $mq-3) {
				display: none;
			}
		}

		&.always {
			display: flex;
		}
	}

	// SubPages
	.profile {
		display: flex;
		flex-direction: column;
		padding-bottom: 60px;

		@media screen and (min-width: $mq-3) {
			flex-direction: row;
			gap: 60px;
		}
		@media screen and (min-width: $mq-4) {
			gap: 90px;
		}
		@media screen and (min-width: $mq-5) {
			gap: 150px;
		}

		// TODO: Generalize Headers
		h3 {
			@include txt-common($size: 18px, $height: 24px);
			color: $white;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				@include txt-common($size: 20px, $height: 28px);
			}
		}

		h4 {
			@include txt-common($size: 16px, $height: 22px);
			color: $white;
			margin-bottom: 25px;
		}

		// Defined Areas
		.boxInformation {
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			@media screen and (min-width: $mq-3) {
				margin-bottom: 40px;
			}

			span {
				@include txt-common($font: "HelveticaLight", $height: 22px);
				// color: $darkGray;
				color: #fff;
				margin-bottom: 10px;

				@media screen and (min-width: $mq-3) {
					margin-bottom: 20px;
				}
			}

			p {
				@include txt-common($height: 22px);
				// color: $darkGray;
				color: #fff;
			}
		}

		.boxProfile {
			display: flex;
			flex-direction: column;
			padding: 30px 0;

			@media screen and (min-width: $mq-3) {
				padding: 20px 0;
				width: 50%;
			}
			@media screen and (min-width: $mq-5) {
				width: 55%;
			}
			@media screen and (min-width: $mq-6) {
				width: 60%;
			}
		}

		.boxDeputy {
			@include border-box;
			display: flex;
			flex-direction: column;
			background-color: $black;
			padding: 25px 25px 60px;

			// TODO: Generalize Column Behaviours
			@media screen and (min-width: $mq-3) {
				width: calc(50% - 60px);
				margin-top: 70px;
				height: fit-content;
			}
			@media screen and (min-width: $mq-4) {
				width: calc(50% - 90px);
			}
			@media screen and (min-width: $mq-5) {
				width: calc(45% - 150px);
			}
			@media screen and (min-width: $mq-6) {
				width: calc(40% - 110px);
			}

			img {
				width: 100px;
				height: 80px;
				margin: 20px auto;
			}

			span {
				@include txt-common($size: 18px, $height: 1.33);
				color: $white;
				margin-bottom: 15px;
				text-align: center;
				width: 100%;

				@media screen and (min-width: $mq-3) {
					@include txt-common($size: 22px, $height: 28px);
				}
			}

			p {
				@include txt-common($font: "HelveticaLight", $height: 22px);
				color: $darkGray;
				margin-bottom: 35px;
				text-align: center;
			}

			.btnOpenDeputy {
				@include btn-common;
				width: fit-content;
				padding: 0 25px;
				margin: 0 auto;
			}
		}

		.boxLangs {
			display: flex;
			flex-flow: wrap;
			gap: 20px;

			span {
				@include btn-shadow;
				width: calc(50% - 10px);
				// flex-basis: 45%;
				// flex-grow: 1;

				// TODO: Generalize Column Behaviours
				@media screen and (min-width: $mq-2) {
					width: calc(33% - 13px);
				}
				@media screen and (min-width: $mq-3) {
					width: calc(50% - 10px);
				}
				@media screen and (min-width: $mq-4) {
					width: calc(33% - 13px);
				}
				@media screen and (min-width: $mq-5) {
					width: calc(25% - 15px);
				}
				@media screen and (min-width: $mq-5) {
					width: calc(20% - 16px);
				}
			}
		}

		// Lists of things
		.lstActions {
			@include lst-common;

			li {
				margin-bottom: 20px;

				a {
					@include transition(0.3s);
					@include txt-common($size: 16px, $height: 22px);
					text-decoration: none;
					color: $blue;

					&:hover {
						color: $darkBlue;
					}
				}
			}
		}
	}

	.deputy {
		display: flex;
		flex-direction: column;
		padding: 30px 0 60px;

		@media screen and (min-width: $mq-3) {
			padding: 20px 0 60px;
		}
		@media screen and (min-width: $mq-5) {
			// padding-right: 15%;
			padding-right: 0;
		}
		@media screen and (min-width: $mq-6) {
			// padding-right: 25%;
			padding-right: 0;
		}

		.errorMessage {
			display: flex;
			align-items: center;
			margin-bottom: 24px;
			.img {
				width: 30px;
				height: 30px;
				margin-right: 20px;
			}
			.title {
				color: #C71526;
				@include font-medium;
			}
		}

		// TODO: Generalize Headers
		h3 {
			@include txt-common($size: 18px, $height: 24px);
			color: $white;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				@include txt-common($size: 22px, $height: 28px);
			}
		}

		h4 {
			@include txt-common($size: 18px, $height: 24px);
			color: $white;
			margin-bottom: 20px;
		}

		// Defined Areas
		p.pageInfo {
			@include txt-common($font: "HelveticaLight", $height: 24px);
			color: $darkWhite;
			margin-bottom: 15px;

			@media screen and (min-width: $mq-2) {
				margin-bottom: 30px;
			}
			@media screen and (min-width: $mq-3) {
				margin-bottom: 20px;
			}
		}

		.boxActions {
			display: flex;
			flex-direction: column;
			padding: 10px 0;
			gap: 20px;
			margin-bottom: 40px;

			@media screen and (min-width: $mq-2) {
				flex-direction: row;

				button {
					width: calc(100% - 10px);
				}
			}

			@media screen and (min-width: $mq-3) {
				button {
					width: fit-content;
					min-width: 150px;
					padding: 0 20px;
				}
			}
		}

		.boxAddNew {
			display: flex;
			flex-direction: row-reverse;
			margin-bottom: 40px;
		}

		.depety10 {
			color: $red;
			display: flex;
			margin-bottom: 40px;
		}

		// Buttons
		.btnAddDeputy {
			@include lnk-common;
			color: $blue;
			width: fit-content;
			padding: 0;

			&:hover {
				color: $blue;
			}

			&.active {
				color: $darkGray;
			}
		}

		.btnCancel {
			@include btn-empty;
			background: transparent;

			&:hover {
				background: transparent;
			}
		}

		.btnApply {
			@include btn-common;
		}

		.lstDeputiesmini {
			@include lst-common;
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
			border-top: solid 2px $border-gray;
			border-bottom: solid 2px $border-gray;
			padding-top: 20px;
			.valuewrap {
				display: flex;
				flex-direction: column;
				.name {
					@include txt-common($height: 22px);
					line-height: 28px;
					color: $white;
					text-align: left;
				}

				.value {
					@include txt-common($font: "HelveticaLight", $height: 22px);
					line-height: 28px;
					color: $white;
					text-align: left;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
				}
			}
			.boxInteract {
				display: flex;
				flex-direction: row;
				border-top: solid 2px $border-gray;
				margin: 10px 0 0;
				padding: 5px 0;
				justify-content: space-around;

				.btnChangeDeputy {
					@include btn-icon;
					width: 40px;
					height: 40px;

					img {
						width: 20px;
						height: 20px;
						margin: 10px;
					}
				}
			}
		}

		.lstDeputiesmall {
			@include lst-common;
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
			border-top: solid 2px $border-gray;
			border-bottom: solid 2px $border-gray;
			padding-top: 20px;
			.valuewrap {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				.name {
					@include txt-common($height: 22px);
					line-height: 28px;
					color: $white;
					text-align: left;
					width: 30%;
				}

				.value {
					@include txt-common($font: "HelveticaLight", $height: 22px);
					line-height: 28px;
					color: $white;
					text-align: right;
					width: 70%;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
				}
			}
			.boxInteract {
				display: flex;
				flex-direction: row;
				border-top: solid 2px $border-gray;
				margin: 10px 0 0;
				padding: 5px 0;
				justify-content: space-around;

				.btnChangeDeputy {
					@include btn-icon;
					width: 40px;
					height: 40px;

					img {
						width: 20px;
						height: 20px;
						margin: 10px;
					}
				}
			}
		}

		.lstDeputiemiddle {
			@include lst-common;
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
			padding-top: 20px;
			color: $white;
			.title {
				display: flex;
				flex-direction: row;
				@include txt-common($height: 22px);
				align-items: center;
				height: 90px;
				border-bottom: solid 2px $border-gray;
				.item1 {
					width: 80px;
				}
				.item2 {
					flex: 2;
					display: flex;
					flex-direction: column;
					line-height: 32px;
					padding-right: 10px;
				}
				.item3 {
					flex: 3;
					display: flex;
					flex-direction: column;
					line-height: 32px;
				}
				.item4 {
					width: 80px;
				}
			}
			.content {
				display: flex;
				flex-direction: row;
				@include txt-common($font: "HelveticaLight", $height: 22px);
				align-items: center;
				height: 90px;
				border-bottom: solid 2px $border-gray;
				.item1 {
					width: 80px;
				}
				.item2 {
					display: flex;
					flex-direction: column;
					line-height: 32px;
					flex: 2;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					padding-right: 10px;
					.value {
						width: 100%;
						white-space:nowrap;
						overflow:hidden;
						text-overflow:ellipsis;
					}
				}
				.item3 {
					flex: 3;
					display: flex;
					flex-direction: column;
					line-height: 32px;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					.value {
						width: 100%;
						white-space:nowrap;
						overflow:hidden;
						text-overflow:ellipsis;
					}
				}
				.item4 {
					width: 80px;
					display: flex;
					flex-direction: row;
					margin: 10px 0 0;
					padding: 5px 0;
					justify-content: space-around;

					.btnChangeDeputy {
						@include btn-icon;
						width: 40px;
						height: 40px;

						img {
							width: 20px;
							height: 20px;
							margin: 10px;
						}
					}
				}
			}
		}

		.lstDeputielarge {
			@include lst-common;
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
			padding-top: 20px;
			color: $white;
			.title {
				display: flex;
				flex-direction: row;
				@include txt-common($height: 22px);
				align-items: center;
				height: 80px;
				border-bottom: solid 2px $border-gray;
				height: 60px;
				.item1 {
					width: 80px;
				}
				.item2 {
					width: 200px;
					padding-right: 10px;
				}
				.item3 {
					width: 200px;
					padding-right: 10px;
				}
				.item4 {
					flex: 1;
					padding-right: 10px;
				}
				.item5 {
					width: 200px;
				}
				.item6 {
					width: 80px;
				}
			}
			.content {
				display: flex;
				flex-direction: row;
				@include txt-common($font: "HelveticaLight", $height: 22px);
				align-items: center;
				height: 80px;
				border-bottom: solid 2px $border-gray;
				height: 60px;
				white-space:nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
				.item1 {
					width: 80px;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
				}
				.item2 {
					width: 200px;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					padding-right: 10px;
				}
				.item3 {
					width: 200px;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					padding-right: 10px;
				}
				.item4 {
					flex: 1;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					padding-right: 10px;
				}
				.item5 {
					width: 200px;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
				}
				.item6 {
					width: 80px;
					width: 80px;
					display: flex;
					flex-direction: row;
					padding: 5px 0;
					justify-content: space-around;

					.btnChangeDeputy {
						@include btn-icon;
						width: 40px;
						height: 40px;

						img {
							width: 20px;
							height: 20px;
							margin: 10px;
						}
					}
				}
			}
		}

		.lstDeputyInfo {
			@include lst-common;
			display: flex;

			@media screen and (min-width: $mq-3) {
				flex-direction: row;
				flex-wrap: wrap;
				gap: 20px;
				margin-bottom: 20px;
			}

			li {
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;

				@media screen and (min-width: $mq-3) {
					width: calc(50% - 10px);
					margin-bottom: 0;

					&.long {
						width: 100%;
					}
				}

				.tag {
					@include txt-common($height: 22px);
					color: $white;
					margin-bottom: 15px;
				}

				.value {
					@include txt-common($font: "HelveticaLight", $height: 22px);
					color: $white;
				}

				.dtpDeputy {
					@include dtp-shadow;
				}

				.txbDeputy {
					@include txb-shadow;
				}

				.ant-picker-input > input {
					color: $white !important;
				}

				.ant-picker-input > input::placeholder {
					color: $text-gray !important;
				}

				.ant-picker:hover {
					outline: none !important;
					background-color: transparent !important;
					border: solid 2px $border-gray !important;
					box-shadow: none !important;
				}

				.ant-picker:focus {
					outline: none !important;
					background-color: transparent !important;
					border: solid 2px $white !important;
					box-shadow: none !important;
				}

				.ant-picker-focused {
					outline: none !important;
					background-color: transparent !important;
					border: solid 2px $white !important;
					box-shadow: none !important;
				}

				.ant-picker-active-bar {
					opacity: 0;
				}

				.ant-picker-range-separator {
					opacity: 0;
				}
				.gotError {
					border: 1px solid #C71526 !important;
				}

				.errInput {
					color: #C71526;
					width: 100%;
					height: 36px;
					line-height: 36px;
					display: none;
					.errorwrap {
						display: flex;
						height: 36px;
						align-items: center;
						img {
							// @include transition(0.3s);
							width: 22px;
							height: 22px;
							margin-left: 10px;
							cursor: pointer;
						}	
					}
				}

				.lh22 {
					line-height: 22px;
				}
	
				.visible {
					display: block;
				}
			}
		}
	}

	.languages {
		display: flex;
		flex-direction: column;
		padding: 30px 0 100px;

		@media screen and (min-width: $mq-3) {
			padding-right: 15%;
		}
		@media screen and (min-width: $mq-5) {
			padding-right: 25%;
		}
		@media screen and (min-width: $mq-6) {
			padding-right: 30%;
		}

		// TODO: Generalize Headers
		h3 {
			@include txt-common($size: 18px, $height: 24px);
			color: $white;
			margin-bottom: 25px;
		}

		.boxLangs {
			display: flex;
			flex-flow: wrap;
			gap: 20px;

			span {
				@include btn-shadow;
				width: calc(50% - 10px);

				// TODO: Generalize Column Behaviours
				@media screen and (min-width: $mq-2) {
					width: calc(33% - 13px);
				}
				@media screen and (min-width: $mq-3) {
					width: calc(25% - 15px);
				}
				@media screen and (min-width: $mq-4) {
					width: calc(20% - 16px);
				}
			}
		}
	}
}

.cancelModal {
	position: relative;
	.ant-modal-content {
		@media screen and (min-width: $mq-2) {
			width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			width: 516px;
		}
		@media screen and (min-width: $mq-4) {
			width: 564px;
		}
		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 60px;

				@media screen and (min-width: $mq-2) {
					padding: 60px 36px;
				}
				.title {
					@include font-light;
					height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.info {
					@include font-light;
					color: $black;
					font-size: 16px;
					line-height: 22px;
				}
			}
			.buttongroup {
				margin-top: 30px;
				display: flex;
				.btnCancel {
					@include transition(0.3s);
					width: 130px;
					border-radius: 2px;
					border: solid 2px $blue;
					background-color: $white;
					text-align: center;
					color: $blue;
					height: 46px;
					text-decoration: none;
					cursor: pointer;
					line-height: 42px;
					font-size: 16px;

					&:hover {
						background-color: $lightBlue;
					}
				}
				.btnLogin {
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;
					margin-left: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}

.ant-slide-up-enter,
.ant-slide-up-appear {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-slide-up-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
	opacity: 0;
	-webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
	-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-slide-down-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
	opacity: 0;
	-webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
	-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@-webkit-keyframes antSlideUpOut {
	0% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@keyframes antSlideUpOut {
	0% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@-webkit-keyframes antSlideDownIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
}
@keyframes antSlideDownIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
}
@-webkit-keyframes antSlideDownOut {
	0% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
}
@keyframes antSlideDownOut {
	0% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
}
