@import '../../assets/cascades/utilities';

.attachModal {
	position: relative;
	.ant-modal-content {
		@media screen and (min-width: $mq-2) {
			width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			width: 516px;
		}
		@media screen and (min-width: $mq-4) {
			width: 564px;
		}
		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 60px;

				@media screen and (min-width: $mq-2) {
					padding: 60px 36px;
				}
				.title {
					@include font-light;
					min-height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.contentwrap {
					display: flex;
					flex-direction: column;
					.itemwrap {
						display: flex;
						height: 60px;
						align-items: center;
						margin-bottom: 20px;
						.itemimage {
							@include icon-ext;
							width: 50px;
							height: 60px;
							margin: 0;
							padding: 0;
						}
						.itemtitlewrap {
							flex: 1;
							display: flex;
							flex-direction: column;
							cursor: pointer;
							.itemfilename {
								display: block;
								color: $black;
								min-width: 200px;
								padding-left: 15px;
								margin-bottom: 5px;
								font-size: 16px;
								@media screen and (min-width: $mq-3) {
									padding-top: 5px;
									padding-left: 30px;
								}
							}
							.itemfilesize {
								display: block;
								color: $lightGray;
								padding-left: 15px;
								width: 100%;
								overflow: hidden;
								font-size: 14px;
								@media screen and (min-width: $mq-3) {
									padding-left: 30px;
								}
							}
						}
					}
				}
			}
			.buttongroup {
				margin-top: 30px;
				display: flex;
				.btnCancel {
					@include transition(0.3s);
					width: 130px;
					border-radius: 2px;
					border: solid 2px $blue;
					background-color: $white;
					text-align: center;
					color: $blue;
					height: 46px;
					text-decoration: none;
					cursor: pointer;
					line-height: 42px;
					font-size: 16px;

					&:hover {
						background-color: $lightBlue;
					}
				}
				.btnLogin {
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;
					margin-left: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}
.ant-modal-mask {
	background: $background-darkest;
}

// TODO: PADDINGS AND MARGINS WILL BE GENERALIZED
// TODO: SPECIFIC WIDTHS FOR COMPONENTS WILL BE ON VARIABLES
// TODO: Use variables for filter colors, less then 5
// TODO: Use Section Mixin
section.overview {
	background-color: $white;
	width: 90%;
	max-width: $content-max-width;
	padding: 0 5% 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: visible;
	// position: relative;
	// Design Adjustment
	margin-top: 146px;
	z-index: 200;

	// TODO: Common Size Mixin
	@media screen and (min-width: $mq-2) {
		margin-top: 226px;
		padding: 0 5% 50px;
	}
	@media screen and (min-width: $mq-3) {
		overflow: visible;
		width: 86%;
		padding: 0 3% 100px;
		margin: 480px 4% 0;
	}
	@media screen and (min-width: $mq-4) {
		padding: 0 3% 100px;
		margin: 500px 4% 0;
	}
	@media screen and (min-width: $mq-5) {
		padding: 0 3% 100px;
		margin: 460px 4% 0;
	}
	@media screen and (min-width: $mq-6) {
		padding: 0 55px 100px;
		margin: 500px auto 0;
		max-width: $content-max-width;
	}

	// TODO: Common Margin Mixin
	// TODO: Generalize Headers
	// Header
	h2 {
		@include font05;
		width: 100%;
		color: $black;
		text-align: center;
		margin-bottom: 20px;
		position: relative;

		@media screen and (min-width: $mq-2) {
			@include font06;
			margin-bottom: 30px;
		}
		@media screen and (min-width: $mq-3) {
			@include font09;
			margin-bottom: 0;
			padding: 35px 0 15px;
		}
		@media screen and (min-width: $mq-4) {
			@include font10;
			padding-bottom: 30px;
		}
	}

	// Individual Buttons
	.btnDownload {
		@include transition(0.3s);
		@include font-bold;
		width: 100%;
		height: 50px;
		display: block;
		border-radius: 2px;
		border: solid 2px $blue;
		background-color: $white;
		text-align: center;
		color: $blue;
		text-decoration: none;
		cursor: pointer;

		@media screen and (min-width: $mq-3) {
			width: fit-content;
			padding: 0 30px;
			margin-left: auto;
		}
		@media screen and (min-width: $mq-4) {
			height: 60px;
		}

		&:hover {
			background-color: $lightBlue;
		}
	}

	// TODO: Use specialized button mixin
	.btnShowFilters {
		// @include transition(0.3s);
		width: 40px;
		height: 40px;
		border: 0;
		margin: 0 0 20px;
		padding: 0;
		background-color: $white;

		@media screen and (min-width: $mq-2) {
			margin: 10px 10px 30px 20px;
		}
		@media screen and (min-width: $mq-3) {
			margin: 10px 15px 30px;
		}
		@media screen and (min-width: $mq-4) {
			margin: 10px 0 30px auto;
		}

		img {
			width: 28px;
			height: 28px;
			margin: 6px;
		}

		&:hover {
			img {
				filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
					brightness(88%) contrast(85%);
			}
		}
	}

	// TODO: Use specialized button mixin
	.btnHideFilters {
		@include transition(0.3s);
		position: absolute;
		width: 40px;
		height: 40px;
		right: 20px;
		top: 20px;
		border: 0;
		margin: 0 0 0 auto;
		padding: 0;
		background-color: $white;

		@media screen and (min-width: $mq-3) {
			right: 30px;
		}

		img {
			@include transition(0.3s);
			width: 18px;
			height: 18px;
			margin: 11px;

			filter: invert(16%) sepia(86%) saturate(0%) hue-rotate(304deg)
				brightness(111%) contrast(103%);
		}

		&:hover {
			img {
				filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg)
					brightness(103%) contrast(100%);
			}
		}
	}

	// TODO: Use multiselector mixin
	.btnStatusFilter {
		@include transition(0.3s);
		@include border-box;
		width: 100%;
		height: 60px;
		border-radius: 2px;
		border: solid 2px rgba(154, 154, 154, 0.2);
		background-color: $white;
		color: $lightGray;
		text-align: center;
		margin-bottom: 20px;

		@media screen and (min-width: $mq-4) {
			width: 30%;
			margin-bottom: 10px;
		}

		&.active {
			border-bottom: solid 4px $blue;
			color: $black;
		}

		&.long {
			@media screen and (min-width: $mq-4) {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&:hover {
			color: $blue;
		}
	}

	.btnApplyFilters {
		@include transition(0.3s);
		@include border-box;
		width: 100%;
		height: 60px;
		background-color: $blue;
		border-radius: 2px;
		color: $white;
		border: solid 2px $blue;
		text-align: center;

		&:hover {
			background-color: $darkBlue;
			border: solid 2px $darkBlue;
		}
	}

	.btnResetFilters {
		@include btn-cancel;
		@include transition(0.3s);
		@include border-box;
		width: 100%;
		height: 60px;
		border-radius: 2px;
		text-align: center;
		margin-top: 20px;

		// &:hover {
		// 	background-color: $lightBlue;
		// }
	}

	// Defined Areas
	aside.filters {
		display: none;
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: auto;
		// max-height: max-content;
		background-color: $white;
		z-index: 700;

		&.visible {
			display: block;
		}

		@media screen and (min-width: $mq-2) {
			width: 320px;
			left: auto;
			box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
			overflow-y: scroll;
		}
		@media screen and (min-width: $mq-3) {
			width: 440px;
			right: 0;
			height: 100%;
		}
		@media screen and (min-width: $mq-4) {
			width: 500px;
		}
	}

	// Boxes
	// Easy to move with a container
	.boxInteraction {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;

		@media screen and (min-width: $mq-4) {
			width: 90px;
			overflow: hidden;
			position: static;
		}

		&.open {
			background-color: $blue;
		}

		&.draft {
			background-color: $bluePassive;
		}

		&.closed {
			background-color: $lightBlue;
		}

		&.read {
			background-color: $bluePassive;
		}

		&.unread {
			background-color: $blue;
			span {
				color: #fff;
			}
		}

		&.long {
			span {
				width: calc(100% - 180px);

				@media screen and (min-width: $mq-4) {
					width: 90px;
				}
			}

			.btnUser {
				@media screen and (min-width: $mq-4) {
					right: 128px;
				}
			}
			.btnUser1 {
				@media screen and (min-width: $mq-4) {
					right: 128px;
				}
			}
			.btnUser2 {
				span {
					@media screen and (min-width: $mq-4) {
						color: #adadad;
					}
				}
				@media screen and (min-width: $mq-4) {
					right: 128px;
				}
			}
		}

		span {
			@include font-bold;
			// width: calc(100% - 140px);
			flex: 1;
			margin-left: 20px;
			margin-top: 9px;
			color: $black;

			@media screen and (min-width: $mq-4) {
				width: 90px;
				height: 80px;
				margin: 0;
				line-height: 80px;
				text-align: center;
			}

			&.open {
				color: $white;
			}
		}

		// Not a Button
		// TODO? Change it to span
		.btnUser {
			cursor: zoom-in;
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 95px;
				top: 20px;
			}

			img {
				// @include transition(0.3s);
				width: 26px;
				height: 26px;
				margin: 7px;

				@media screen and (min-width: $mq-4) {
					filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
						brightness(88%) contrast(94%);
				}
			}

			&.open img {
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				@media screen and (min-width: $mq-4) {
					filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
						brightness(88%) contrast(94%);
				}
			}
			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
							brightness(88%) contrast(85%);
					}
				}
			}

			p {
				@include font01;
				@include border-box;
				display: none;
				position: absolute;
				z-index: 600;
				bottom: 46px;
				right: -3px;
				width: 270px;
				height: auto;
				padding: 15px;
				background-color: $black;
				color: $white;

				@media screen and (min-width: $mq-3) {
					padding: 20px;
				}
				@media screen and (min-width: $mq-6) {
					right: -105px;
				}

				&:after {
					@include icon-tooltip;
					right: 13px;
					bottom: -10px;

					@media screen and (min-width: $mq-3) {
						right: 14px;
					}
					@media screen and (min-width: $mq-6) {
						right: 124px;
					}
				}
			}

			&:hover p {
				display: block;
			}
		}

		.btnUser1 {
			cursor: zoom-in;
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 140px;
				top: 20px;
			}

			img {
				// @include transition(0.3s);
				width: 36px;
				height: 36px;
				// margin: 7px;
				margin: 3px 0 0 0px;
				@media screen and (min-width: $mq-4) {
					filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
						brightness(88%) contrast(94%);
				}
			}

			&.open img {
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				@media screen and (min-width: $mq-4) {
					filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
						brightness(88%) contrast(94%);
				}
			}
			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
							brightness(88%) contrast(85%);
					}
				}
			}

			p {
				@include font01;
				@include border-box;
				display: none;
				position: absolute;
				z-index: 600;
				bottom: 46px;
				right: -3px;
				width: 270px;
				height: auto;
				padding: 15px;
				background-color: $black;
				color: $white;

				@media screen and (min-width: $mq-3) {
					padding: 20px;
				}
				@media screen and (min-width: $mq-6) {
					right: -105px;
				}

				&:after {
					@include icon-tooltip;
					right: 13px;
					bottom: -10px;

					@media screen and (min-width: $mq-3) {
						right: 14px;
					}
					@media screen and (min-width: $mq-6) {
						right: 124px;
					}
				}
			}

			&:hover p {
				display: block;
			}
		}

		.btnUser2 {
			cursor: context-menu;
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 180px;
				top: 20px;
			}

			span {
				color: #fff;
				@media screen and (min-width: $mq-4) {
					color: #adadad;
				}
			}

			img {
				// @include transition(0.3s);
				width: 26px;
				height: 26px;
				margin: 7px;

				@media screen and (min-width: $mq-4) {
					filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
						brightness(88%) contrast(94%);
				}
			}

			&.open img {
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				@media screen and (min-width: $mq-4) {
					filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
						brightness(88%) contrast(94%);
				}
			}
			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
							brightness(88%) contrast(85%);
					}
				}
			}

			p {
				@include font01;
				@include border-box;
				display: none;
				position: absolute;
				z-index: 600;
				bottom: 46px;
				right: -3px;
				width: 270px;
				height: auto;
				padding: 15px;
				background-color: $black;
				color: $white;

				@media screen and (min-width: $mq-3) {
					padding: 20px;
				}
				@media screen and (min-width: $mq-6) {
					right: -105px;
				}

				&:after {
					@include icon-tooltip;
					right: 13px;
					bottom: -10px;

					@media screen and (min-width: $mq-3) {
						right: 14px;
					}
					@media screen and (min-width: $mq-6) {
						right: 124px;
					}
				}
			}

			&:hover p {
				display: block;
			}
		}

		.btnDetail {
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 55px;
				top: 20px;
			}

			img {
				// @include transition(0.3s);
				width: 22px;
				height: 22px;
				margin: 9px;

				@media screen and (min-width: $mq-4) {
					filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
						brightness(96%) contrast(82%);
				}
			}

			&.open img {
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				@media screen and (min-width: $mq-4) {
					filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
						brightness(96%) contrast(82%);
				}
			}

			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
							brightness(88%) contrast(85%);
					}
				}
			}
		}

		.btnDetailblue {
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 55px;
				top: 20px;
			}

			img {
				// @include transition(0.3s);
				width: 22px;
				height: 22px;
				margin: 9px;

				@media screen and (min-width: $mq-4) {
					filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
						brightness(88%) contrast(85%);
				}
			}

			&.open img {
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				@media screen and (min-width: $mq-4) {
					filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
						brightness(88%) contrast(85%);
				}
			}

			// &:hover {
			// 	img {
			// 		@media screen and (min-width: $mq-4) {
			// 			filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
			// 				brightness(88%) contrast(85%);
			// 		}
			// 	}
			// }
		}

		// Different type of SVG
		// So, filters are different
		.btnFile {
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 50px;
				top: 20px;
			}

			img {
				// @include transition(0.3s);
				width: 22px;
				height: 22px;
				margin: 9px;

				@media screen and (min-width: $mq-4) {
					filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
						brightness(96%) contrast(82%);
				}
			}

			&.open img {
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				@media screen and (min-width: $mq-4) {
					filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
						brightness(96%) contrast(82%);
				}
			}

			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
							brightness(88%) contrast(85%);
					}
				}
			}
		}

		.btnFile1 {
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 88px;
				top: 20px;
			}

			img {
				// @include transition(0.3s);
				width: 22px;
				height: 22px;
				margin: 9px;

				@media screen and (min-width: $mq-4) {
					filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
						brightness(96%) contrast(82%);
				}
			}

			&.open img {
				filter: invert(100%) sepia(53%) saturate(0%) hue-rotate(118deg)
					brightness(111%) contrast(101%);

				@media screen and (min-width: $mq-4) {
					filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
						brightness(96%) contrast(82%);
				}
			}

			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
							brightness(88%) contrast(85%);
					}
				}
			}
		}

		.btnExpand {
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0 10px 0 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 15px;
				top: 20px;
				margin: 0;
			}

			img {
				// @include transition(0.3s);
				width: 18px;
				height: 18px;
				margin: 11px;

				@media screen and (min-width: $mq-4) {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}
			}

			img.expand {
				display: block;
			}
			img.shrink {
				display: none;
			}

			&.expanded img.shrink {
				display: block;
			}
			&.expanded img.expand {
				display: none;
			}

			&.open img {
				filter: brightness(0) invert(1);

				@media screen and (min-width: $mq-4) {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}
			}

			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
							brightness(96%) contrast(92%);
					}
				}
			}
		}

		.btnExpand1 {
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0 10px 0 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 15px;
				top: 20px;
				margin: 0;
			}

			img {
				// @include transition(0.3s);
				width: 18px;
				height: 18px;
				margin: 11px;

				@media screen and (min-width: $mq-4) {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}
				// filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
				// 	brightness(97%) contrast(85%);
			}

			img.expand {
				display: block;
			}
			img.shrink {
				display: none;
			}

			&.expanded img.shrink {
				display: block;
			}
			&.expanded img.expand {
				display: none;
			}

			&.open img {
				// filter: brightness(0) invert(1);

				@media screen and (min-width: $mq-4) {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}
			}

			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
							brightness(96%) contrast(92%);
					}
				}
			}
		}

		.btnExpand2 {
			position: relative;
			width: 40px;
			height: 40px;
			border: 0;
			margin: 0 10px 0 0;
			padding: 0;
			background-color: transparent;

			@media screen and (min-width: $mq-4) {
				position: absolute;
				right: 15px;
				top: 20px;
				margin: 0;
			}

			img {
				// @include transition(0.3s);
				width: 18px;
				height: 18px;
				margin: 11px;

				filter: invert(45%) sepia(18%) saturate(3583%) hue-rotate(161deg)
					brightness(109%) contrast(76%);

				@media screen and (min-width: $mq-4) {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}
			}

			img.expand {
				display: block;
			}
			img.shrink {
				display: none;
			}

			&.expanded img.shrink {
				display: block;
			}
			&.expanded img.expand {
				display: none;
			}

			&.open img {
				filter: brightness(0) invert(1);

				@media screen and (min-width: $mq-4) {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}
			}

			&:hover {
				img {
					@media screen and (min-width: $mq-4) {
						filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
							brightness(96%) contrast(92%);
					}
				}
			}
		}

		&.open {
			.btnExpand {
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnExpand1 {
				&.open img {
					@media screen and (min-width: $mq-4) {
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}
				}

				// &:hover {
				// 	img {
				// 		// @media screen and (min-width: $mq-4) {
				// 		// 	filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 		// 		brightness(96%) contrast(92%);
				// 		// }
				// 		filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 			brightness(96%) contrast(92%);
				// 	}
				// }
			}
			.btnExpand2 {
				&.open img {
					filter: invert(45%) sepia(18%) saturate(3583%) hue-rotate(161deg)
						brightness(109%) contrast(76%);

					@media screen and (min-width: $mq-4) {
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}
				}

				// &:hover {
				// 	img {
				// 		@media screen and (min-width: $mq-4) {
				// 			filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 				brightness(96%) contrast(92%);
				// 		}
				// 	}
				// }
			}
			.btnDetail {
				&.open img {
					filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(30deg)
						brightness(104%) contrast(101%);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				img {
					filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(30deg)
						brightness(104%) contrast(101%);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser {
				&.open img {
					filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(30deg)
						brightness(104%) contrast(101%);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				img {
					filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(30deg)
						brightness(104%) contrast(101%);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				img {
					filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(30deg)
						brightness(104%) contrast(101%);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser1 {
				&.open img {
					// filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				img {
					filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(30deg)
						brightness(104%) contrast(101%);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser2 {
				span {
					color: #fff;
					@media screen and (min-width: $mq-4) {
						color: #adadad;
					}
				}
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
		}

		&.closed {
			.btnExpand {
				&.open img {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnExpand1 {
				&.open img {
					// filter: brightness(1) invert(0);

					// @media screen and (min-width: $mq-4) {
					// 	filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
					// 		brightness(97%) contrast(85%);
					// }
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}

				// &:hover {
				// 	img {
				// 		// @media screen and (min-width: $mq-4) {
				// 		// 	filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 		// 		brightness(96%) contrast(92%);
				// 		// }
				// 		filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 			brightness(96%) contrast(92%);
				// 	}
				// }
			}
			.btnExpand2 {
				&.open img {
					filter: invert(45%) sepia(18%) saturate(3583%) hue-rotate(161deg)
						brightness(109%) contrast(76%);

					@media screen and (min-width: $mq-4) {
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}
				}

				// &:hover {
				// 	img {
				// 		@media screen and (min-width: $mq-4) {
				// 			filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 				brightness(96%) contrast(92%);
				// 		}
				// 	}
				// }
			}
			.btnDetail {
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser {
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser1 {
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(78%) sepia(1%) saturate(0%) hue-rotate(6deg)
							brightness(88%) contrast(94%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser2 {
				span {
					color: #000;
					@media screen and (min-width: $mq-4) {
						color: #adadad;
					}
				}
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
		}

		&.draft {
			.btnExpand {
				&.open img {
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnExpand1 {
				&.open img {
					// filter: brightness(1) invert(0);

					// @media screen and (min-width: $mq-4) {
					// 	filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
					// 		brightness(97%) contrast(85%);
					// }
					filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
						brightness(97%) contrast(85%);
				}

				// &:hover {
				// 	img {
				// 		@media screen and (min-width: $mq-4) {
				// 			filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 				brightness(96%) contrast(92%);
				// 		}
				// 	}
				// }
			}
			.btnExpand2 {
				&.open img {
					filter: invert(45%) sepia(18%) saturate(3583%) hue-rotate(161deg)
						brightness(109%) contrast(76%);

					@media screen and (min-width: $mq-4) {
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}
				}

				// &:hover {
				// 	img {
				// 		@media screen and (min-width: $mq-4) {
				// 			filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
				// 				brightness(96%) contrast(92%);
				// 		}
				// 	}
				// }
			}
			.btnDetail {
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
							brightness(96%) contrast(82%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser {
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
							brightness(96%) contrast(82%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser1 {
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
							brightness(96%) contrast(82%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
			.btnUser2 {
				span {
					color: #000;
					@media screen and (min-width: $mq-4) {
						color: #adadad;
					}
				}
				&.open img {
					filter: brightness(1) invert(0);

					@media screen and (min-width: $mq-4) {
						filter: invert(75%) sepia(0%) saturate(2657%) hue-rotate(20deg)
							brightness(96%) contrast(82%);
					}
				}

				&:hover {
					img {
						@media screen and (min-width: $mq-4) {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}
		}
	}

	// This makes Intersection Observer
	// more reliable, I hope
	.boxStickyObserver {
		width: 100%;
		height: 0px;
	}

	.boxHead {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: static;
		background-color: $white;
		top: 0;
		z-index: 500;
		width: 100%;

		@media screen and (min-width: $mq-4) {
			position: sticky;
			padding-bottom: 10px;
		}
		@media screen and (min-width: $mq-5) {
			max-width: $content-max-width;
			margin: 0 auto;
		}

		// &.stuck {
		// 	@media screen and (min-width: $mq-4) {
		// 		box-shadow: 0 2px 15px 0 $background-light;
		// 		// this huge unclipping is for the visibility of
		// 		// the absolute objects inside the header
		// 		clip-path: inset(0px 1px -500px 1px);
		// 	}
		// }

		.aaaa {
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			.badgecount {
				width: 58px;
				margin-left: 0;
				@media screen and (min-width: $mq-4) {
					margin-left: auto;
				}
				.ant-badge-count {
					min-width: 8px;
					right: 9px !important;
					margin-top: 21px !important;
					@media screen and (min-width: $mq-4) {
						right: 10px !important;
						margin-top: 30px !important;
					}
				}
			}

			.boxTabs {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				gap: 2px;
				width: 100%;
				margin: 25px 0 20px;

				@media screen and (min-width: $mq-3) {
					margin: 30px auto 36px;
					// width: fit-content;
				}
				@media screen and (min-width: $mq-6) {
					margin: 30px auto 36px;
					// padding: 0 30px 20px;
				}

				.btnTab {
					@include font01;
					@include font-light;
					color: $darkGray;
					background-color: transparent;
					text-align: center;
					// minus gap/2
					width: 50%;
					padding: 10px 15px;
					border: 0;
					border-bottom: solid 3px $lighterGray;
					// background: $lighterGray;

					@media screen and (min-width: $mq-3) {
						@include font07;
						// padding: 0 30px 15px;
						// width: fit-content;
					}
					@media screen and (min-width: $mq-4) {
						@include font08;
					}
					@media screen and (min-width: $mq-6) {
						padding: 10px 20px;
					}

					&:hover {
						color: $blue;
					}

					&.current {
						border-bottom: solid 3px $blue;
						color: $black;
						background: #fff;
					}

					&.ma {
						margin: 0 auto;
					}

					.name {
						width: fit-content;
						position: relative;

						.quantity {
							@include font00;
							@include border-box;
							position: absolute;
							// width: fit-content;
							min-width: 24px;
							height: 24px;
							border-radius: 50%;
							top: -3px;
							right: -24px;
							padding-top: 1px;
							vertical-align: center;
							background-color: $blue;
							color: $white;
							text-align: center;

							@media screen and (min-width: $mq-3) {
								top: 2px;
								right: -30px;
							}
						}
					}
				}
			}

			.jc {
				justify-content: center;
			}

			.boxSearch {
				position: relative;
				width: 100%;
				margin: 0 0 20px;

				@media screen and (min-width: $mq-3) {
					margin: 0 0 30px;
				}
				@media screen and (min-width: $mq-4) {
					width: 55%;
					margin: 0 20px 30px 0;
				}
				@media screen and (min-width: $mq-5) {
					width: 55%;
					margin: 0 20px 30px 0;
				}
				@media screen and (min-width: $mq-5) {
					margin-right: 30px;
				}

				// TODO: INPUT MIXIN
				input.txbSearch {
					@include transition(0.5s);
					@include font-light;
					line-height: 38px; // TODO: improve here
					width: calc(100% - 44px);
					color: $black;
					background-color: $white;
					border-radius: 2px;
					border: solid 2px rgba(154, 154, 154, 0.2);
					padding: 9px 20px;

					&.gotError {
						background-color: rgba(199, 21, 38, 0.15);
						border-color: transparent;
					}

					&.isClean {
						background-color: $greenOk;
						border-color: transparent;
					}

					&:focus {
						outline: none !important;
						background-color: $white;
						border: solid 2px $bluePassive;
					}
				}

				.btnCancel {
					position: absolute;
					right: 50px;
					top: 10px;
					width: 40px;
					height: 40px;
					border: 0;
					margin: 0;
					padding: 0;
					background-color: $white;

					img {
						@include transition(0.3s);
						width: 16px;
						height: 22px;
						margin: 9px 12px;
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}

					&:hover {
						img {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}

				.btnSearch {
					position: absolute;
					right: 12px;
					top: 10px;
					width: 40px;
					height: 40px;
					border: 0;
					margin: 0;
					padding: 0;
					background-color: $white;

					img {
						@include transition(0.3s);
						width: 18px;
						height: 20px;
						margin: 10px 12px;
						filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
							brightness(97%) contrast(85%);
					}

					&:hover {
						img {
							filter: invert(35%) sepia(89%) saturate(676%) hue-rotate(164deg)
								brightness(96%) contrast(92%);
						}
					}
				}
			}

			.boxPeriod {
				position: relative;
				width: 100%;
				margin-bottom: 20px;

				@media screen and (min-width: $mq-2) {
					width: calc(100% - 70px);
				}
				@media screen and (min-width: $mq-3) {
					width: 50%;
				}
				@media screen and (min-width: $mq-4) {
					width: 35%;
				}
				// @media screen and (min-width: $mq-4) {
				//     width: 35%;
				// }

				.ddlPeriod {
					@include ddl-common;
				}
			}

			// Phantom area on mobile devices
			.boxTitleswrap {
				display: none;
				background: $white;
				@media screen and (min-width: $mq-4) {
					display: flex;
					width: 100%;
					flex-wrap: wrap;
				}
				@media screen and (min-width: $mq-5) {
					display: flex;
					width: 100%;
					flex-wrap: nowrap;
				}
				@media screen and (min-width: $mq-6) {
					display: flex;
					width: 100%;
				}
				&.stuck {
					@media screen and (min-width: $mq-4) {
						box-shadow: 0 2px 15px 0 $background-light;
						// this huge unclipping is for the visibility of
						// the absolute objects inside the header
						clip-path: inset(0px 1px -500px 1px);
					}
				}
				.boxTitles {
					display: none;

					@media screen and (min-width: $mq-4) {
						display: flex;
						flex: 1;
						margin-left: 102px;
					}
					@media screen and (min-width: $mq-5) {
						flex: 1;
						margin-left: 102px;
					}
					@media screen and (min-width: $mq-6) {
						flex: 1;
						margin-left: 102px;
					}

					.btnSort {
						@include font01;
						@include font-bold;
						@include btn-sorter;
						@include border-box;
						width: 140px;
						padding: 5px 10px;
						height: 60px;
						text-indent: 10px;

						@media screen and (min-width: $mq-5) {
							width: 150px;
						}
						@media screen and (min-width: $mq-6) {
							width: 160px;
						}

						&.duedatesort {
							background-position: 0 50%;
						}

						&.ncnumbersort {
							background-position: 0 50%;
						}

						&.referencesort {
							background-position: 0 50%;
						}

						&.problemsort {
							background-position: 0 50%;
						}

						// TODO: Specify these w/ real content
						&.long {
							width: 200px;

							@media screen and (min-width: $mq-5) {
								width: 215px;
							}
							@media screen and (min-width: $mq-6) {
								width: 225px;
							}
						}
						&.flexible {
							@media screen and (min-width: $mq-6) {
								min-width: 250px;
								width: 20%;
							}
						}
					}
				}

				.boxAllExpand {
					@include font-bold;
				}
			}
		}
	}

	.searchresult {
		width: 100%;
		margin: 0 0 20px 0;
		p {
			margin-top: 10px;
		}
	}

	.nosearchresult {
		width: 100%;
		margin: 0 0 300px 0;
		p {
			margin-top: 10px;
		}
	}

	.requestemptyshowmine {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
	}

	.requestempty {
		display: flex;
		margin: 40px auto 0 auto;
		width: 100%;
		flex-direction: column;
		align-items: center;
		@media screen and (min-width: $mq-3) {
			flex-direction: row;
			align-items: center;
		}
		@media screen and (min-width: $mq-5) {
			width: 1200px;
		}
		.eimage {
			width: 90%;
			height: 262px;
			margin: 0;
			@media screen and (min-width: $mq-3) {
				margin: 0 20px 0 0;
				width: 463px;
			}
			@media screen and (min-width: $mq-4) {
				padding-left: 40px;
			}
			img {
				width: 100%;
				height: 262px;
				display: block;
				@media screen and (min-width: $mq-3) {
					width: 463px;
				}
			}
		}
		.ewrap {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media screen and (min-width: $mq-3) {
				align-items: flex-start;
			}
			@media screen and (min-width: $mq-4) {
				padding-right: 40px;
			}
			.etitle {
				@include font-bold;
				@include font03;
				text-align: center;
				@media screen and (min-width: $mq-3) {
					text-align: left;
				}
			}
			.econtent {
				margin-top: 16px;
				@include font-light;
				text-align: center;
				@media screen and (min-width: $mq-3) {
					text-align: left;
				}
			}
			.ebutton {
				margin: 40px 0 0 0;
				@include transition(0.3s);
				width: 130px;
				display: block;
				background-color: $blue;
				border-radius: 2px;
				color: $white;
				border: solid 2px $blue;
				text-align: center;
				height: 46px;
				line-height: 42px;
				cursor: pointer;
				font-size: 16px;

				&:hover {
					background-color: $darkBlue;
					border: solid 2px $darkBlue;
				}
			}
		}
	}

	// Items after the list
	.boxItemQuantity {
		position: relative;
		width: 100%;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 20px;

		@media screen and (min-width: $mq-3) {
			margin-top: 0;
			justify-content: flex-start;
			width: 50%;
		}
		@media screen and (min-width: $mq-4) {
			width: auto;
			margin-right: 30px;
		}
		@media screen and (min-width: $mq-5) {
			margin-right: 20px;
		}

		span {
			// width: calc(100% - 120px);
			margin: 18px 10px;
			text-align: right;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				margin: 18px 10px 0 0;
			}
		}
		.ddlQuantity {
			@include ddl-common;
			width: auto;
			min-width: 120px;
		}
	}

	.boxPagination {
		position: relative;
		width: 200px;
		margin: 0 calc(50% - 100px) 25px;
		display: flex;
		flex-direction: row;

		@media screen and (min-width: $mq-3) {
			width: 300px;
			margin: 0 0 30px calc(50% - 300px);
		}
		@media screen and (min-width: $mq-4) {
			margin-left: 10%;
		}
		@media screen and (min-width: $mq-5) {
			margin-left: 0;
		}

		span {
			width: 60px;
			@include font01;
			@include font-light;
			margin: 20px 0;
			display: inline-block;
			text-align: center;
		}
		.btnLeft {
			width: 30px;
			height: 40px;
			border: 0;
			margin: 10px 15px;
			padding: 0;
			background-color: $white;

			@media screen and (min-width: $mq-3) {
				margin: 10px 35px 10px 25px;
			}

			img {
				@include transition(0.3s);
				display: inline-block;
				width: 12px;
				height: 20px;
				margin: 10px 9px;
			}
		}
		.btnRight {
			width: 30px;
			height: 40px;
			border: 0;
			margin: 10px 5px;
			padding: 0;
			background-color: $white;

			@media screen and (min-width: $mq-3) {
				margin: 10px 25px 10px 35px;
			}

			img {
				@include transition(0.3s);
				width: 12px;
				height: 20px;
				margin: 10px 9px;
			}
		}

		.txbPagination {
			@include txb-common;
			text-align: center;
			width: 60px;
			padding: 9px 5px;
		}
	}

	// Lists
	.lstOverview {
		@include lst-common;
		gap: 20px;

		@media screen and (min-width: $mq-3) {
			gap: $base-margin;
			margin: 0 2px 20px;
		}
		@media screen and (min-width: $mq-4) {
			margin: 13px 2px 30px;
		}
		@media screen and (min-width: $mq-5) {
		}

		li {
			background-color: $nearlyWhite;

			@media screen and (min-width: $mq-4) {
				background-color: transparent;
				position: relative;
				display: flex;
				flex-wrap: wrap;
			}

			// List variations
			&.expanded {
				background-color: $white;
				display: flex;
				flex-direction: column;
				flex-flow: wrap;

				.lstMainElements {
					border-left: solid 2px $background-gray-disabled;
					border-right: solid 2px $background-gray-disabled;
					border-top: solid 2px transparent;
					overflow-y: hidden;
					padding-bottom: 0;

					@media screen and (min-width: $mq-4) {
						background-color: $white;
						border-top: solid 2px $background-gray-disabled;
						border-bottom: 0;
						padding-bottom: 10px;
					}
				}

				.lstExtraElementsLeft {
					display: flex;
					flex-direction: column;
					padding-top: 0;
					border: solid 2px $background-gray-disabled;
					border-top: 0;
					border-bottom: 0;
					gap: 10px;

					@media screen and (min-width: $mq-3) {
						padding-right: 0;
						border-right: 0;
						border-bottom: solid 2px $background-gray-disabled;
					}
					@media screen and (min-width: $mq-4) {
						gap: 20px;
						padding-top: 20px;
						background-color: $white;
					}
					@media screen and (min-width: $mq-6) {
						padding-top: 30px;
					}

					&.shorts {
						border-bottom: solid 2px $background-gray-disabled;

						@media screen and (min-width: $mq-3) {
							padding-right: 20px;
							padding-left: 0;
							border-left: 0;
							border-right: solid 2px $background-gray-disabled;
						}
					}
					.letterStatus {
						@include border-box;
						background-color: $white;
						border: solid 2px $nearlyWhite;
						border-radius: 2px;
						display: flex;
						flex-flow: wrap;
						align-items: center;
						width: 100%;
						margin: 20px 0;
						padding: 15px;

						.lblAttention {
							margin-top: 6px;
						}

						.lblPassive {
							margin-top: 6px;
						}
						.lblRelaxwrap {
							display: flex;
							flex-direction: column;
							justify-content: center;
							flex: 1;
							.lblRelax {
								margin-top: 6px;
								@include font-light;
								word-break: break-all;
							}
						}
						img {
							@include icon-success;
						}

						span {
							@include font01;
							margin: 0 0 0 15px;
						}

						.icnRelax {
							display: none;
						}
						.lblRelaxwrap {
							display: flex;
							flex-direction: column;
							justify-content: center;
							flex: 1;
							.lblRelax {
								display: none;
							}
						}

						&.confirmed {
							background-color: $greenOk;
							border: solid 2px $greenOk;

							.icnAttention {
								display: none;
							}
							.lblAttention {
								display: none;
							}

							.icnRelax {
								display: block;
								width: 32px;
							}
							.lblRelaxwrap {
								display: flex;
								flex-direction: column;
								justify-content: center;
								flex: 1;
								.lblRelax {
									display: block;
									word-break: break-all;
								}
							}
						}

						.boxConfirmation {
							width: 100%;
							border-top: solid 2px $nearlyWhite;
							display: flex;
							margin-top: 15px;
							padding-top: 15px;

							.cbxConfirm {
								@include ckb-common;
							}

							.previewcbxConfirm {
								@include ckb-common-prview;
							}

							.lblPassive {
								display: block;
							}
							.lblActive {
								display: none;
							}

							&.confirmed {
								.lblPassive {
									display: block;
								}
								.lblActive {
									display: block;
								}
							}
						}
					}
				}

				.lstExtraElementswrap {
					display: flex;
					flex-direction: column;
					flex: 1;
					.lstExtraElements {
						display: flex;
						flex-direction: column;
						padding-top: 0;
						border: solid 2px $background-gray-disabled;
						border-top: 0;
						border-bottom: 0;
						gap: 10px;
						width: 100%;
						@media screen and (min-width: $mq-3) {
							padding-right: 0;
							border-right: 0;
							border-bottom: solid 2px $background-gray-disabled;
						}
						@media screen and (min-width: $mq-4) {
							gap: 20px;
							padding-top: 20px;
							background-color: $white;
						}
						@media screen and (min-width: $mq-6) {
							padding-top: 30px;
						}

						&.shorts {
							border-bottom: solid 2px $background-gray-disabled;

							@media screen and (min-width: $mq-3) {
								padding-right: 20px;
								padding-left: 0;
								border-left: 0;
								border-right: solid 2px $background-gray-disabled;
							}
						}
						.letterStatus {
							@include border-box;
							background-color: $white;
							border: solid 2px $nearlyWhite;
							border-radius: 2px;
							display: flex;
							flex-flow: wrap;
							align-items: center;
							width: 100%;
							margin: 20px 0;
							padding: 15px;

							.lblAttention {
								margin-top: 6px;
							}

							.lblPassive {
								margin-top: 6px;
							}
							.lblRelaxwrap {
								display: flex;
								flex-direction: column;
								justify-content: center;
								flex: 1;
								.lblRelax {
									margin-top: 6px;
									@include font-light;
									word-break: break-all;
								}
							}
							img {
								@include icon-success;
							}

							span {
								@include font01;
								margin: 0 0 0 15px;
							}

							.icnRelax {
								display: none;
							}
							.lblRelaxwrap {
								display: flex;
								flex-direction: column;
								justify-content: center;
								flex: 1;
								.lblRelax {
									display: none;
								}
							}

							&.confirmed {
								background-color: $greenOk;
								border: solid 2px $greenOk;

								.icnAttention {
									display: none;
								}
								.lblAttention {
									display: none;
								}

								.icnRelax {
									display: block;
									width: 32px;
								}
								.lblRelaxwrap {
									display: flex;
									flex-direction: column;
									justify-content: center;
									flex: 1;
									.lblRelax {
										display: block;
										word-break: break-all;
									}
								}
							}

							.boxConfirmation {
								width: 100%;
								border-top: solid 2px $nearlyWhite;
								display: flex;
								margin-top: 15px;
								padding-top: 15px;

								.cbxConfirm {
									@include ckb-common;
								}

								.previewcbxConfirm {
									@include ckb-common-prview;
								}

								.lblPassive {
									display: block;
								}
								.lblActive {
									display: none;
								}

								&.confirmed {
									.lblPassive {
										display: block;
									}
									.lblActive {
										display: block;
									}
								}
							}
						}
					}
					.lstExtraElementsconfirm {
						display: flex;
						flex-direction: column;
						padding-top: 0;
						border: solid 2px $background-gray-disabled;
						border-top: 0;
						border-bottom: 0;
						gap: 10px;
						width: 100%;
						@media screen and (min-width: $mq-3) {
							padding-right: 0;
							border-right: 0;
							border-bottom: solid 2px $background-gray-disabled;
						}
						@media screen and (min-width: $mq-4) {
							gap: 20px;
							padding-top: 20px;
							background-color: $white;
						}
						@media screen and (min-width: $mq-6) {
							padding-top: 30px;
						}

						&.shorts {
							border-bottom: solid 2px $background-gray-disabled;

							@media screen and (min-width: $mq-3) {
								padding-right: 20px;
								padding-left: 0;
								border-left: 0;
								border-right: solid 2px $background-gray-disabled;
							}
						}
						.letterStatus {
							@include border-box;
							background-color: $white;
							border: solid 2px $nearlyWhite;
							border-radius: 2px;
							display: flex;
							flex-flow: wrap;
							align-items: center;
							width: 100%;
							margin: 0;
							padding: 15px;
							@media screen and (min-width: $mq-3) {
								// margin: 0 0 57px 0;
								margin: 0 0 82px 0;
							}
							.lblAttention {
								margin-top: 6px;
							}

							.lblPassive {
								margin-top: 6px;
							}
							.lblRelaxwrap {
								display: flex;
								flex-direction: column;
								justify-content: center;
								flex: 1;
								.lblRelax {
									margin-top: 6px;
									@include font-light;
									word-break: break-all;
								}
							}
							img {
								@include icon-success;
							}

							span {
								@include font01;
								margin: 0 0 0 15px;
							}

							.icnRelax {
								display: none;
							}
							.lblRelaxwrap {
								display: flex;
								flex-direction: column;
								justify-content: center;
								flex: 1;
								.lblRelax {
									display: none;
								}
							}

							&.confirmed {
								background-color: $greenOk;
								border: solid 2px $greenOk;

								.icnAttention {
									display: none;
								}
								.lblAttention {
									display: none;
								}

								.icnRelax {
									display: block;
									width: 32px;
								}
								.lblRelaxwrap {
									display: flex;
									flex-direction: column;
									justify-content: center;
									flex: 1;
									.lblRelax {
										display: block;
										word-break: break-all;
									}
								}
							}

							.boxConfirmation {
								width: 100%;
								border-top: solid 2px $nearlyWhite;
								display: flex;
								margin-top: 15px;
								padding-top: 15px;

								.cbxConfirm {
									@include ckb-common;
								}

								.previewcbxConfirm {
									@include ckb-common-prview;
								}

								.lblPassive {
									display: block;
								}
								.lblActive {
									display: none;
								}

								&.confirmed {
									.lblPassive {
										display: block;
									}
									.lblActive {
										display: block;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Connected to the lstOverview
	.lstMainElements {
		@include lst-common;
		padding: 20px 20px 10px;
		border-left: solid 2px transparent;
		border-right: solid 2px transparent;
		border-top: solid 2px transparent;

		// Initial width
		// 620px, 680px, 720px
		@media screen and (min-width: $mq-3) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
		@media screen and (min-width: $mq-4) {
			padding: 10px 20px 10px;
			height: 80px;
			background-color: $nearlyWhite;
			width: calc(100% - 90px);
		}

		// Alternative
		// &.alt {
		//     border: solid 2px rgba(154, 154, 154, 0.2);
		//     border-top: 0;

		//     @media screen and (min-width: $mq-4) {
		//         background-color: $white;
		//         border-top: solid 2px rgba(154, 154, 154, 0.2);
		//     }
		// }

		li {
			@include border-box;
			width: 100%;
			margin-bottom: 10px;
			background-color: transparent;
			padding: 0;
			display: flex;
			flex-direction: column;
			position: relative;

			@media screen and (min-width: $mq-3) {
				width: 60%;
			}
			@media screen and (min-width: $mq-4) {
				display: table;
				width: 140px;
				padding: 0;
				margin-bottom: 0;
				height: 60px;
			}
			@media screen and (min-width: $mq-5) {
				width: 150px;
			}
			@media screen and (min-width: $mq-6) {
				width: 160px;
			}

			&:nth-child(2n) {
				@media screen and (min-width: $mq-3) {
					width: 40%;
					padding-left: 8px;
				}
				@media screen and (min-width: $mq-4) {
					width: 140px;
				}
				@media screen and (min-width: $mq-5) {
					width: 150px;
				}
				@media screen and (min-width: $mq-6) {
					width: 160px;
				}
			}

			&.long {
				@media screen and (min-width: $mq-4) {
					width: 200px;
					padding-left: 8px;
				}
				@media screen and (min-width: $mq-5) {
					width: 215px;
				}
				@media screen and (min-width: $mq-6) {
					width: 225px;
				}
			}

			&.flexible {
				@media screen and (min-width: $mq-6) {
					min-width: 250px;
					width: 20%;
				}
			}

			span {
				@include font01;
				margin-bottom: 10px;

				@media screen and (min-width: $mq-4) {
					display: none;
				}
			}

			// Multiline Text
			p {
				@include font01;
				// If overdue padding cause any issue
				// padding: 3px 5px;

				@media screen and (min-width: $mq-4) {
					display: table-cell;
					vertical-align: middle;
					height: 60px;
				}
			}

			// TODO? too many overdues
			&.overdue {
				@media screen and (min-width: $mq-4) {
					position: static;
				}

				span.overdue {
					@include font00;
					position: absolute;
					top: 34px;
					left: 100px;
					right: auto;
					width: 78px;
					padding: 3px 10px;
					text-align: center;
					border-radius: 16px;
					background-color: $red;
					color: $text-light-gray;
					margin: 0;
					display: block;
					z-index: 9;

					@media screen and (min-width: $mq-3) {
						left: 100px;
						right: auto;
					}

					@media screen and (min-width: $mq-4) {
						top: -$base-margin/2;
						right: $base-margin;
						left: auto;
					}

					&.longInteraction {
						@media screen and (min-width: $mq-5) {
							top: 27px;
							right: 190px;
						}
					}
				}
			}
			&.overdue,
			&.pre-overdue {
				p {
					background-color: $lightRed;
					color: $red;
					width: fit-content;
					padding: 3px 5px;

					@media screen and (min-width: $mq-4) {
						margin-top: 15px;
						display: flex;
						height: auto;
					}
				}
			}
		}
	}

	// Two types of list
	// Design: Top2bottom columns
	// Flex & Static Height is not a good option
	.lstExtraElements {
		@include lst-common;
		display: none;
		padding: 20px 20px 10px;

		@media screen and (min-width: $mq-3) {
			// Take Hidden Border Width
			width: calc(60% - 4px);
			padding: 20px;
		}
		@media screen and (min-width: $mq-4) {
			width: calc(65% - 94px);
			margin-left: 90px;
			background-color: $nearlyWhite;
		}
		@media screen and (min-width: $mq-6) {
			padding: 20px 25px 20px;
		}

		&.borderbottomnone {
			border-bottom: none !important;
		}

		&.receiptconfirmed {
			padding: 20px 0 0;
			margin: 0 !important;
		}

		li {
			display: flex;
			flex-direction: column;
			background-color: transparent;
			@include font01;
			word-wrap: break-word;
			word-break: break-all;

			span {
				@include font-bold;
				margin-bottom: 10px;
			}

			p {
				padding-right: 40px;

				a {
					color: $blue;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		&.shorts {
			p {
				padding-right: 20px;
			}

			@media screen and (min-width: $mq-3) {
				// Put Hidden Border Width
				width: calc(40% + 4px);
			}
			@media screen and (min-width: $mq-4) {
				margin-left: 0;
				width: calc(35% + 4px);
			}
		}
	}

	.lstExtraElementsconfirm {
		@include lst-common;
		display: none;
		padding: 20px 20px 10px;

		@media screen and (min-width: $mq-3) {
			// Take Hidden Border Width
			width: calc(60% - 4px);
			padding: 20px;
		}
		@media screen and (min-width: $mq-4) {
			width: calc(65% - 94px);
			margin-left: 90px;
			background-color: $nearlyWhite;
		}
		@media screen and (min-width: $mq-6) {
			padding: 20px 25px 20px;
		}

		&.borderbottomnone {
			border-bottom: none !important;
		}

		&.receiptconfirmed {
			padding: 20px 0 0;
			margin: 0 !important;
		}

		li {
			display: flex;
			flex-direction: column;
			background-color: transparent;
			@include font01;
			word-wrap: break-word;
			word-break: break-all;

			span {
				@include font-bold;
				margin-bottom: 10px;
			}

			p {
				padding-right: 40px;

				a {
					color: $blue;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		&.shorts {
			p {
				padding-right: 20px;
			}

			@media screen and (min-width: $mq-3) {
				// Put Hidden Border Width
				width: calc(40% + 4px);
			}
			@media screen and (min-width: $mq-4) {
				margin-left: 0;
				width: calc(35% + 4px);
			}
		}
	}

	.lstExtraElementsLeft {
		@include lst-common;
		display: none;
		padding: 20px 20px 10px;

		@media screen and (min-width: $mq-3) {
			// Take Hidden Border Width
			width: calc(60% - 4px);
			padding: 20px;
		}
		@media screen and (min-width: $mq-4) {
			width: calc(65% - 94px);
			margin-left: 90px;
			background-color: $nearlyWhite;
		}
		@media screen and (min-width: $mq-6) {
			padding: 20px 25px 20px;
		}

		&.borderbottomnone {
			border-bottom: none !important;
		}

		&.receiptconfirmed {
			padding: 20px 0 0;
			margin: 0 !important;
		}

		li {
			display: flex;
			flex-direction: column;
			background-color: transparent;
			@include font01;
			word-wrap: break-word;
			word-break: break-all;

			span {
				@include font-bold;
				margin-bottom: 10px;
			}

			p {
				padding-right: 40px;

				a {
					color: $blue;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			p.cc {
				background-color: #ffe5e5;
				color: #c71526;
				width: fit-content;
				padding: 3px 5px;
			}
		}

		&.shorts {
			p {
				padding-right: 20px;
			}

			@media screen and (min-width: $mq-3) {
				// Put Hidden Border Width
				width: calc(40% + 4px);
			}
			@media screen and (min-width: $mq-4) {
				margin-left: 0;
				width: calc(35% + 4px);
			}
		}
	}

	.lstFilters {
		@include lst-common;
		padding: 60px 30px;
		background-color: #fff;

		@media screen and (min-width: $mq-3) {
			padding: 60px 40px;
		}

		li {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: 25px;

			span {
				width: 100%;
				margin-bottom: 15px;
			}

			@media screen and (min-width: $mq-4) {
				justify-content: space-between;
			}

			.txbFilter {
				@include txb-common;
			}
		}
	}
}
